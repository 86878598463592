// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from '../../models/user-login.model';
//import { UserRegistrationComponent } from '../../components/controls/user-registration.component';
import { UserEdit } from '../../models/user-edit.model';

import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { UserForgetPasswordComponent } from '../../components/controls/user-forget-password.component';

import { Role } from '../../models/role.model';
import { Permission } from '../../models/permission.model';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { IdentityClaimUser } from '../../models/identity-claim-user.model';
import { environment } from '../../../environments/environment';
import { JwtHelper } from '../../services/jwt-helper';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  isIdentityLoading = false;
  isOpenAMloginsuccess = false;
  identityclaimuser = new IdentityClaimUser();
  @Input()
  isModal = false;

  //@ViewChild('editorModal', { static: true })
  //editorModal: ModalDirective;

  //@ViewChild('userEditor', { static: true })
  //userEditor: UserRegistrationComponent;


  editedUser: UserEdit;
  allRoles: Role[] = [];

  constructor(private alertService: AlertService, private authService: AuthService, private configurations: ConfigurationService, private accountService: AccountService, private modalService: BsModalService, private router: Router) {

  }


  ngOnInit() {
    this.userLogin.rememberMe = this.authService.rememberMe;

    localStorage.removeItem("email");
    sessionStorage.removeItem('loginreponse');
    this.CheckOpenAMAuthenticated();

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
    // this.loadData();
  }

  CheckOpenAMAuthenticated() {

    //kIVKFvU16RgW_IzPE0jHa7kPv2I
    //localStorage.setItem("idamcode", "25OEtra-SVNVAjw0-CHmEMkZQHo");

    if (typeof localStorage.getItem("idam") != "undefined" && localStorage.getItem("idam") != null) {
      const token = JSON.parse(localStorage.getItem("idam"));
      this.isOpenAMloginsuccess = true;

      this.userLogin.userName = token.preferredMail;

      this.userLogin.password = atob("dGVtcFBAc3MxMjM=");
      this.userLogin.rememberMe = false;
    }
    else {
      // get identity claim user information by authorization api
      if (typeof localStorage.getItem("idamcode") == "undefined" && localStorage.getItem("idamcode") == null) {
        return false;
      }
      const code = localStorage.getItem("idamcode");

      if (code != null) {
        this.identityclaimuser.code = code;

        this.identityclaimuser.appid = environment.idam.client_app_id;
        this.identityclaimuser.apikey = environment.idam.client_secret;
        this.identityclaimuser.apiurl = environment.idam.app_url;
        this.identityclaimuser.iamauthapihost = environment.idam.piam_auth_host;

        this.isIdentityLoading = true;
        this.accountService.SetData<string>("identityauthorization", this.identityclaimuser).subscribe(
          result => {
            if (result != '' && result != null) {
              let encodedidToken = result;

              const jwtHelper = new JwtHelper();
              const decodedIdToken = jwtHelper.decodeToken(encodedidToken);
              this.isIdentityLoading = false;
              this.isOpenAMloginsuccess = true;
              Object.assign(this.identityclaimuser, decodedIdToken);
              this.userLogin.userName = this.identityclaimuser.email;
              this.userLogin.password = atob("dGVtcFBAc3MxMjM=");
              this.userLogin.rememberMe = false;
              localStorage.removeItem("idamcode");

              this.login();

            }
          },
          error => {
            localStorage.removeItem("idamcode");
            this.alertService.showStickyMessage('Load Error', 'Unable to retrieve data from the server.\r\nErrors: "' + Utilities.getHttpResponseMessages(error),
              MessageSeverity.error, error);
          });

      }

    }
  }



  OpenAMLogin() {

    ////for local idam login
    //localStorage.setItem("idam", JSON.stringify({ "aud": "urn:u2zzdvpoaswa001.azurewebsites.net", "cloudEmail": "prabal.c.mukherjee@pwc.com", "country": "IN", "email": "admin@pwc.com", "employeeNumber": "JV000000", "employeetype": "Employee", "family_name": "Inbuilt Administrator", "given_name": "Inbuilt Administrator", "name": "Inbuilt Administrator", "preferredMail": "prabal.c.mukherjee@pwc.com", "pwcUserType": "Internal", "realm": "/pwc", "sub": "mbasu020", "uid": "mbasu020", "upn": "prabal.c.mukherjee@pwc.com" }));




    //this.isIdentityLoading = true;
    const token = JSON.parse(localStorage.getItem("idam"));

    if (token == null || typeof token == 'undefined') {
      this.authService.checkAuthenticationWithIdAM();
      return;
    }

    //for local idam login
    //if (token != null || typeof token != 'undefined') {
    //  //this.isIdentityLoading = false;
    //  this.CheckOpenAMAuthenticated()

    //}

  }

  ngAfterViewInit() {

    //this.userEditor.changesSavedCallback = () => {

    //  this.editorModal.hide();
    //};

    //this.userEditor.changesCancelledCallback = () => {
    //  this.editedUser = null;

    //  this.editorModal.hide();
    //};
  }


  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }


  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }


  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }


  login() {
    this.isLoading = true;
    this.alertService.startLoadingMessage('', 'Attempting login...');

    localStorage.removeItem('currentTemplateDetails');
    localStorage.removeItem('currentUserDetails');

    this.authService.login(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe)
      .subscribe(
        user => {

          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();

            if (!this.isModal) {
              this.alertService.showMessage('Login', `Welcome ${user.userName}!`, MessageSeverity.success);
              if (this.canViewRoles) {
                this.router.navigate(['userinfo']);
              }
            } else {
              this.alertService.showMessage('Login', `Session for ${user.userName} restored!`, MessageSeverity.success);
              setTimeout(() => {
                this.alertService.showStickyMessage('Session Restored', 'Please try your last operation again', MessageSeverity.default);
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        error => {

          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
            this.offerAlternateHost();
          } else {
            const errorMessage = Utilities.getHttpResponseMessage(error);

            if (errorMessage) {
              this.alertService.showStickyMessage('Unable to login', this.mapLoginErrorMessage(errorMessage), MessageSeverity.error, error);
            } else {
              this.alertService.showStickyMessage('Unable to login', 'An error occured whilst logging in, please try again later.\nError: ' + Utilities.getResponseBody(error), MessageSeverity.error, error);
            }
          }

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }


  offerAlternateHost() {

    if (Utilities.checkIsLocalHost(location.origin) && Utilities.checkIsLocalHost(this.configurations.baseUrl)) {
      this.alertService.showDialog('Dear Developer!\nIt appears your backend Web API service is not running...\n' +
        'Would you want to temporarily switch to the online Demo API below?(Or specify another)',
        DialogType.prompt,
        (value: string) => {
          this.configurations.baseUrl = value;
          this.configurations.tokenUrl = value;
          this.alertService.showStickyMessage('API Changed!', 'The target Web API has been changed to: ' + value, MessageSeverity.warn);
        },
        null,
        null,
        null,
        this.configurations.fallbackBaseUrl);
    }
  }


  mapLoginErrorMessage(error: string) {

    if (error == 'invalid_username_or_password') {
      return 'Invalid username or password';
    }

    if (error == 'invalid_grant') {
      return 'This account has been disabled';
    }

    return error;
  }


  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  /*User registration*/

  //onEditorModalHidden() {
  //  //this.editingUserName = null;
  //  this.userEditor.resetForm(true);
  //}
  //newUser() {
   
  //  //this.editingUserName = null;
  //  //this.sourceUser = null;
  //  this.editedUser = this.userEditor.newUser(this.allRoles);
  //  this.editorModal.show();
  //}

  /* Forget password modal callback */
  forgetPassword() {
    this.modalService.show(UserForgetPasswordComponent);
  }

  loadData() {

    if (this.canViewRoles) {
      this.accountService.getUsersAndRoles().subscribe(results => this.onDataLoadSuccessful(results[0], results[1]), error => this.onDataLoadFailed(error));
    } else {
      this.accountService.getUsers().subscribe(users => this.onDataLoadSuccessful(users, this.accountService.currentUser.roles.map(x => new Role(x))), error => this.onDataLoadFailed(error));
    }
  }

  onDataLoadSuccessful(users: User[], roles: Role[]) {
    this.alertService.stopLoadingMessage();
    //this.loadingIndicator = false;

    users.forEach((user, index, users) => {
      (user as any).index = index + 1;
    });


    this.allRoles = roles;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    //this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

}
