import { Auditentity } from './auditentity.model';

export class Question extends Auditentity {

  constructor
    (
      id?: number,
      version?: number,
      versionName?: string,
      excelColumnName?: string,
      filename?: string,
      dimensionInfoJSON?: string,
      questionDetails?: QuestionDTL[]
    ) {
    super();
    this.id = id;
    this.version = version;
    this.versionName = versionName;
    this.excelColumnName = excelColumnName;
    this.filename = filename;
    this.dimensionInfoJSON = dimensionInfoJSON;
    this.questionDetails = questionDetails;
  }

  public id: number;
  public version: number;
  public versionName: string;
  public excelColumnName: string;
  public filename: string;
  public dimensionInfoJSON: string;
  public questionDetails: QuestionDTL[];
}

export class QuestionDTL {

  constructor(
    id?: number,
    questionid?: number,
    customdbid?: string,
    version?: number,
    questionDetailJSON?: string,
    isChecked?:boolean,
    slNo?: string) {

    this.id = id;
    this.questionid = questionid;
    this.customdbid = customdbid;
    this.version = version;
    this.questionDetailJSON = questionDetailJSON;
    this.isChecked = isChecked;
    this.slNo = slNo;
  }
  public id: number;
  public questionid: number;
  public customdbid: string;
  public version: number;
  public questionDetailJSON: string;
  public isChecked: boolean;
  public slNo: string;
}
