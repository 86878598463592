import { Component, ViewChild } from '@angular/core';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';
import { AppComponent } from '../app.component';
import { Client } from '../../models/client.model';
import * as Enumerable from '../../assets/scripts/Linq/linq';

@Component({
  selector: 'client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss']
})

export class ClientEditorComponent {

  private isNewRole = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public clientEdit: Client = new Client();
  public allPermissions: Permission[] = [];
  public selectedValues: { [key: string]: boolean; } = {};
  private editingClientName: string;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;


  @ViewChild('f', { static: false })
  private form;

  obj: Client = new Client;
  module: any = 'Client';

  constructor(private alertService: AlertService, private accountService: AccountService, public appComponent: AppComponent) {
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  save() {
    this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {
      if (this.clientEdit.name == null || typeof this.clientEdit.name == 'undefined' || this.clientEdit.name.length == 0) {
        this.alertService.showDialog('Organization name is required.', DialogType.alert);
        return;
      }
      if (this.clientEdit.name != "" && this.clientEdit.name != null && typeof this.clientEdit.name != 'undefined') {
        if (this.clientEdit.name.trim() == "") {
          this.alertService.showDialog('Please enter a valid private equity company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio1 != "" && this.clientEdit.portfolio1 != null && typeof this.clientEdit.portfolio1 != 'undefined') {
        if (this.clientEdit.portfolio1.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio2 != "" && this.clientEdit.portfolio2 != null && typeof this.clientEdit.portfolio2 != 'undefined') {
        if (this.clientEdit.portfolio2.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio3 != "" && this.clientEdit.portfolio3 != null && typeof this.clientEdit.portfolio3 != 'undefined') {
        if (this.clientEdit.portfolio3.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio4 != "" && this.clientEdit.portfolio4 != null && typeof this.clientEdit.portfolio4 != 'undefined') {
        if (this.clientEdit.portfolio4.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio5 != "" && this.clientEdit.portfolio5 != null && typeof this.clientEdit.portfolio5 != 'undefined') {
        if (this.clientEdit.portfolio5.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio6 != "" && this.clientEdit.portfolio6 != null && typeof this.clientEdit.portfolio6 != 'undefined') {
        if (this.clientEdit.portfolio6.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      if (this.clientEdit.portfolio7 != "" && this.clientEdit.portfolio7 != null && typeof this.clientEdit.portfolio7 != 'undefined') {
        if (this.clientEdit.portfolio7.trim() == "") {
          this.alertService.showDialog('Please enter a valid portfolio company name.', DialogType.alert);
          return;
        }
      }
      this.clientEdit.createdby = this.accountService.currentUser.id;
      this.clientEdit.isdeleted = false;
      this.clientEdit.isget = false;
      this.accountService.Set(this.module, this.clientEdit).subscribe(
        () => {
          this.alertService.showDialog('Saved Successfully.', DialogType.alert);
          this.clientEdit = new Client();
          this.resetForm();

          if (this.changesSavedCallback) {
            this.changesSavedCallback();
          }
        },
        error => {
          this.appComponent.failedHelper(error);
        });
    });
  }

  cancel() {
    this.clientEdit = new Client();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  newRole(allPermissions: Permission[]) {
    this.isNewRole = true;
    this.showValidationErrors = true;

    this.editingClientName = null;
    this.allPermissions = allPermissions;
    this.selectedValues = {};
    this.clientEdit = new Client();

    return this.clientEdit;
  }

  editRole(client: Client, allPermissions: Permission[]) {
    if (client) {
      this.isNewRole = false;
      this.showValidationErrors = true;

      this.editingClientName = client.name;
      this.allPermissions = allPermissions;
      this.selectedValues = {};
      this.clientEdit = new Client();
      Object.assign(this.clientEdit, client);

      return this.clientEdit;
    } else {
      return this.newRole(allPermissions);
    }
  }
}
