import { Auditentity } from "./auditentity.model";

export class Digitalleversupload extends Auditentity {
  constructor(createdby?: string, postedFile?: string, fileName?: string) {
    super();
    this.createdby = createdby;
    this.postedFile = postedFile;
    this.fileName = fileName;
  }

  public createdby: string;
  public postedFile: string;
  public fileName: string;
}
