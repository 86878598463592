export type PermissionNames =
    'View Users' | 'Manage Users' |
    'View Roles' | 'Manage Roles' | 'Assign Roles';

export type PermissionValues =
    'users.view' | 'users.manage' |
  'roles.view' | 'roles.manage' | 'roles.assign' | 'clients.view' | 'clients.manage' |
  'categories.view' | 'categories.manage' | 'subcategories.manage' | 'assmnt.view' | 'assmnt.manage' |
  'templates.view' | 'templates.manage' | 'questions.view' | 'questions.manage' | 'admin.manage';

export class Permission {

    public static readonly viewUsersPermission: PermissionValues = 'users.view';
    public static readonly manageUsersPermission: PermissionValues = 'users.manage';

    public static readonly viewRolesPermission: PermissionValues = 'roles.view';
    public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
    public static readonly assignRolesPermission: PermissionValues = 'roles.assign';

    public static readonly viewClientsPermission: PermissionValues = 'clients.view';
    public static readonly manageClientsPermission: PermissionValues = 'clients.manage';
    public static readonly viewAssmntPermission: PermissionValues = 'assmnt.view';
    public static readonly manageAssmntPermission: PermissionValues = 'assmnt.manage'
    public static readonly ViewcategoriesPermission: PermissionValues = 'categories.view';
    public static readonly managecategoriesPermission: PermissionValues = 'categories.manage';
    public static readonly ViewsubcategoriesPermission: PermissionValues = 'subcategories.manage';
    public static readonly viewTemplatePermission: PermissionValues = 'templates.view';
    public static readonly manageTemplatePermission: PermissionValues = 'templates.manage';
    public static readonly viewQuestionsPermission: PermissionValues = 'questions.view';
    public static readonly manageQuestionsPermission: PermissionValues = 'questions.manage';
    public static readonly adminpermissions: PermissionValues = 'admin.manage';

    constructor(name?: PermissionNames, value?: PermissionValues, groupName?: string, description?: string) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;
}
