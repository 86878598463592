import { Auditentity } from './auditentity.model';

export class Client extends Auditentity {

  constructor(id?: number
    , name?: string
    , portfolio1?: string
    , portfolio2?: string
    , portfolio3?: string
    , portfolio4?: string
    , portfolio5?: string
    , portfolio6?: string
    , portfolio7?: string
    , isEnabled?: boolean,) {
    super();
    this.id = id;
    this.name = name;
    this.portfolio1 = portfolio1;
    this.portfolio2 = portfolio2;
    this.portfolio3 = portfolio3;
    this.portfolio4 = portfolio4;
    this.portfolio5 = portfolio5;
    this.portfolio6 = portfolio6;
    this.portfolio7 = portfolio7;
    this.isEnabled = isEnabled;
  }

  public id: number;
  public name: string;
  public portfolio1: string;
  public portfolio2: string;
  public portfolio3: string;
  public portfolio4: string;
  public portfolio5: string;
  public portfolio6: string;
  public portfolio7: string;
  public isEnabled: boolean;
}
