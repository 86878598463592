import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppComponent } from '../app.component';
import { AssessmentQuestions, Assessment } from '../../models/assessment.model';
import { Template } from '../../models/template.model';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Tree from 'highcharts/modules/treemap';
import Heatmap from 'highcharts/modules/heatmap';
More(Highcharts);
Tree(Highcharts);
Heatmap(Highcharts);
import { Router } from '@angular/router';

@Component({
  selector: 'reportrelevantvaluepoolsprint',
  templateUrl: './reportrelevantvaluepoolsprint.component.html',
  styleUrls: ['./reportrelevantvaluepoolsprint.component.scss'],
  animations: [fadeInOut]
})

export class ReportrelevantvaluepoolsprintComponent implements OnInit {
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp = {};
  questionWithResponseList: any[] = [];
  currentAssessment: Assessment = new Assessment();
  obj: AssessmentQuestions = new AssessmentQuestions();
  generateReportFlag: number = 0;
  responseData: any = [];
  Highcharts = Highcharts;
  overallScore: number = 0;
  isQuantitative: boolean;
  totalRevenues: number = 0;
  plList: any = [];

  chartOptionsheatmap1 = {
    colorAxis: {
      minColor: /*'#fec1a0'*/ '#FD6412',
      maxColor: /*'#fd6412'*/ '#571F01'
    },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: []
    }],
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () { },
      shared: true
    }
  };

  chartOptionsheatmap2 = {
    colorAxis: {
      minColor: /*'#fec1a0'*/ '#FD6412',
      maxColor: /*'#fd6412'*/ '#571F01'
    },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: []
    }],
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () { },
      shared: true
    }
  };

  chartOptionsheatmap3 = {
    colorAxis: {
      minColor: '#444',
      maxColor: '#ccc'
    },
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'squarified',
      data: []
    }],
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () { },
      shared: true
    }
  };

  constructor(private alertService: AlertService, public appComponent: AppComponent, private accountService: AccountService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadAssessment();
      this.loadResponses();
      this.loadHeatChartData();
    }, 10000)

   
  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.children.length > 0) {
        obj.children.forEach(c => {
          this.temp = {};
          this.temp[obj.prop] = obj.name;
          this.temp[c.prop] = c.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(c);
        });
      }
    }
  }

  loadAssessment() {
    if (localStorage.getItem('printTemplate') != null && typeof localStorage.getItem('printTemplate') != 'undefined') {
      this.currentAssessment = JSON.parse(localStorage.getItem('printAssessment')) as Assessment;
      this.questionWithResponseList = JSON.parse((JSON.parse(localStorage.getItem('printAssessment')) as Assessment).userResponses);
      this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('printTemplate')) as Template).treeStructure);
      this.isQuantitative = (JSON.parse(localStorage.getItem('printTemplate')) as Template).isQuantitative;

      this.moduleTreeCache.forEach(f => {
        if (f.ischecked) {
          this.temp = {};
          this.temp[f.prop] = f.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(f);
        }
      });

      this.generateReportFlag = 0
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.response == 0 && (q.other == null || typeof q.other == 'undefined' || q.other == '')) {
            this.generateReportFlag++;
          }
        });
      }
    }
  }

  loadResponses() {
    this.responseData = [];
    let questionStructure = Enumerable.from(JSON.parse((JSON.parse(localStorage.getItem('printTemplate')) as Template).questionStructure)).select(s => s['columndata']);

    this.moduleTree.forEach(f => {
      if (Object.keys(f).length == 1) {
        let desc = Enumerable.from(this.moduleTreeCache).where(x => x.name == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();
        this.responseData.push({ short: '', full: f[Object.keys(f)[0]], xxx: 0, xxxtarget: 0, target: 0, total: 0, dimension: '', checked: false, showmore: false, description: desc });
      }
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.columndata != null && typeof q.columndata != 'undefined') {
            if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]]) {
              if (typeof q.columndata[Object.keys(f)[1]] != 'undefined') {
                let short = '';
                if (Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).any()) {
                  short = Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).firstOrDefault().Abbreviation;
                }
                if (!Enumerable.from(this.responseData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                  var response = 0, responsecount = 0, responsetarget = 0, responsetargetcount = 0, benchmark = 0, benchmarkcount = 0;
                  this.questionWithResponseList.forEach(s => {
                    if (s.columndata != null && typeof s.columndata != 'undefined') {
                      if (s.columndata['Sub-Dimension'] == q.columndata[Object.keys(f)[0]]) {
                        response += parseFloat(s.response);
                        responsecount++;
                        responsetarget += parseFloat(s.responsetarget);
                        responsetargetcount++;
                        benchmark += parseFloat(s.benchmarkValue);
                        benchmarkcount++;
                      }
                    }
                  });
                  let subdim = Enumerable.from(this.moduleTreeCache).where(x => x.name == q.columndata['Dimension']).select(y => y["children"]).firstOrDefault();
                  let desc = Enumerable.from(subdim).where(x => x['name'] == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();

                  let xxx = 0, xxxtarget = 0, target = 0;
                  try {
                    if (!isNaN(response) && !isNaN(responsecount))
                      xxx = response / responsecount;
                    else
                      xxx = 0;
                    if (!isNaN(responsetarget) && !isNaN(responsetargetcount))
                      xxxtarget = responsetarget / responsetargetcount;
                    else
                      xxxtarget = 0;
                    if (!isNaN(benchmark) && !isNaN(benchmarkcount))
                      target = benchmark / benchmarkcount;
                    else
                      target = 0;
                  }
                  catch {
                    xxx = 0;
                    xxxtarget = 0;
                    target = 0;
                  }

                  this.responseData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: xxx, xxxtarget: xxxtarget, target: target, total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false, description: desc });
                }
              }
            }
          }
        });
      }
    });

    this.responseData.forEach((data, index, datas) => {
      (data as any).index = index + 1;
    });

    let dimcount = 0, dimscore = 0;

    this.responseData.forEach(f => {
      if (f.short != '') {
        try {
          if (!isNaN(f.xxx) && !isNaN(f.target))
            f.total = Math.round((f.xxx / f.target) * 100);
          else
            f.total = 0;
        }
        catch {
          f.total = 0;
        }
      }
      else {
        let nextIndex = Enumerable.from(this.responseData).max(m => m['index']) + 1;

        if (Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').any()) {
          nextIndex = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').firstOrDefault()['index'];
        }

        var dimensionAverage = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxx']);
        var dimensionAverageTarget = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxxtarget']);

        try {
          if (!isNaN(dimensionAverage) && !isNaN(dimensionAverageTarget)) {
            f.xxx = dimensionAverage;
            f.xxxtarget = dimensionAverageTarget;
            f.target = 5;
            f.total = Math.round((dimensionAverage / 5) * 100);
          }
          else {
            f.xxx = 0;
            f.xxxtarget = 0;
            f.target = 0;
            f.total = 0;
          }
        }
        catch {
          f.xxx = 0;
          f.xxxtarget = 0;
          f.target = 0;
          f.total = 0;
        }

        dimscore += f.total;
        dimcount++;
      }
    });

    this.overallScore += Math.round(dimscore / dimcount);
  }

  loadHeatChartData() {
    let templpl: any;

    if (this.currentAssessment.plStructure != null) {
      templpl = JSON.parse(this.currentAssessment.plStructure);
    }
    else if (JSON.parse(localStorage.getItem('printTemplate'))['plStructure']) {
      templpl = JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure']);
    }
    if (templpl != null) {
      templpl.children01.forEach(f => {
        this.plList.push({ category: 'Revenue', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children02.forEach(f => {
        this.plList.push({ category: 'Revenue', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children04.forEach(f => {
        this.plList.push({ category: 'Variable Cost', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children06.forEach(f => {
        this.plList.push({ category: 'Fixed Cost', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children08.forEach(f => {
        this.plList.push({ category: 'SG&A & OPEX', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children09.forEach(f => {
        this.plList.push({ category: 'Other Expenses', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });
      templpl.children10.forEach(f => {
        this.plList.push({ category: '', name: f.name, description: f.description, value1: f.value1, value2: f.value2 });
      });

      let templ01 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children01 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children01'];
      let templ02 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children02 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children02'];
      let templ03 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children03 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children03'];
      let templ04 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children04 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children04'];
      let templ06 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children06 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children06'];
      let templ08 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children08 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children08'];
      let templ09 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children09 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children09'];
      let templ10 = this.currentAssessment.plStructure != null ? JSON.parse(this.currentAssessment.plStructure).children10 : JSON.parse(JSON.parse(localStorage.getItem('printTemplate'))['plStructure'])['children10'];
      if (templ03) {
        if (Enumerable.from(templ03).where(x => x['name'] === 'Total Revenues').any())
          this.totalRevenues = Enumerable.from(templ03).where(x => x['name'] === 'Total Revenues').firstOrDefault()['value1'];
        else
          this.totalRevenues = 0;
      }

      if (templ01) {
        templ01.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap1.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 10) / 10,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100)
            });
          }
        });
      }

      if (templ02) {
        templ02.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap1.series[0].data.push({
              name: f.name,
              value: Math.round(Math.round(f.value1 / this.totalRevenues * 100) * 10) / 10,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100),
            });
          }
        });
      }

      if (templ04) {
        templ04.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
            this.chartOptionsheatmap2.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 100) / 100,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100),
            });
        });
      }

      if (templ06) {
        templ06.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap2.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 100) / 100,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100),
            });
          }
        });
      }

      if (templ08) {
        templ08.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap2.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 100) / 100,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100),
            });
          }
        });
      }

      if (templ09) {
        templ09.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap2.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 100) / 100,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100),
            });
          }
        });
      }

      if (templ10) {
        templ10.forEach(f => {
          if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1)) {
            this.chartOptionsheatmap3.series[0].data.push({
              name: f.name,
              value: Math.round((f.value1 / this.totalRevenues * 100) * 100) / 100,
              colorValue: Math.round(f.value1 / this.totalRevenues * 100)
            });
          }
        });
      }
    }

    let pList = this.plList;
    let tRevenues = this.totalRevenues;
    this.chartOptionsheatmap1.tooltip.formatter = function () {
      var tooltip = '';
      var hoverpl = Enumerable.from(pList).where(x => x['name'] == this.key).firstOrDefault();
      if (hoverpl['category'] != '')
        tooltip += '<label style="font-size: 16px; font-weight: bold; text-decoration: underline;">' + hoverpl['category'] + '</label>' + '<br>';
      if (hoverpl['name'] != '')
        tooltip += '<label style="font-size: 15px;">' + hoverpl['name'] + '</label>' + '<br>';
      if (hoverpl['value1'] != '')
        tooltip += '<label style="font-size: 15px;">' + Highcharts.numberFormat(hoverpl['value1'] / tRevenues * 100, 2, '.', ', ') + '%</label>' + '<br>';
      if (hoverpl['description'] != '')
        tooltip += '<label style="font-size: 15px;">' + hoverpl['description'] + '</label>';
      if (tooltip != '')
        return tooltip;
      else
        return null;
    };

    this.chartOptionsheatmap2.tooltip.formatter = function () {
      var tooltip = '';
      var hoverpl = Enumerable.from(pList).where(x => x['name'] == this.key).firstOrDefault();
      if (hoverpl['category'] != '')
        tooltip += '<label style="font-size: 16px; font-weight: bold; text-decoration: underline;">' + hoverpl['category'] + '</label>' + '<br>';
      if (hoverpl['name'] != '')
        tooltip += '<label style="font-size: 15px;">' + hoverpl['name'] + '</label>' + '<br>';
      if (hoverpl['value1'] != '')
        tooltip += '<label style="font-size: 15px;">' + Highcharts.numberFormat(hoverpl['value1'] / tRevenues * 100, 2, '.', ', ') + '%</label>' + '<br>';
      if (hoverpl['description'] != '')
        tooltip += '<label style="font-size: 15px;"><br>' + hoverpl['description'] + '</label>';
      if (tooltip != '')
        return tooltip;
      else
        return null;
    };

    this.chartOptionsheatmap3.tooltip.formatter = function () {
      var tooltip = '';
      var hoverpl = Enumerable.from(pList).where(x => x['name'] == this.key).firstOrDefault();
      if (hoverpl['category'] != '')
        tooltip += '<label style="font-size: 16px; font-weight: bold; text-decoration: underline;">' + hoverpl['category'] + '</label>' + '<br>';
      if (hoverpl['name'] != '')
        tooltip += '<label style="font-size: 15px;">' + hoverpl['name'] + '</label>' + '<br>';
      if (hoverpl['value1'] != '')
        tooltip += '<label style="font-size: 15px;">' + Highcharts.numberFormat(hoverpl['value1'] / tRevenues * 100, 2, '.', ', ') + '%</label>' + '<br>';
      if (hoverpl['description'] != '')
        tooltip += '<label style="font-size: 15px;">' + hoverpl['description'] + '</label>';
      if (tooltip != '')
        return tooltip;
      else
        return null;
    };
  }
}
