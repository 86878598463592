export class ExternalUserInvite {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(email?: string, role?: string, requestingUserEmail?: string, orgId?: string, Product?: string
    , product_list?: string, emailLanguage?: string, state?: string, apikey?: string, appid?: string, apiurl?: string) {

    this.email = email;
    this.role = role;
    this.requestingUserEmail = requestingUserEmail;
    this.orgId = orgId;
    this.Product = Product;
    this.product_list = product_list;
    this.emailLanguage = emailLanguage;
    this.state = state;

    this.apikey = apikey;
    this.appid = appid;
    this.apiurl = apiurl;

  }


  public email: string;
  public role: string;
  public requestingUserEmail: string;
  public orgId: string;
  public Product: string; ing
  public product_list: string;
  public emailLanguage: string;
  public state: string;
  public apikey: string;
  public appid: string;
  public apiurl: string;

}
