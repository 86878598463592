import { Component, ElementRef, ViewChild, Input } from "@angular/core";
import {
  AlertService,
  MessageSeverity,
  DialogType,
} from "../../services/alert.service";
import { AccountService } from "../../services/account.service";
import { Permission } from "../../models/permission.model";
import { AppComponent } from "../app.component";
import { Question } from "../../models/question.model";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "question-editor",
  templateUrl: "./question-editor.component.html",
  styleUrls: ["./question-editor.component.scss"],
})
export class QuestionEditorComponent {
  private isNewRole = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public questionEdit: Question = new Question();
  public allPermissions: Permission[] = [];
  public selectedValues: { [key: string]: boolean } = {};
  private editingQuestionName: string;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input() modalClose = "";

  @ViewChild("f", { static: false })
  private form;

  @ViewChild("file", { static: false })
  myInputVariable: ElementRef;

  obj: Question = new Question();
  catList: string[] = [];
  subcatList: string[] = [];
  module: any = "Question";
  public progress: number;
  public filename: string;
  protected formData: FormData;

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  ngOnChanges() {
    if (this.modalClose == "close") {
      this.formData = null;
    }
  }
  upload(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    var ext =
      fileToUpload.name.split(".")[fileToUpload.name.split(".").length - 1];

    if (ext.trim() != "xlsx") {
      this.alertService.showMessage(
        "Unsupported file",
        "Not supported file extension found",
        MessageSeverity.error
      );
      this.myInputVariable.nativeElement.value = "";
      return false;
    }

    this.filename = fileToUpload.name;

    this.formData = new FormData();
    this.formData.append(this.filename, fileToUpload, this.filename);
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  save() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (this.formData == null || typeof this.formData == "undefined") {
          this.alertService.showDialog(
            "Select a file to proceed.",
            DialogType.alert
          );
          return;
        }

        this.http
          .post("api/Upload", this.formData, {
            headers: new HttpHeaders({
              "X-XSRF-TOKEN": this.cookieService.get("XSRF-TOKEN"),
            }),
            reportProgress: true,
            observe: "events",
          })
          .subscribe(
            (event) => {
              if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round((100 * event.loaded) / event.total);
              else if (event.type === HttpEventType.Response) {
                let fileurl = event.body.toString();

                if (fileurl.length == 0) {
                  this.alertService.showDialog(
                    `Select a file to proceed.`,
                    DialogType.alert
                  );
                  return;
                }

                this.myInputVariable.nativeElement.value = ""; //clear fileupload control
                this.formData = null; //clear file content.

                this.questionEdit.filename = fileurl;
                this.questionEdit.createdby =
                  this.accountService.currentUser.id;
                this.questionEdit.isdeleted = false;
                this.questionEdit.isget = false;

                this.accountService
                  .Set(this.module, this.questionEdit)
                  .subscribe(
                    () => {
                      this.filename = "";
                      this.questionEdit = new Question();
                      this.resetForm();
                      if (this.changesSavedCallback) {
                        this.changesSavedCallback();
                      }
                      this.alertService.showDialog(
                        "Saved Successfully.",
                        DialogType.alert
                      );
                      this.formData = null;
                    },
                    (error) => {
                      error.message =
                        "Something went wrong. Please check the file format and try again.";
                      this.appComponent.failedHelper(error);
                    }
                  );
              }
            },
            () => {
              const message =
                "Something went wrong. Please check the file format and try again.";
                this.alertService.showDialog(message, DialogType.alert);
            }
          );
      }
    );
  }

  cancel() {
    this.questionEdit = new Question();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  newRole(allPermissions: Permission[]) {
    this.isNewRole = true;
    this.showValidationErrors = true;

    this.editingQuestionName = null;
    this.allPermissions = allPermissions;
    this.selectedValues = {};
    this.questionEdit = new Question();

    return this.questionEdit;
  }

  editRole(question: Question, allPermissions: Permission[]) {
    if (question) {
      this.isNewRole = false;
      this.showValidationErrors = true;
      this.allPermissions = allPermissions;
      this.selectedValues = {};
      this.questionEdit = new Question();
      Object.assign(this.questionEdit, question);

      return this.questionEdit;
    } else {
      return this.newRole(allPermissions);
    }
  }
}
