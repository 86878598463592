import { Auditentity } from './auditentity.model';

export class Opportunities extends Auditentity {

  constructor(
    id?: number,
    serialno?: number,
    timestamp?: any,
    digitalkpi?: string,
    description?: string,
    currentlevel?: number,
    industrylevel?: number,
    bestcaselevel?: number,
    potential?: number,
    conversiontoebitda?: number,
    source?: string,
    opportunityformula?: string,
    risk?: string,
    risklevel?: string,
    comments?: string,
    isenabled?: boolean
  ) {
    super();
    this.id = id;
    this.serialno = serialno;
    this.timestamp = timestamp;
    this.digitalkpi = digitalkpi;
    this.description = description;
    this.currentlevel = currentlevel;
    this.industrylevel = industrylevel;
    this.bestcaselevel = bestcaselevel;
    this.potential = potential;
    this.conversiontoebitda = conversiontoebitda;
    this.source = source;
    this.opportunityformula = opportunityformula;
    this.risk = risk;
    this.risklevel = risklevel;
    this.comments = comments;
    this.isenabled = isenabled;
  }
  public id: number;
  public serialno: number;
  public timestamp: any;
  public digitalkpi: string;
  public description: string;
  public currentlevel: number;
  public industrylevel: number;
  public bestcaselevel: number;
  public potential: number;
  public conversiontoebitda: number;
  public source: string;
  public opportunityformula: string;
  public risk: string;
  public risklevel: string;
  public comments: string;
  public isenabled: boolean
}
