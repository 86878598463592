// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AlertService, AlertDialog, DialogType, AlertCommand, AlertMessage, MessageSeverity } from '../services/alert.service';
import { NotificationService } from '../services/notification.service';
import { AppTranslationService } from '../services/app-translation.service';
import { AccountService } from '../services/account.service';
import { LocalStoreManager } from '../services/local-store-manager.service';
import { AppTitleService } from '../services/app-title.service';
import { AuthService } from '../services/auth.service';
import { ConfigurationService } from '../services/configuration.service';
import { Permission } from '../models/permission.model';
import { LoginComponent } from '../components/login/login.component';
import { Utilities } from '../services/utilities';
import { environment } from '../../environments/environment';
import * as $ from 'jquery'
import { User } from '../models/user.model';
import { CookieService } from 'ngx-cookie-service';

const alertify: any = require('../assets/scripts/alertify.js');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  shouldShowLoginModal: boolean;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  //appTitle = 'Digital Opportunity & Maturity Tool';
  appTitle = 'Digital Maturity Assessment';
  appLogo = require('../assets/images/pwc-logo.png');

  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  @ViewChildren('loginModal,loginControl')
  modalLoginControls: QueryList<any>;

  loginModal: ModalDirective;
  loginControl: LoginComponent;
  isRoleAdmin: boolean
  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  get notificationsTitle() {
    if (this.newNotificationCount) {
      return `${this.gT('app.Notifications')} (${this.newNotificationCount} ${this.gT('app.New')})`;
    } else {
      return this.gT('app.Notifications');
    }
  }


  constructor(
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public configurations: ConfigurationService,
    public router: Router,
    private cookieService: CookieService) {

    //// override the route reuse strategy
    //this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //  return false;
    //}

    //this.router.events.subscribe((evt) => {
    //  if (evt instanceof NavigationEnd) {
    //    // trick the Router into believing it's last link wasn't previously loaded
    //    this.router.navigated = false;
    //    // if you need to scroll back to top, here is the right place
    //    window.scrollTo(0, 0);
    //  }
    //});

    storageManager.initialiseStorageSyncListener();

    this.toastaConfig.theme = 'bootstrap';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;
    this.toastaConfig.showDuration = false;

    this.appTitleService.appName = this.appTitle;
  }

  

  ngAfterViewInit() {

    this.modalLoginControls.changes.subscribe((controls: QueryList<any>) => {
      controls.forEach(control => {
        if (control) {
          if (control instanceof LoginComponent) {
            this.loginControl = control;
            this.loginControl.modalClosedCallback = () => this.loginModal.hide();
          } else {
            this.loginModal = control;
            this.loginModal.show();
          }
        }
      });
    });
  }


  onLoginModalShown() {
    this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again', MessageSeverity.info);
  }


  onLoginModalHidden() {
    this.alertService.resetStickyMessage();
    this.loginControl.reset();
    this.shouldShowLoginModal = false;

    if (this.authService.isSessionExpired) {
      this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again to renew your session', MessageSeverity.warn);
    }
  }


  onLoginModalHide() {
    this.alertService.resetStickyMessage();
  }


  ngOnInit() {
    //get identity authorisation code
    const queryString = window.location.href;
    let urlParams = new URL(queryString);
    if (urlParams.searchParams.has('code')) {
      localStorage.setItem("idamcode", urlParams.searchParams.get('code'));
    }

    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();
        this.alertService.showMessage('Login', `Welcome back ${this.fullName}!`, MessageSeverity.default);
      }
    }, 2000);


    this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));

    this.authService.reLoginDelegate = () => this.shouldShowLoginModal = true;

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;


      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
      } else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage('Session Ended!', '', MessageSeverity.default);
        }
      }, 500);
    });
  }


  ngOnDestroy() {
    this.unsubscribeNotifications();
  }


  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }



  initNotificationsLoading() {

    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailures = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
        error => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailures++ < 20) {
            setTimeout(() => this.initNotificationsLoading(), 5000);
          } else {
            this.alertService.showStickyMessage('Load Error', 'Loading new notifications from the server failed!', MessageSeverity.error);
          }
        });
  }


  markNotificationsAsRead() {

    const recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (const n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage('Notification Error', 'Marking read notifications failed', MessageSeverity.error);

          });
    }
  }



  showDialog(dialog: AlertDialog) {

    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          }, dialog.defaultValue);

        break;
    }
  }



  showToast(alert: AlertCommand) {

    if (alert.operation == 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail,
    };


    if (alert.operation == 'add_sticky') {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }


    switch (alert.message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }



  clearsession() {
    sessionStorage.clear();
    localStorage.removeItem("idam");
    localStorage.clear();
    this.deleteAllCookies();

    this.cookieService.deleteAll();

    this.cookieService.delete('XSRF-TOKEN', '/', window.location.host);
    this.cookieService.delete('ARRAffinity', '/', window.location.host);
    this.cookieService.delete('ARRAffinitySameSite', '/', window.location.host);
    this.authService.logout();
    this.IAMLogout();
    this.authService.redirectLogoutUser();
  }

  IAMLogout() {
    //stage
    //const url = `${environment.idam.authorization_endpoint}response_type=code&client_id=${environment.idam.client_app_id}&redirect_uri=${encodeURIComponent(environment.idam.app_url)}&scope=openid+email+profile+uid+cloudEmail+preferredMail+employeeNumber+country+pwcUserType`;
    const url = `${environment.idam.logout_url}`;

    window.location.href = url;

  }
  logout() {
    
    let objuser: User = new User();
    if (this.accountService.currentUser != null) {
      this.alertService.startLoadingMessage();
      objuser.email = this.accountService.currentUser.email;
      this.accountService.SetData<string>('LoginHandler/ClearUserSession', objuser).subscribe(response => {
        this.alertService.stopLoadingMessage();
        this.clearsession();

      },
        error => {

          this.alertService.stopLoadingMessage();
          this.clearsession();

        });
    }
    else {

    }

  }

  deleteAllCookies() {


    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++)
      document.cookie = cookies[i] + "=;expires="
        + new Date(0).toUTCString();

  }

  getYear() {
    return new Date().getUTCFullYear();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : '';
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : '';
  }

  currentUserRole() {

    if (this.authService.currentUser.roles) {
      this.authService.currentUser.roles.forEach(r => {
        if (r.toLowerCase().includes("admin")) {
          this.isRoleAdmin = true;
        }
        else
          this.isRoleAdmin = false;

      });
    }
  }

  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); // eg. viewCustomersPermission
  }

  get canViewProducts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission); // eg. viewProductsPermission
  }

  get canViewOrders() {
    return true; // eg. viewOrdersPermission
  }
  get canViewOrganisations() {
    return this.accountService.userHasPermission(Permission.viewClientsPermission);
  }
  get canManageOrganisations() {
    return this.accountService.userHasPermission(Permission.manageClientsPermission);
  }
  get canViewQuestions() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }
  get canViewTemplates() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }
  get canViewAssessment() {
    return this.accountService.userHasPermission(Permission.viewAssmntPermission);
  }
  get canManageAssessment() {
    return this.accountService.userHasPermission(Permission.manageAssmntPermission);
  }
  get isAdmin() {
    return this.accountService.userHasPermission(Permission.manageUsersPermission);
  }
  failedHelper(error: any) {
    this.alertService.showDialog(Utilities.getHttpResponseMessage(error).toString(), DialogType.alert);
  }
}
