import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { Opportunities } from '../../models/opportunities.model';
import { OpportunitiesEditorComponent } from './opportunities-editor.component';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'opportunities-management',
  templateUrl: './opportunities-management.component.html',
  styleUrls: ['./opportunities-management.component.scss'],
  providers: [DatePipe]
})

export class OpportunitiesManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: Opportunities[] = [];
  rowsCache: Opportunities[] = [];
  editedOpportunities: Opportunities;
  sourceOpportunities: Opportunities;
  editingOpportunities: { digitalkpi: string };
  loadingIndicator: boolean;
  searchText: string;
  
  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal', { static: true })
  editorModal: ModalDirective;

  module: any = 'Opportunities';
    obj: Opportunities=new Opportunities();
  @ViewChild('opportunitiesEditor', { static: true })
  opportunitiesEditor: OpportunitiesEditorComponent;
  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, public appComponent: AppComponent, private router: Router, private datePipe: DatePipe) {
  }
   
  ngOnInit() {
    if (this.appComponent.isAdmin) {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        { prop: 'serialno', name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
        { prop: 'digitalkpi', name: 'Digital KPI' },
        { prop: 'kpiname', name: 'KPI (Name)' },
        { prop: 'impact', name: 'Impact of the Lever' },
        { prop: 'businessunit', name: 'Business Unit' },
        { prop: 'timestamp', name: 'Date Last Modified' },
        { name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
      ];

      this.loadData();
    }
  }

 
  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.opportunitiesEditor.changesSavedCallback = () => {
        this.addNewOpportunitiesToList();
        this.editorModal.hide();
      };

      this.opportunitiesEditor.changesCancelledCallback = () => {
        this.editedOpportunities = null;
        this.sourceOpportunities = null;
        this.editorModal.hide();
      };
    }
  }

  addNewOpportunitiesToList() {
    if (this.sourceOpportunities) {
      Object.assign(this.sourceOpportunities, this.editedOpportunities);

      let sourceIndex = this.rowsCache.indexOf(this.sourceOpportunities, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceOpportunities, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedOpportunities = null;
      this.sourceOpportunities = null;
    } else {
      const opportunities = new Opportunities();
      Object.assign(opportunities, this.editedOpportunities);
      this.editedOpportunities = null;

      let maxIndex = 0;
      for (const r of this.rowsCache) {
        if ((r as any).index > maxIndex) {
          maxIndex = (r as any).index;
        }
      }

      (opportunities as any).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, opportunities);
      this.rows.splice(0, 0, opportunities);
      this.rows = [...this.rows];
    }
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.obj.isget = true;
    this.accountService.Set(this.module, this.obj).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const Opportunitiess = results;

        Opportunitiess.forEach(item => {
          if (item.timestamp == "0001-01-01")
            item.timestamp = null;
          item.timestamp = this.datePipe.transform(item.timestamp, 'MM-dd-yyyy hh:mm:ss a');
        });

        this.rowsCache = [...Opportunitiess];
        this.rows = Opportunitiess;
      },
      error => {
         this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve Opportunitiess from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.digitalkpi, r.currentlevel, r.industrylevel, r.bestcaselevel, r.potential));
  }
    
  onEditorModalHidden() {
    this.editingOpportunities = null;
    this.opportunitiesEditor.resetForm(true);
    this.loadData();
    this.searchText = "";
  }

  new() {
    this.editingOpportunities = null;
    this.sourceOpportunities = null;
    this.editedOpportunities = this.opportunitiesEditor.new();
    this.editorModal.show();
  }

  edit(row: Opportunities) {
    this.editingOpportunities = { digitalkpi: row.digitalkpi };
    this.sourceOpportunities = row;
    this.editedOpportunities = this.opportunitiesEditor.edit(row);
    this.editorModal.show();
  }

  delete(obj: Opportunities) {
    this.alertService.showDialog('Are you sure you want to delete \"' + obj.digitalkpi + '\"?', DialogType.confirm, () => {
      obj.createdby = this.accountService.currentUser.id;
      obj.isdeleted = true;
      obj.isget = false;
      this.accountService.Set(this.module, obj).subscribe(
        () => {
          this.alertService.showDialog(`Deleted Successfully.`, DialogType.alert);

          this.rowsCache = this.rowsCache.filter(item => item !== obj);
          this.rows = this.rows.filter(item => item !== obj);

          this.loadData();

        }, error => { this.appComponent.failedHelper(error); });
    });
  }
}
