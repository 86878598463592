import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppComponent } from '../app.component';
import { Workbook } from 'exceljs';
const EXCEL_EXTENSION = '.xlsx';
import * as fs from 'file-saver';
import { AssessmentQuestions, Assessment } from '../../models/assessment.model';
import { Template } from '../../models/template.model';
import { Client } from '../../models/client.model';
import { AccountService } from '../../services/account.service';
import { Report } from '../../models/report.model';
import { AuthService } from '../../services/auth.service';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
import { Router } from '@angular/router';
Drilldown(Highcharts);

@Component({
  selector: 'reportqualitativechart-management',
  templateUrl: './reportqualitativechart.component.html',
  styleUrls: ['./reportqualitativechart.component.scss'],
  animations: [fadeInOut]
})

export class ReportqualitativechartComponent implements OnInit, AfterViewInit {
  module = "Reports"
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp = {};
  questionWithResponseList: any[] = [];
  loadingIndicator: boolean;
  currentAssessment: Assessment = new Assessment();
  obj: AssessmentQuestions = new AssessmentQuestions();
  reportdata: AssessmentQuestions[] = [];
  responseListCalculated: AssessmentQuestions[] = [];
  generateReportFlag: number = 0;
  public clientObj: Client = new Client();
  public reportObj: Report = new Report();
  public orgList: Client[] = [];
  public clientlist: any[] = [];
  public compareData: Report[] = [];
  isQuantitative: boolean;
  checkedDimensions: any[] = [];

  //@ViewChild('radarChart', { static: true }) private chartRef;
  @ViewChild('authorSelector', { static: true }) private authorSelector;

  chartData: any = [];
  chart: any;
  compare: boolean = false;
  comparewith: any;
  viewChart: boolean = false;
  Highcharts = Highcharts; // required
  chartConstructor = 'chart'; // optional string, defaults to 'chart'  
  chartCallback;
  updateFlag: boolean = true; // optional boolean
  opportunityList: any = {};
  dimensionList: any = {};
  toogleCompareData: boolean = true;

  setClientComparisonIdList: any = [];

  chartOptionsRadar = {};

  chartOptionsPortfolio = {
    lang: {
      drillUpText: '<< Back'
    },
    chart: {
      type: 'column',
      events: {
        drilldown: function (e) {
          $("#portfolio .highcharts-title tspan").text("Portfolio Comparison - " + e.point.name);
        },
        drillup: function () {
          $("#portfolio .highcharts-title tspan").text("Portfolio Comparison");
        }
      }
    },
    title: { text: 'Portfolio Comparison' },
    xAxis: { type: 'category' },
    colors: [
      '#D04A02',
      '#FD6412',
      '#FEB791',
      '#FFDCA9',
      '#FFA929',
      '#EB8C00',
      '#D04A02',
      '#FD6412',
      '#FEB791'
    ],
    plotOptions: {
      //column: {
      //  colorByPoint: true
      //},
      series: {
        borderWidth: 0, dataLabels: {
          enabled: true,
          formatter: function () {
            return Math.round(this.y) + '%';
          },
        }
      }
    },
    series: [],
    drilldown: {
      allowPointDrilldown: false,
      series: []
    }
  };

  constructor(private alertService: AlertService, public appComponent: AppComponent, private accountService: AccountService, private authService: AuthService, private router: Router) {
    //this.chartCallback = chart => { this.chart = chart; };
  }

  ngOnInit() {
    this.loadData();
    this.radarChartData();
    this.loadOrganisation();

    this.setClientComparisonIdList = new Array<any>();
  }

  ngAfterViewInit() {

  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.children.length > 0) {
        obj.children.forEach(c => {
          this.temp = {};
          this.temp[obj.prop] = obj.name;
          this.temp[c.prop] = c.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(c);
        });
      }
    }
  }

  loadData() {
    if (localStorage.getItem('currentTemplateDetails') != null && typeof localStorage.getItem('currentTemplateDetails') != 'undefined') {
      this.currentAssessment = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
      this.questionWithResponseList = JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses);
      this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).treeStructure);
      this.isQuantitative = (JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).isQuantitative;

      this.moduleTreeCache.forEach(f => {
        if (f.ischecked) {
          this.temp = {};
          this.temp[f.prop] = f.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(f);
        }
      });

      this.generateReportFlag = 0
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.response == 0 && (q.other == null || typeof q.other == 'undefined' || q.other == '')) {
            this.generateReportFlag++;
          }
        });
      }
    }
  }

  loadOrganisation() {
    this.orgList = [];
    this.clientObj.isget = true;
    this.accountService.Set('client/getclients', this.clientObj).subscribe(
      results => {
        if (results) {
          Object.assign(this.orgList, results);
          this.orgList = Enumerable.from(this.orgList).where(x => x.id == (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).orgId).toArray();
          this.clientlist = [];
          this.orgList.forEach(f => {

            if (typeof f.portfolio1 != 'undefined' && f.portfolio1 != null && f.portfolio1.trim().length > 0 && f.portfolio1 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio1 });
            if (typeof f.portfolio2 != 'undefined' && f.portfolio2 != null && f.portfolio2.trim().length > 0 && f.portfolio2 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio2 });
            if (typeof f.portfolio3 != 'undefined' && f.portfolio3 != null && f.portfolio3.trim().length > 0 && f.portfolio3 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio3 });
            if (typeof f.portfolio4 != 'undefined' && f.portfolio4 != null && f.portfolio4.trim().length > 0 && f.portfolio4 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio4 });
            if (typeof f.portfolio5 != 'undefined' && f.portfolio5 != null && f.portfolio5.trim().length > 0 && f.portfolio5 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio5 });
            if (typeof f.portfolio6 != 'undefined' && f.portfolio6 != null && f.portfolio6.trim().length > 0 && f.portfolio6 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio6 });
            if (typeof f.portfolio7 != 'undefined' && f.portfolio7 != null && f.portfolio7.trim().length > 0 && f.portfolio7 != this.authService.currentUser.jobTitle)
              this.clientlist.push({ id: f.id, name: f.portfolio7 });
          });
        }
      }, error => { this.appComponent.failedHelper(error) });
  }

  radarChartData() {
    this.chartData = [];
    let questionStructure = Enumerable.from(JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).questionStructure)).select(s => s['columndata']);
    //#region qualitative start    
    this.moduleTree.forEach(f => {
      if (Object.keys(f).length == 1) {
        this.chartData.push({ short: '', full: f[Object.keys(f)[0]], xxx: 0, xxxtarget: 0, target: 0, total: 0, dimension: '', checked: false, showmore: false });
      }
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.columndata != null && typeof q.columndata != 'undefined') {
            if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]]) {
              if (typeof q.columndata[Object.keys(f)[1]] != 'undefined') {
                let short = '';
                if (Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).any()) {
                  short = Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).firstOrDefault().Abbreviation;
                }
                //if (!Enumerable.from(this.chartData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                //this.chartData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: q['response'] == "0" ? 0 : parseFloat(q['response']), xxxtarget: q['responsetarget'] == "0" ? 0 : parseFloat(q['responsetarget']), target: parseFloat(q['benchmarkValue']), total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false });
                if (!Enumerable.from(this.chartData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                  var response = 0, responsecount = 0, responsetarget = 0, responsetargetcount = 0, benchmark = 0, benchmarkcount = 0;
                  this.questionWithResponseList.forEach(s => {
                    if (s.columndata != null && typeof s.columndata != 'undefined') {
                      if (s.columndata['Sub-Dimension'] == q.columndata[Object.keys(f)[0]]) {
                        response += parseFloat(s.response);
                        responsecount++;
                        responsetarget += parseFloat(s.responsetarget);
                        responsetargetcount++;
                        benchmark += parseFloat(s.benchmarkValue);
                        benchmarkcount++;
                      }
                    }
                  });

                  let xxx = 0, xxxtarget = 0, target = 0;
                  try {
                    if (!isNaN(response) && !isNaN(responsecount))
                      xxx = response / responsecount;
                    else
                      xxx = 0;
                    if (!isNaN(responsetarget) && !isNaN(responsetargetcount))
                      xxxtarget = responsetarget / responsetargetcount;
                    else
                      xxxtarget = 0;
                    if (!isNaN(benchmark) && !isNaN(benchmarkcount))
                      target = benchmark / benchmarkcount;
                    else
                      target = 0;
                  }
                  catch {
                    xxx = 0;
                    xxxtarget = 0;
                    target = 0;
                  }
                  this.chartData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: xxx, xxxtarget: xxxtarget, target: target, total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false });
                }
              }
            }
          }
        });
      }
    });
    //#endregion

    this.chartData.forEach((data, index, datas) => {
      (data as any).index = index + 1;
    });
    this.chartData.forEach(f => {
      if (f.short != '') {
        try {
          if (!isNaN(f.xxx) && !isNaN(f.target))
            f.total = Math.round((f.xxx / f.target) * 100);
          else
            f.total = 0;
        }
        catch {
          f.total = 0;
        }
      }

      if (f.short == '') {
        let nextIndex = Enumerable.from(this.chartData).max(m => m['index']) + 1;

        if (Enumerable.from(this.chartData).where(x => x['index'] > f['index'] && x['short'] == '').any()) {
          nextIndex = Enumerable.from(this.chartData).where(x => x['index'] > f['index'] && x['short'] == '').firstOrDefault()['index'];
        }

        var dimensionAverage = Enumerable.from(this.chartData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxx']);
        var dimensionAverageTarget = Enumerable.from(this.chartData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxxtarget']);
        try {
          if (!isNaN(dimensionAverage) && !isNaN(dimensionAverageTarget)) {
            f.xxx = dimensionAverage;
            f.xxxtarget = dimensionAverageTarget;
            f.target = 5;
            f.total = Math.round((dimensionAverage / 5) * 100);
          }
          else {
            f.xxx = 0;
            f.xxxtarget = 0;
            f.target = 0;
            f.total = 0;
          }
        }
        catch {
          f.xxx = 0;
          f.xxxtarget = 0;
          f.target = 0;
          f.total = 0;
        }
      }

    });

    this.drawChart();

    this.save();
  }

  get compareCheck(): boolean {
    return (this.compare && typeof this.setClientComparisonIdList != 'undefined' && this.setClientComparisonIdList.length > 0) ? true : false;
  }

  drawChart() {
    let target = [];
    let total = [];
    let totaltarget = [];
    let labels = [];
    this.chart = null;

    if (this.checkedDimensions.length == 0) {
      Enumerable.from(this.chartData).where(x => x['short'] == '').select(s => s['target']).toArray().forEach(f => {
        target.push(f);
      });
      Enumerable.from(this.chartData).where(x => x['short'] == '').select(s => s['xxx']).toArray().forEach(f => {
        total.push(f);
      });
      Enumerable.from(this.chartData).where(x => x['short'] == '').select(s => s['xxxtarget']).toArray().forEach(f => {
        totaltarget.push(f);
      });
      Enumerable.from(this.chartData).where(x => x['short'] == '').select(s => s['full']).toArray().forEach(f => {
        let xxx = Enumerable.from(this.chartData).where(x => x['full'] == f).select(s => s['xxx']).firstOrDefault();
        let target = Enumerable.from(this.chartData).where(x => x['full'] == f).select(s => s['target']).firstOrDefault();
        let label = ((xxx / target) * 100).toFixed(0);

        if (parseInt(label) == 0)
          label = '<span style="color: #D04A02; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';
        else if (parseInt(label) >= 1 && parseInt(label) <= 20)
          label = '<span style="color: #FD6412; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';
        else if (parseInt(label) >= 21 && parseInt(label) <= 40)
          label = '<span style="color: #FEB791; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';
        else if (parseInt(label) >= 41 && parseInt(label) <= 60)
          label = '<span style="color: #FFDCA9; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';
        else if (parseInt(label) >= 61 && parseInt(label) <= 80)
          label = '<span style="color: #FFA929; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';
        else if (parseInt(label) >= 81)
          label = '<span style="color: #EB8C00; font-weight: bold;">' + f.trim() + ': ' + label + '%</span>';

        this.dimensionList[label] = Enumerable.from(this.chartData).where(x => x['full'] == f).select(s => s['dimension']).firstOrDefault();
        labels.push(label);
      });
    }
    else {
      this.checkedDimensions.forEach(d => {
        Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == d).select(s => s['target']).toArray().forEach(f => {
          target.push(parseFloat(f));
        });
        Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == d).select(s => s['xxx']).toArray().forEach(f => {
          total.push(parseFloat(f));
        });
        Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == d).select(s => s['xxxtarget']).toArray().forEach(f => {
          totaltarget.push(parseFloat(f));
        });
        Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == d).select(s => s['full']).toArray().forEach(f => {
          let xxx = Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['xxx']).firstOrDefault();
          let target = Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['target']).firstOrDefault();
          let label = ((xxx / target) * 100).toFixed(0);

          if (parseInt(label) == 0)
            label = '<span style="color: #D04A02; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';
          else if (parseInt(label) >= 1 && parseInt(label) <= 20)
            label = '<span style="color: #FD6412; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';
          else if (parseInt(label) >= 21 && parseInt(label) <= 40)
            label = '<span style="color: #FEB791; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';
          else if (parseInt(label) >= 41 && parseInt(label) <= 60)
            label = '<span style="color: #FFDCA9; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';
          else if (parseInt(label) >= 61 && parseInt(label) <= 80)
            label = '<span style="color: #FFA929; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';
          else if (parseInt(label) >= 81)
            label = '<span style="color: #EB8C00; font-weight: bold;">' + Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['short']).firstOrDefault().trim() + ': ' + label + '%</span>';

          this.dimensionList[label] = Enumerable.from(this.chartData).where(x => x['full'] == f && x['dimension'] == d).select(s => s['dimension']).firstOrDefault();
          labels.push(label);
        });
      });
    }

    let dimList = this.dimensionList;
    let cData = this.chartData;

    this.chartOptionsRadar = {
      chart: {
        polar: true
      },

      title: {
        text: 'Digital Opportunity Assessment - Results'
      },

      //subtitle: {
      //  text: '(percentages calculated against benchmark)'
      //},

      pane: {
        size: '98%'
      },

      xAxis: {
        categories: labels,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          style: {
            whiteSpace: 'nowrap',
            useHTML: true
          }
        }
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      },

      tooltip: {
        shared: true,
        formatter: function () {
          if (dimList[this.points[0].key] == null || dimList[this.points[0].key] == "") {
            let ttip = "";
            if (this.points[0] != null) {
              if (this.points[0].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[0].y.toFixed(1) + '</span><br>';
              if (this.points[0].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[0].y.toFixed(1) + '</span><br>';
              if (this.points[0].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[0].y.toFixed(1) + '</span><br>';
            }
            if (this.points[1] != null) {
              if (this.points[1].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[1].y.toFixed(1) + '</span><br>';
              if (this.points[1].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[1].y.toFixed(1) + '</span><br>';
              if (this.points[1].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[1].y.toFixed(1) + '</span><br>';
            }
            if (this.points[2] != null) {
              if (this.points[2].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[2].y.toFixed(1) + '</span><br>';
              if (this.points[2].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[2].y.toFixed(1) + '</span><br>';
              if (this.points[2].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[2].y.toFixed(1) + '</span><br>';
            }
            return ttip;
          }
          else {
            let xxx = Enumerable.from(cData).where(x => x['short'] == '' && x['full'] == dimList[this.points[0].key]).select(s => s['xxx']).firstOrDefault();
            let target = Enumerable.from(cData).where(x => x['short'] == '' && x['full'] == dimList[this.points[0].key]).select(s => s['target']).firstOrDefault();
            let label = ((xxx / target) * 100).toFixed(0);
            let ttip = '<span style="font-size: 16px; font-weight: bold;">' + dimList[this.points[0].key] + ' (' + label + '%)</span><br>';
            if (this.points[0] != null) {
              if (this.points[0].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[0].y.toFixed(1) + '</span><br>';
              if (this.points[0].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[0].y.toFixed(1) + '</span><br>';
              if (this.points[0].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[0].y.toFixed(1) + '</span><br>';
            }
            if (this.points[1] != null) {
              if (this.points[1].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[1].y.toFixed(1) + '</span><br>';
              if (this.points[1].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[1].y.toFixed(1) + '</span><br>';
              if (this.points[1].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[1].y.toFixed(1) + '</span><br>';
            }
            if (this.points[2] != null) {
              if (this.points[2].color == '#2D2D2D')
                ttip += '<span style="color: #2D2D2D; font-weight: bold;">Benchmark: ' + this.points[2].y.toFixed(1) + '</span><br>';
              if (this.points[2].color == '#D93954')
                ttip += '<span style="color: #D93954; font-weight: bold;">Target: ' + this.points[2].y.toFixed(1) + '</span><br>';
              if (this.points[2].color == '#FFB600')
                ttip += '<span style="color: #FFB600; font-weight: bold;">Current: ' + this.points[2].y.toFixed(1) + '</span><br>';
            }
            return ttip;
          }
        }
      },

      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
      },

      series: [{
        name: 'Benchmark',
        data: target,
        pointPlacement: 'on',
        type: 'area',
        color: '#2D2D2D'
      }, {
        name: 'Target',
        data: totaltarget,
        pointPlacement: 'on',
        type: 'area',
        color: '#D93954'
      }, {
        name: 'Current',
        data: total,
        pointPlacement: 'on',
        type: 'area',
        color: '#FFB600'
      }],

      responsive: {
        rules: [{
          condition: {
            minWidth: 1200
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            pane: {
              size: '97%'
            }
          }
        }]
      }
    };
  }

  exportdata() {
    if (this.questionWithResponseList != null) {

      //#region
      //EXCEL CREATION
      var title = "Digital Opportunity Assessment";
      var filename = "Digital Opportunity Assessment";

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Data');

      worksheet.getRow(2).getCell(2).value = { richText: [{ font: { name: 'Arial', family: 4, size: 16, underline: 'single', bold: true }, text: title }] };
      worksheet.getRow(2).getCell(5).value = { richText: [{ font: { name: 'Arial', family: 4, size: 16, bold: true }, text: 'XXX' }] };
      worksheet.getRow(2).getCell(5).alignment = { horizontal: 'center' };
      worksheet.getRow(3).getCell(3).value = { richText: [{ text: 'Assessment executed by:' }] };
      worksheet.getRow(3).getCell(3).alignment = { horizontal: 'right' };
      worksheet.getRow(3).getCell(4).value = { richText: [{ font: { bold: true }, text: 'Hauke Hansen' }] };
      worksheet.getRow(3).getCell(4).alignment = { horizontal: 'left' };
      worksheet.getRow(4).getCell(3).value = { richText: [{ text: 'Business responsible:' }] };
      worksheet.getRow(4).getCell(3).alignment = { horizontal: 'right' };
      worksheet.getRow(4).getCell(4).value = { richText: [{ font: { bold: true }, text: 'XXX' }] };
      worksheet.getRow(4).getCell(4).alignment = { horizontal: 'left' };
      worksheet.getRow(3).getCell(8).value = { richText: [{ text: 'Assessment executed at:' }] };
      worksheet.getRow(3).getCell(8).alignment = { horizontal: 'right' };
      worksheet.getRow(3).getCell(9).value = { richText: [{ font: { bold: true }, text: '19/5/20' }] };
      worksheet.getRow(3).getCell(9).alignment = { horizontal: 'left' };
      worksheet.getRow(4).getCell(8).value = { richText: [{ text: 'Results reported:' }] };
      worksheet.getRow(4).getCell(8).alignment = { horizontal: 'right' };
      worksheet.getRow(4).getCell(9).value = { richText: [{ font: { bold: true }, text: '' }] };
      worksheet.getRow(4).getCell(9).alignment = { horizontal: 'left' };

      worksheet.addRow([]);

      worksheet.getColumn(1).width = 3;
      worksheet.getColumn(2).width = 6;
      worksheet.getColumn(3).width = 30;
      worksheet.getColumn(4).width = 45;
      worksheet.getColumn(5).width = 10;
      worksheet.getColumn(6).width = 10;
      worksheet.getColumn(7).width = 10;
      worksheet.getColumn(8).width = 10;
      worksheet.getColumn(9).width = 50;
      worksheet.getColumn(10).width = 15;
      worksheet.getColumn(11).width = 15;

      //#region overall points start
      worksheet.addRow(['', '', '', 'Overall score', '40%']);
      worksheet.addRow(['', '', '', 'Assessment scores', '']);
      worksheet.addRow(['', '', '', '', '']);
      worksheet.addRow(['', '', '', '', '']);
      worksheet.addRow(['', '', '', '', '']);
      worksheet.addRow([]);
      //#endregion

      //#region qualitative start
      var headerqualitative = ["", "ID", "Criterion (full)", "Supporting criteria / best practices", "Value", "Target", "Weight", "Total", "Comments", "EBITDA impact potential (in M€)", "Impact rationale"];
      let headerRowqualitative = worksheet.addRow(headerqualitative);

      for (let i = 2; i <= headerqualitative.length; i++) {
        headerRowqualitative.getCell(i).font = { name: 'Calibri', family: 4, size: 10 };
        headerRowqualitative.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        headerRowqualitative.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        headerRowqualitative.getCell(i).font = { bold: true }
        headerRowqualitative.getCell(i).alignment = { wrapText: true }
      }
      //#endregion

      var row = 13;

      //#region qualitative start
      var i = 1, j = 1, dimension = 1, subdimension = 1;
      var _categoryTotal = 0
      var _categoryWeitageTotal = 0;
      var _categoryWeitageSum = 0;
      var _overallscore = 0;
      var _categoryscores = [];

      this.moduleTree.forEach(f => {
        let _categoryValue = Enumerable.from(this.questionWithResponseList).where(x => typeof x.columndata != 'undefined'
          && f[Object.keys(f)[0]] == x.columndata[Object.keys(f)[0]]
          && f[Object.keys(f)[1]] == x.columndata[Object.keys(f)[1]]).select(s => s.response).sum();

        let _categoryWeitage = Enumerable.from(this.questionWithResponseList).where(x => typeof x.columndata != 'undefined'
          && f[Object.keys(f)[0]] == x.columndata[Object.keys(f)[0]]
          && f[Object.keys(f)[1]] == x.columndata[Object.keys(f)[1]]).select(s => parseFloat(s.weightage)).sum();

        if (f[Object.keys(f)[1]] == null || typeof f[Object.keys(f)[1]] == 'undefined') {
          var category = ["", dimension, f[Object.keys(f)[0]], "", "", "", Math.round(_categoryWeitage * 100) / 100, Math.round((_categoryValue / _categoryWeitage) * 100) / 100, "", "", ""];
          let categoryRow = worksheet.addRow(category);

          for (let i = 2; i <= category.length; i++) {
            categoryRow.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' }, bgColor: { argb: 'F2F2F2' } }
            categoryRow.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
            categoryRow.getCell(i).font = { name: 'Calibri', family: 4, size: 10, bold: true };
          }

          _categoryTotal = Math.round((((_categoryValue / _categoryWeitage) / 5) * 100) * 100) / 100;
          _categoryWeitageTotal = _categoryWeitage;
          _categoryWeitageSum += _categoryWeitage;

          row++;
          dimension++
          subdimension = 1;
        }


        if (Object.keys(f).length > 1) {
          j = 1;
          this.questionWithResponseList.forEach(q => {

            if (q.columndata != null && typeof q.columndata != 'undefined' && q.questiontype != 'firstmodule') {

              if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]] && f[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]] && Object.keys(f).length > 1) {
                q.category = f[Object.keys(f)[0]];
                q.subcategory = f[Object.keys(f)[1]];
                var _id = (dimension - 1) + '.' + subdimension;
                var _criterion = /*q.columndata['DB ID']+' | '+*/q.columndata[Object.keys(f)[1]];
                var _question = '';
                var _value = '';
                var _target = '';
                var _weight = 0;
                var _total = (subdimension == 1) ? _categoryTotal + '%' : '';
                var _comments = '';

                if (q.questiontext && q.questiontext != null && typeof q.questiontext != 'undefined') { _question = q.questiontext; }
                if (q.response && q.response != null && typeof q.response != 'undefined') { _value = q.response; }
                if (q.benchmarkValue && q.benchmarkValue != null && typeof q.benchmarkValue != 'undefined') { _target = q.benchmarkValue; }
                if (q.weightage && q.weightage != null && typeof q.weightage != 'undefined') { _weight = q.weightage; }
                if (q.other && q.other != null && typeof q.other != 'undefined') { _comments = q.other; }

                //#region DESIGN
                var columnindex = 2;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _id }]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _criterion }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _question }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _value }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _target }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _weight.toString() }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _total.toString() }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                worksheet.getRow(row).getCell(columnindex).value = {
                  richText: [{ font: { name: 'Arial', family: 4, size: 10 }, text: _comments }
                  ]
                };

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

                columnindex++;
                worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
                worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                //#endregion

                if (subdimension == 1) {
                  _overallscore = (_overallscore + (_categoryWeitageTotal * _categoryTotal / 100));
                  _categoryscores.push(_categoryTotal);
                }
                j++;
                row++;
                subdimension++;
              }

            }
          });
          i++;
        }

      });

      worksheet.getRow(6).getCell(5).value = {
        richText: [
          { text: Math.round((_overallscore / _categoryWeitageSum * 100) * 100) / 100 + '%' }
        ]
      };

      var footerqualitative = ['', '', '', '', '', '', _categoryWeitageSum, '', '', '', ''];
      let footerRowqualitative = worksheet.addRow(footerqualitative);

      for (let i = 2; i <= footerqualitative.length; i++) {
        footerRowqualitative.getCell(i).font = { name: 'Calibri', family: 4, size: 10 };
        footerRowqualitative.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd8d8d8' }, bgColor: { argb: 'd8d8d8' } }
        footerRowqualitative.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        footerRowqualitative.getCell(i).font = { bold: true }
        footerRowqualitative.getCell(i).alignment = { wrapText: true }
      }

      var index = 0;
      for (let i = 0; i < _categoryscores.length; i++) {
        worksheet.getRow(7 + index).getCell(5 + (i % 3)).value = {
          richText: [
            { text: _categoryscores[i] + '%' }
          ]
        };
        if (i % 3 == 2)
          index++;
      }
      row++;
      //#endregion

      worksheet.addRow([]);
      worksheet.addRow([]);

      worksheet.addImage(workbook.addImage({ extension: 'png', base64: this.chart.toBase64Image() }), 'A' + (row + 2) + ':H' + (row + 22));

      let graphData = worksheet.addRow(['', '', '', '', '', '', '', '', 'Criterion(short)', 'Criterion(full)', 'Current', 'Target', 'Total']);

      for (let i = 9; i <= 14; i++) {
        graphData.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        graphData.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        graphData.getCell(i).font = { bold: true }
        graphData.getCell(i).alignment = { wrapText: true }
      }

      this.chartData.forEach(f => {
        worksheet.addRow(['', '', '', '', '', '', '', '', f['short'], f['full'], f['xxx'], f['target'], f['total']]);
        row++;
      });

      for (let i = 0; i <= (22 - this.chartData.length); i++) {
        worksheet.addRow([]);
        row++;
      }

      // #region improvement area start
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.getRow(row + 5).getCell(3).value = { richText: [{ font: { name: 'Arial', family: 4, size: 10, bold: true }, text: 'Core improvement areas' }] };
      row += 6;
      var improvementareadata = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).improvementAreaStructure);
      for (var i = 0; i < improvementareadata.length; i++) {
        var _title = ''
        var _desc = ''

        if (improvementareadata[i].title && improvementareadata[i].title != null && typeof improvementareadata[i].title != 'undefined') { _title = improvementareadata[i].title; }
        if (improvementareadata[i].description && improvementareadata[i].description != null && typeof improvementareadata[i].description != 'undefined') { _desc = improvementareadata[i].description; }

        var columnindex = 3;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd8d8d8' }, bgColor: { argb: 'd8d8d8' } }
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _title }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _desc }
          ]
        };
        row++;
      }
      // #endregion

      // #region observation start
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.getRow(row + 2).getCell(3).value = { richText: [{ font: { name: 'Arial', family: 4, size: 10, bold: true }, text: 'Observations' }] };
      row += 3;
      var observationadata = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).observationStructure);
      for (var i = 0; i < observationadata.length; i++) {
        var _title = ''
        var _desc = ''

        if (observationadata[i].title && observationadata[i].title != null && typeof observationadata[i].title != 'undefined') { _title = observationadata[i].title; }
        if (observationadata[i].description && observationadata[i].description != null && typeof observationadata[i].description != 'undefined') { _desc = observationadata[i].description; }

        var columnindex = 3;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'd8d8d8' }, bgColor: { argb: 'd8d8d8' } }
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _title }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _desc }
          ]
        };
        row++;
      }
      // #endregion

      //pl start
      worksheet.addRow([]);
      worksheet.addRow([]);
      var headerpl1 = ["", "", "", "", "", "", "Target", ""];
      let headerRowpl1 = worksheet.addRow(headerpl1);
      for (let i = 3; i <= headerpl1.length; i++) {
        headerRowpl1.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        headerRowpl1.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        headerRowpl1.getCell(i).font = { bold: true }
        headerRowpl1.getCell(i).alignment = { wrapText: true }
      }

      var headerpl2 = ["", "", "P&L", "", "2019", "", "2023", ""];
      let headerRowpl2 = worksheet.addRow(headerpl2);
      for (let i = 3; i <= headerpl2.length; i++) {
        headerRowpl2.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        headerRowpl2.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        headerRowpl2.getCell(i).font = { bold: true }
        headerRowpl2.getCell(i).alignment = { wrapText: true }
      }

      var headerpl3 = ["", "", "", "", "[M€]", "%", "[M€]", "%"];
      let headerRowpl3 = worksheet.addRow(headerpl3);
      for (let i = 3; i <= headerpl3.length; i++) {
        headerRowpl3.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        headerRowpl3.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        headerRowpl3.getCell(i).font = { bold: true }
        headerRowpl3.getCell(i).alignment = { wrapText: true }
      }

      row += 5;
      var pldata = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).plStructure);
      for (var i = 0; i < pldata.children.length; i++) {
        var _name = ''
        var _desc = ''
        var _val1 = ''
        var _val2 = ''
        var _val3 = ''
        var _val4 = ''

        if (pldata.children[i].name && pldata.children[i].name != null && typeof pldata.children[i].name != 'undefined') { _name = pldata.children[i].name; }
        if (pldata.children[i].description && pldata.children[i].description != null && typeof pldata.children[i].description != 'undefined') { _desc = pldata.children[i].description; }
        if (pldata.children[i].value1 && pldata.children[i].value1 != null && typeof pldata.children[i].value1 != 'undefined') { _val1 = pldata.children[i].value1; }
        if (pldata.children[i].value2 && pldata.children[i].value2 != null && typeof pldata.children[i].value2 != 'undefined') { _val2 = pldata.children[i].value2; }
        if (pldata.children[i].value3 && pldata.children[i].value3 != null && typeof pldata.children[i].value3 != 'undefined') { _val3 = pldata.children[i].value3; }
        if (pldata.children[i].value4 && pldata.children[i].value4 != null && typeof pldata.children[i].value4 != 'undefined') { _val4 = pldata.children[i].value4; }

        var columnindex = 3;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _name }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _desc }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _val1 }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _val2 }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _val3 }
          ]
        };

        columnindex++;
        worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            { text: _val4 }
          ]
        };

        row++;
      }
      //pl end

      worksheet.addRow([]);

      // quantitative start
      var headerquantitative = ["", "", "DIGITAL VISION & STRATEGY", "Where to go and how to get there", "", "Base", "Points", "Max"];
      let headerRowquantitative = worksheet.addRow(headerquantitative);

      for (let i = 2; i <= headerquantitative.length; i++) {
        //if (i == 2) {
        //  headerRowquantitative.getCell(i).merge(headerRowquantitative.getCell(i + 1));
        //  headerRowquantitative.getCell("").value = {
        //    richText: [
        //      { text: "DIGITAL VISION & STRATEGY" }
        //    ]
        //  };
        //}
        headerRowquantitative.getCell(i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' }, bgColor: { argb: 'FF0000FF' } }
        headerRowquantitative.getCell(i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        headerRowquantitative.getCell(i).font = { bold: true }
        headerRowquantitative.getCell(i).alignment = { wrapText: true }
      }

      row = row + 2;
      for (var i = 0; i < this.questionWithResponseList.length; i++) {
        if (this.questionWithResponseList[i].questiontype == 'firstmodule') {
          var _criterion = '';
          var _question = '';
          var _options = '';
          var _base = '';
          var _points = '';
          var _max = 0;

          if (this.questionWithResponseList[i].parentValue && this.questionWithResponseList[i].parentValue != null && typeof this.questionWithResponseList[i].parentValue != 'undefined') { _criterion = this.questionWithResponseList[i].parentValue; }
          if (this.questionWithResponseList[i].questiontext && this.questionWithResponseList[i].questiontext != null && typeof this.questionWithResponseList[i].questiontext != 'undefined') { _question = this.questionWithResponseList[i].questiontext; }
          if (this.questionWithResponseList[i].maturitylevel1Desc && this.questionWithResponseList[i].maturitylevel1Desc != null && typeof this.questionWithResponseList[i].maturitylevel1Desc != 'undefined') { _options = this.questionWithResponseList[i].maturitylevel1Desc.replace(",", "/"); }
          if (this.questionWithResponseList[i].response && this.questionWithResponseList[i].response != null && typeof this.questionWithResponseList[i].response != 'undefined') { _base = this.questionWithResponseList[i].maturitylevel1Desc.split(',')[this.questionWithResponseList[i].maturitylevel2Desc.split(',').indexOf(this.questionWithResponseList[i].response)]; }
          if (this.questionWithResponseList[i].response && this.questionWithResponseList[i].response != null && typeof this.questionWithResponseList[i].response != 'undefined') { _points = this.questionWithResponseList[i].response; }
          if (this.questionWithResponseList[i].response && this.questionWithResponseList[i].response != null && typeof this.questionWithResponseList[i].response != 'undefined') { _max = Math.max.apply(Math, this.questionWithResponseList[i].maturitylevel2Desc.split(',')); }

          var columnindex = 2;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _criterion }
            ]
          };

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _question }
            ]
          };

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _options }
            ]
          };

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _base + '' }
            ]
          };

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _points + '' }
            ]
          };

          columnindex++;
          worksheet.getRow(row).alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
          worksheet.getRow(row).getCell(columnindex).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [
              { text: _max + '' }
            ]
          };

          row++;
        }
      }
      // quantitative end

      worksheet.addRow([]);

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, new Date().getTime() + '_' + filename + EXCEL_EXTENSION);
      });

      //   end
      this.alertService.showStickyMessage('Success', `Report data downloaded successfully.`, MessageSeverity.success);
    }
    else {
      this.alertService.showStickyMessage('Load Error', `Unable to retrieve Questions from the server.`, MessageSeverity.error);
    }


  }

  save() {
    this.reportObj = new Report();
    this.reportObj.isget = false;
    this.reportObj.userid = this.accountService.currentUser.id;
    this.reportObj.chartdata = JSON.stringify(this.chartData);
    this.reportObj.createdby = this.accountService.currentUser.id;
    this.reportObj.isdeleted = false;

    this.accountService.Set('report', this.reportObj).subscribe(() => { }, error => { this.appComponent.failedHelper(error) });
  }

  getdata() {
    this.reportObj = new Report();
    this.reportObj.isget = true;
    this.reportObj.userid = this.accountService.currentUser.id;

    this.comparewith = this.setClientComparisonIdList;

    this.reportObj.chartdata = JSON.stringify(this.comparewith);

    this.accountService.Set('report', this.reportObj).subscribe(results => {
      if (results) {
        Object.assign(this.compareData, results);

        this.compareData.forEach(f => {
          var data = JSON.parse(f.chartdata);
          this.chartData.forEach(c => {
            c[f.userid] = 0;


            data.forEach(d => {
              if (c['short'] == d['Short'] && c['full'] == d['Full']) {

                c[f.userid] = d['Xxx'];
              }
            });

          });
        });

        //this.drawBarChart();
        this.updateChart();
        //this.bubbleChartData();
      }
    }, error => { this.appComponent.failedHelper(error) });
  }

  updateChart() {

    setTimeout(() => {

      this.chartOptionsPortfolio.series = [];

      //#region Current
      let current = { name: this.accountService.currentUser.jobTitle, data: [] };
      Enumerable.from(this.chartData).where(x => x['short'] == '').forEach(f => {
        let drillName = 'current' + f['index'];
        let obj = { name: f['full'], y: f['total'], drilldown: drillName };
        current.data.push(obj);

        let nextIndex = 0;
        if (Enumerable.from(this.chartData).where(x => x['short'] == '' && x['index'] > f['index']).any()) {
          nextIndex = Enumerable.from(this.chartData).where(x => x['short'] == '' && x['index'] > f['index']).firstOrDefault()['index'];
        }
        else {
          nextIndex = Enumerable.from(this.chartData).max(m => m['index']);
        }

        let subdimensions = Enumerable.from(this.chartData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).toArray();
        let drillobj = { id: drillName, name: 'Current', data: [] };

        subdimensions.forEach(s => {
          let sObj = [s['short'], s['total']];
          drillobj.data.push(sObj);
        });

        this.chartOptionsPortfolio.drilldown.series.push(drillobj);

      });
      this.chartOptionsPortfolio.series.push(current);
      //#endregion

      //#region Portfolio
      this.comparewith.forEach(f => {
        let itemSet = { name: f, data: [] };
        Enumerable.from(this.chartData).where(x => x['short'] == '').forEach(d => {
          let drillName = f + d['index'];
          let obj = { name: d['full'], y: d[f], drilldown: drillName };
          itemSet.data.push(obj);

          let nextIndex = 0;
          if (Enumerable.from(this.chartData).where(x => x['short'] == '' && x['index'] > d['index']).any()) {
            nextIndex = Enumerable.from(this.chartData).where(x => x['short'] == '' && x['index'] > d['index']).firstOrDefault()['index'];
          }
          else {
            nextIndex = Enumerable.from(this.chartData).max(m => m['index']);
          }

          let subdimensions = Enumerable.from(this.chartData).where(x => x['index'] > d['index'] && x['index'] < nextIndex).toArray();
          let drillobj = { id: drillName, name: f, data: [] };

          subdimensions.forEach(s => {
            let sObj = [s['short'], s[f]];
            drillobj.data.push(sObj);
          });

          this.chartOptionsPortfolio.drilldown.series.push(drillobj);
        })
        this.chartOptionsPortfolio.series.push(itemSet);
      });
      //#endregion

      this.updateFlag = true;
    }, 1000);

    this.viewChart = true;
  }

  checkdimension(selectedIndex: number) {
    var item = this.chartData[selectedIndex];

    Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == item["full"]).toArray().forEach(f => { f['checked'] = item["checked"]; });

    if (item["checked"] == true) {
      this.checkedDimensions.push(item["full"]);
    }
    else {
      this.checkedDimensions.splice($.inArray(item["full"], this.checkedDimensions), 1);
      Enumerable.from(this.chartData).where(x => x['short'] == '' && x['full'] == item["full"]).toArray().forEach(f => { f['showmore'] = false; });
      Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == item["full"]).toArray().forEach(f => { f['showmore'] = false; });
    }
    //this.chart.destroy();
    this.drawChart();
  }

  showSubDimensions(selectedIndex: number) {
    var item = this.chartData[selectedIndex];

    if (item["showmore"] == true) {
      item["showmore"] = false;
      $("#showmore" + selectedIndex).html("<span class='appkiticon icon-down-chevron-fill'></span>");
    }
    else {
      item["showmore"] = true;
      $("#showmore" + selectedIndex).html("<span class='appkiticon icon-up-chevron-fill'></span>");
    }

    Enumerable.from(this.chartData).where(x => x['short'] != '' && x['dimension'] == item["full"]).toArray().forEach(f => { f['showmore'] = item["showmore"]; });
  }

  submit() {
    this.alertService.showDialog('Are you sure you want to submit the assessment?', DialogType.confirm, () => {
      this.currentAssessment.createdby = this.accountService.currentUser.id;
      this.currentAssessment.isdeleted = false;
      this.currentAssessment.isget = false;
      this.currentAssessment.isSubmitted = true;

      this.accountService.Set("assessment", this.currentAssessment).subscribe(
        () => {
          localStorage.removeItem("currentTemplateDetails");
          localStorage.removeItem('currentUserDetails');
          this.router.navigate(['home']);
        },
        error => { this.appComponent.failedHelper(error); });
    });
  }

  setClientComparisonId(event: any, id: any) {
    this.viewChart = null;
    if (event.target.checked) {
      this.setClientComparisonIdList.push(id);
      this.toogleCompareData = false;
    } else {
      this.setClientComparisonIdList = this.setClientComparisonIdList.filter(m => m != id);
      if (this.setClientComparisonIdList.length === 0) {
        this.toogleCompareData = true;
      }
    }

  }

  clearClientComparisonId(event: any) {
    if (event.target.checked == false) {
      this.viewChart = false;
      this.setClientComparisonIdList = [];
      this.toogleCompareData = true;
    }
  }

  changenView(args) {
    if (args == false)
      this.router.navigate(['reportqualitativecontent']);
  }
}
