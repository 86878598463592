import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { Router } from '@angular/router';
import { Assessment } from '../../models/assessment.model';
import { AccountService } from '../../services/account.service';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppComponent } from '../app.component';
import { Utilities } from '../../services/utilities';

@Component({
  selector: 'privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  animations: [fadeInOut]
})
export class PrivacyComponent {

  public assessmentObj: Assessment = new Assessment();

  constructor(private router: Router, private accountService: AccountService, private alertService: AlertService, private appComponent: AppComponent) {
    if (!this.appComponent.isAdmin) {
      this.loadCurrentUserData();
    }
  }

  private loadCurrentUserData() {
    if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined') {
      this.assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
    }
    else {
      this.router.navigate(['home']);
    }
  }

  save() {
    if (this.assessmentObj.isAcceptedPrivacy != null && typeof (this.assessmentObj.isAcceptedPrivacy) != 'undefined') {
      var assessmentObj = new Assessment();
      assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment
      assessmentObj.isget = false;
      assessmentObj.userId = this.accountService.currentUser.id;
      assessmentObj.createdby = this.accountService.currentUser.id;
      assessmentObj.isAcceptedPrivacy = this.assessmentObj.isAcceptedPrivacy;
      this.accountService.Set("assessment", this.assessmentObj).subscribe(
        results => {
          localStorage.setItem("currentUserDetails", JSON.stringify(results));

          if (this.assessmentObj.isAcceptedPrivacy == false) {
            this.router.navigate(['home']);
          }
          else {
            this.router.navigate(['questionnaires']);
          }
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }
}
