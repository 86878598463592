import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppComponent } from '../app.component';
import { AssessmentQuestions, Assessment } from '../../models/assessment.model';
import { Template } from '../../models/template.model';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DigitalLevers } from '../../models/digitallevers.model';
import { max, result } from 'lodash';
import { DimensionsHelper } from '@swimlane/ngx-datatable';

@Component({
  selector: 'reportdigitizationleverscontent-management',
  templateUrl: './reportdigitizationleverscontent.component.html',
  styleUrls: ['./reportdigitizationleverscontent.component.scss'],
  animations: [fadeInOut]
})

export class ReportdigitizationleverscontentComponent implements OnInit {
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp = {};
  questionWithResponseList: any[] = [];
  currentAssessment: Assessment = new Assessment();
  obj: AssessmentQuestions = new AssessmentQuestions();
  generateReportFlag: number = 0;
  responseData: any = [];
  overallScore: number = 0;
  isQuantitative: boolean;
  templInput: any = [];
  rptData: any;
  digitalleversobj: DigitalLevers = new DigitalLevers();
  public digitalleversalldata: DigitalLevers[] = [];
  highVal: number = 0;
  midVal: number = 0;
  lowVal: number = 0;
  @ViewChild('detailsModal', { static: false })
  detailsModal: ModalDirective;
  modalData: any = [];

  constructor(private alertService: AlertService, public appComponent: AppComponent, private accountService: AccountService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.loadAssessment();
    this.loadResponses();
    this.loadDigitalLevers();
  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.children.length > 0) {
        obj.children.forEach(c => {
          this.temp = {};
          this.temp[obj.prop] = obj.name;
          this.temp[c.prop] = c.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(c);
        });
      }
    }
  }

  loadAssessment() {
    if (localStorage.getItem('currentTemplateDetails') != null && typeof localStorage.getItem('currentTemplateDetails') != 'undefined') {
      this.currentAssessment = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
      this.questionWithResponseList = JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses);
      this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).treeStructure);
      this.isQuantitative = (JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).isQuantitative;

      this.moduleTreeCache.forEach(f => {
        if (f.ischecked) {
          this.temp = {};
          this.temp[f.prop] = f.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(f);
        }
      });

      this.generateReportFlag = 0
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.response == 0 && (q.other == null || typeof q.other == 'undefined' || q.other == '')) {
            this.generateReportFlag++;
          }
        });
      }
    }
  }

  loadResponses() {
    this.responseData = [];
    let questionStructure = Enumerable.from(JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).questionStructure)).select(s => s['columndata']);


    this.moduleTree.forEach(f => {
      if (Object.keys(f).length == 1) {
        let desc = Enumerable.from(this.moduleTreeCache).where(x => x.name == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();
        this.responseData.push({ short: '', full: f[Object.keys(f)[0]], xxx: 0, xxxtarget: 0, target: 0, total: 0, dimension: '', checked: false, showmore: false, description: desc });
      }
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.columndata != null && typeof q.columndata != 'undefined') {
            if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]]) {
              if (typeof q.columndata[Object.keys(f)[1]] != 'undefined') {
                let short = '';
                if (Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).any()) {
                  short = Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).firstOrDefault().Abbreviation;
                }
                if (!Enumerable.from(this.responseData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                  var response = 0, responsecount = 0, responsetarget = 0, responsetargetcount = 0, benchmark = 0, benchmarkcount = 0;
                  this.questionWithResponseList.forEach(s => {
                    if (s.columndata != null && typeof s.columndata != 'undefined') {
                      if (s.columndata['Sub-Dimension'] == q.columndata[Object.keys(f)[0]]) {
                        response += parseFloat(s.response);
                        responsecount++;
                        responsetarget += parseFloat(s.responsetarget);
                        responsetargetcount++;
                        benchmark += parseFloat(s.benchmarkValue);
                        benchmarkcount++;
                      }
                    }
                  });
                  let subdim = Enumerable.from(this.moduleTreeCache).where(x => x.name == q.columndata['Dimension']).select(y => y["children"]).firstOrDefault();
                  let desc = Enumerable.from(subdim).where(x => x['name'] == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();

                  let xxx = 0, xxxtarget = 0, target = 0;
                  try {
                    if (!isNaN(response) && !isNaN(responsecount))
                      xxx = response / responsecount;
                    else
                      xxx = 0;
                    if (!isNaN(responsetarget) && !isNaN(responsetargetcount))
                      xxxtarget = responsetarget / responsetargetcount;
                    else
                      xxxtarget = 0;
                    if (!isNaN(benchmark) && !isNaN(benchmarkcount))
                      target = benchmark / benchmarkcount;
                    else
                      target = 0;
                  }
                  catch {
                    xxx = 0;
                    xxxtarget = 0;
                    target = 0;
                  }

                  this.responseData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: xxx, xxxtarget: xxxtarget, target: target, total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false, description: desc });
                }
              }
            }
          }
        });
      }
    });

    this.responseData.forEach((data, index, datas) => {
      (data as any).index = index + 1;
    });

    let dimcount = 0, dimscore = 0;

    this.responseData.forEach(f => {
      if (f.short != '') {
        try {
          if (!isNaN(f.xxx) && !isNaN(f.target))
            f.total = Math.round((f.xxx / f.target) * 100);
          else
            f.total = 0;
        }
        catch {
          f.total = 0;
        }
      }
      else {
        let nextIndex = Enumerable.from(this.responseData).max(m => m['index']) + 1;

        if (Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').any()) {
          nextIndex = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').firstOrDefault()['index'];
        }

        var dimensionAverage = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxx']);
        var dimensionAverageTarget = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxxtarget']);

        try {
          if (!isNaN(dimensionAverage) && !isNaN(dimensionAverageTarget)) {
            f.xxx = dimensionAverage;
            f.xxxtarget = dimensionAverageTarget;
            f.target = 5;
            f.total = Math.round((dimensionAverage / 5) * 100);
          }
          else {
            f.xxx = 0;
            f.xxxtarget = 0;
            f.target = 0;
            f.total = 0;
          }
        }
        catch {
          f.xxx = 0;
          f.xxxtarget = 0;
          f.target = 0;
          f.total = 0;
        }

        dimscore += f.total;
        dimcount++;
      }
    });

    this.overallScore += Math.round(dimscore / dimcount);
  }

  loadDigitalLevers() {
    this.digitalleversobj.isget = true;
    this.accountService.Set('loginhandler/getdigitallever', this.digitalleversobj).subscribe(
      results => {
        if (results) {
          Object.assign(this.digitalleversalldata, results);
          this.loadReportData();
        }
      },
      error => {
        this.appComponent.failedHelper(error);
      });
  }

  loadReportData() {

    let templpl: any;

    if (this.currentAssessment.plStructure != null) {
      templpl = JSON.parse(this.currentAssessment.plStructure);
    }
    else if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['plStructure']) {

      templpl = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['plStructure']);
    }
    if (templpl != null && typeof templpl != 'undefined') {
      templpl.children01.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children02.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children03.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children04.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children05.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children06.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children07.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children08.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children09.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children10.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
    }

    let templassumption: any;
    if (this.currentAssessment.assumptionStructure != null) {
      templassumption = JSON.parse(this.currentAssessment.assumptionStructure);
      templassumption.forEach(f => {
        this.templInput.push({ name: f.kpi, value: f.value, format: 'Currency(M€)' });
      });
    }
    else if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['assumptionStructure']) {
      templassumption = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['assumptionStructure']);
      templassumption.forEach(f => {
        this.templInput.push({ name: f.kpi, value: f.value, format: 'Currency(M€)' });
      });
    }
    if (this.digitalleversalldata) {
      this.digitalleversalldata.forEach(f => {
        this.templInput.push({ name: f.lever + " | Minimum case", value: f.minimumcase, format: 'Percentage', });
        this.templInput.push({ name: f.lever + " | Realistic case", value: f.realisticcase, format: 'Percentage' });
        this.templInput.push({ name: f.lever + " | Best case", value: f.bestcase, format: 'Percentage' });
      });
    }

    //generate report data
    this.rptData = [];
    if (this.responseData) {
      Enumerable.from(this.responseData).where(x => x['short'] == '').forEach(f => {
        let obj = [];
        Enumerable.from(this.responseData).where(x => x['short'] != '' && x['dimension'] == f['full']).forEach(g => {
          if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['opportunityStructure']) {
            let templop = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['opportunityStructure']);
            Enumerable.from(templop).where(x => x['subdimension'] == g['full']).forEach(h => {
              let equation = h['opportunityformula'];

              if (equation) {
                let minimumcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Minimum case");
                let realisticcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Realistic case");
                let bestcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Best case");

                minimumcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      minimumcase = minimumcase.replace(t, v.toString());
                    }
                  }
                });

                realisticcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      realisticcase = realisticcase.replace(t, v.toString());
                    }
                  }
                });

                bestcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      bestcase = bestcase.replace(t, v.toString());
                    }
                  }
                });

                let mval = 0, rval = 0, bval = 0;
                try {
                  mval = Math.round(eval(minimumcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  mval = 0;
                }
                try {
                  rval = Math.round(eval(realisticcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  rval = 0;
                }
                try {
                  bval = Math.round(eval(bestcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  bval = 0;
                }
                if (rval > this.highVal)
                  this.highVal = rval;
                obj.push({ dimension: f['full'], subdimension: g['full'], businessunit: h['businessunit'], lever: h['lever'], formula: equation + '*(1-Maturity)', equation: realisticcase + '*(1-' + f['total'] + '%)', minimumcasevalue: mval, realisticcasevalue: rval, bestcasevalue: bval, advantages: h['advantages'] || 'NA', challenges: h['challenges'] || 'NA', domain: h['domain'] || 'NA', subdomain: h['subdomain'] || 'NA', keytechnologies: h['keytechnologies'] || 'NA' });
              } else {
                obj.push({ dimension: f['full'], subdimension: g['full'], businessunit: h['businessunit'] || 'NA', lever: h['lever'], formula: 'NA', equation: 'NA', minimumcasevalue: 'NA', realisticcasevalue: 'NA', bestcasevalue: 'NA', advantages: h['advantages'] || 'NA', challenges: h['challenges'] || 'NA', domain: h['domain'] || 'NA', subdomain: h['subdomain'] || 'NA', keytechnologies: h['keytechnologies'] || 'NA' });
              }
            })
          }
        });

        this.rptData.push({ dimension: f['full'], digitallever: Enumerable.from(obj).orderByDescending(x => x['realisticcasevalue']).toArray() })
      });

      this.midVal = Math.round(this.highVal / 3 * 2);
      this.lowVal = Math.round(this.highVal / 3);
    }
  }

  showDetailsModal(dimension: any, lever: any) {
    this.modalData = [];
    let description = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.description).firstOrDefault();
    let paybackperiodonetime = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.paybackperiodonetime).firstOrDefault();
    let paybackperiodtotal = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.paybackperiodtotal).firstOrDefault();
    let strategicrelevance = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.strategicrelevance).firstOrDefault();
    let implementationcomplexity = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.implementationcomplexity).firstOrDefault();
    let implementationtime = Enumerable.from(this.digitalleversalldata).where(x => x.lever == lever.lever).select(y => y.implementationtime).firstOrDefault();
    this.modalData.push({ category: dimension.dimension, subcategory: lever.subdimension, lever: lever.lever, description: description, minimumcasevalue: lever.minimumcasevalue, realisticcasevalue: lever.realisticcasevalue, bestcasevalue: lever.bestcasevalue, formula: lever.formula, implementationcostonetime: Math.round(lever.realisticcasevalue * paybackperiodonetime * 10) / 10, implementationcosttotal: Math.round(lever.realisticcasevalue * paybackperiodtotal * 10) / 10, strategicrelevance: strategicrelevance, implementationcomplexity: implementationcomplexity, implementationtime: implementationtime });
    this.detailsModal.show();
  }
}
