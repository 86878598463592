export class IdentityClaimUser {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(code?: string, tokenName?: string, tokenType?: string, email?: string, name?: string, given_name?: string, family_name?: string, organization_id_list?: string
    , product_list?: string, userid?: string, access_token?: string, apikey?: string, appid?: string, apiurl?: string, iamauthapihost?:string) {
    this.code = code;
    this.tokenName = tokenName;
    this.email = email;
    this.name = name;
    this.given_name = given_name;
    this.family_name = family_name;
    this.organization_id_list = organization_id_list;
    this.product_list = product_list;
    this.userid = userid;
    this.access_token = access_token;

    this.apikey = apikey;
    this.appid = appid;
    this.apiurl = apiurl;
    this.iamauthapihost = iamauthapihost;
  }

  public code: string;
  public tokenName: string;
  public email: string;
  public name: string;
  public given_name: string;
  public family_name: string;
  public organization_id_list: string;
  public product_list: string;
  public userid: string;
  public access_token: string;
  public apikey: string;
  public appid: string;
  public apiurl: string;
  public iamauthapihost: string;
  
}
