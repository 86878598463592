import { TemplateRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { Assessment, AssessmentModules, AssessmentQuestions, AssessmentResponseOptions } from '../../models/assessment.model';
import * as $ from 'jquery'
import { Template } from '../../models/template.model';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { result } from 'lodash';

@Component({
  selector: 'questionnaires-management',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss'],
  animations: [fadeInOut]
})

export class QuestionnairesComponent {
  public checkedModules: AssessmentModules[] = []
  assessment: Assessment = new Assessment();
  assessmentResponse: AssessmentQuestions = new AssessmentQuestions();
  questionWithResponseList: AssessmentQuestions[] = [];
  questionWithResponseListCache: AssessmentQuestions[] = [];
  module = "questionnaire";
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp: any[] = [];
  itemIndex: number = 1;
  currentModule: any[] = [];
  moduleCount: number = 0;
  moduleindex: number = 0;
  submoduleindex: number = 1;
  obj: AssessmentQuestions = new AssessmentQuestions();
  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, public appComponent: AppComponent, private router: Router) {
    $(document).ready(function () {
      $("#customComboBox").click(function () {
        $("#customComboBox li").slideToggle("fast");
        if ($("#customComboBox").hasClass("open"))
          $("#customComboBox").removeClass("open");
        else
          $("#customComboBox").addClass("open");
      });
      // view more
      $(".viewMore").show();
      $(".viewLess, .viewMoreSec").hide();
      $(".viewMore").click(function () {
        $(".viewLess, .viewMoreSec").show("fast");
        $(".viewMore").hide("fast");
      });
      $(".viewLess").click(function () {
        $(".viewLess, .viewMoreSec").hide("fast");
        $(".viewMore").show("fast");
      });

      // nav panel control
      $(".shrink-nav").hide("fast");
      $(".expand-nav").click(function () {
        $(".left-panel").addClass("left-panel-extended");
        $(".right-panel").addClass("right-panel-shrink");
        $(".expand-nav").hide("fast");
        $(".shrink-nav").show("fast");
      });
      $(".shrink-nav").click(function () {
        $(".expand-all").show();
        $(".collapse-all").hide();
        $('.a-accordion-content.inner-content').each(function () {
          var id = $(this).attr('id');
          if ($('#' + id).is(':visible')) {
            $('#' + id.replace("content", "title")).click();
          }
        });
        if ($('#main-content-1').is(':visible')) {
          $('#main-title-1').click();
        }
        if ($('#main-content-2').is(':visible')) {
          $('#main-title-2').click();
        }
        $(".left-panel").removeClass("left-panel-extended");
        $(".right-panel").removeClass("right-panel-shrink");
        $(".shrink-nav").hide("fast");
        $(".expand-nav").show("fast");
      });
      $(".a-accordion-title.inner-title").click();

      // expand
      $(".expand-all").show();
      $(".collapse-all").hide();
      $(".expand-all").click(function () {
        $(".expand-all").hide();
        $(".collapse-all").show();
        $('.a-accordion-content.inner-content').each(function () {
          var id = $(this).attr('id');
          if (!$('#' + id).is(':visible')) {
            $('#' + id.replace("content", "title")).click();
          }
        });
        if (!$('#main-content-1').is(':visible')) {
          $('#main-title-1').click();
        }
        if (!$('#main-content-2').is(':visible')) {
          $('#main-title-2').click();
        }
      });
      $(".collapse-all").click(function () {
        $(".expand-all").show();
        $(".collapse-all").hide();
        if ($('#main-content-1').is(':visible')) {
          $('#main-title-1').click();
        }
        if ($('#main-content-2').is(':visible')) {
          $('#main-title-2').click();
        }
        $('.a-accordion-content.inner-content').each(function () {
          var id = $(this).attr('id');
          if ($('#' + id).is(':visible')) {
            $('#' + id.replace("content", "title")).click();
          }
        });
      });

      $('#main-title-1, #main-title-2').click(function () {
        if (!$(".left-panel").hasClass("left-panel-extended")) {
          $(".left-panel").addClass("left-panel-extended");
          $(".right-panel").addClass("right-panel-shrink");
          $(".expand-nav").hide("fast");
          $(".shrink-nav").show("fast");
        }
      });

      // progress overview
      $(".main-panel").show();
      $(".module-panel").hide();
      $("#btnProgressOverview").click(function () {
        $(".main-panel").hide("fast");
        $(".module-panel").show("fast");
      });
      $("#btnProgressOverviewBack").click(function () {
        $(".main-panel").show("fast");
        $(".module-panel").hide("fast");
      });
    });
  }

  ngOnInit() {
    this.assessment = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
    if (!this.assessment.isSubmitted) {
      this.loadData();
      this.filterQuestion(0, 'next');
    }
    else {
      this.router.navigate(['reportqualitativecontent']);
    }
  }

  filterQuestion(index: number, step: string) {
    if (this.temp.length != 0) {
      $(".main-panel").show("fast");
      $(".module-panel").hide("fast");
      this.itemIndex = index;
      if (!Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex && x['level'] > 0).any()) {
        if (step == 'next') {
          this.itemIndex++;
          this.filterQuestion(this.itemIndex, step);
        }
        else {
          this.itemIndex--;
          this.filterQuestion(this.itemIndex, step);
        }
        return;
      }

      this.currentModule = [];
      this.currentModule.push({
        index: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().index,
        sectionIndex: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().indexno,
        sectionName: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().name,
        sectionComplete: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().complete,
        sectionTotal: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().total,
        subsectionIndex: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().indexno,
        subsectionName: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().name,
        subsectionComplete: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().complete,
        subsectionTotal: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().total,
        progress: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().complete / Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().total * 100,
        subsections: Enumerable.from(this.temp).where(x => x['dimension'] == Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().name).toArray()
      });

      this.questionWithResponseList = [];
      this.questionWithResponseList = Enumerable.from(this.questionWithResponseListCache).where(x => typeof x.columndata != 'undefined' && x.columndata['Dimension'] == Enumerable.from(this.currentModule).select(s => s['sectionName']).firstOrDefault() && x.columndata['Sub-Dimension'] == Enumerable.from(this.currentModule).select(s => s['subsectionName']).firstOrDefault()).toArray();
      $(".right-panel").animate({ scrollTop: 0 }, "slow");
    }
  }

  loadData() {
    this.questionWithResponseListCache = [];

    if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined' &&
      JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses) != null &&
      typeof JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses) != 'undefined' &&
      JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses).length != 0
    ) {
      this.questionWithResponseListCache = JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses);
    }
    else {
      let objQuestions = JSON.parse((JSON.parse(localStorage.getItem("currentTemplateDetails")) as Template).questionStructure);
      if (objQuestions) {
        objQuestions.forEach(f => {
          if (f.isChecked) {
            let objQuestion = new AssessmentQuestions();
            objQuestion.customdbid = f.customdbid;
            objQuestion.questiontext = f.questiontext;
            objQuestion.questionCategory = f.questionCategory;
            objQuestion.weightage = f.weightage;
            objQuestion.benchmarkValue = f.benchmarkValue;
            objQuestion.maturitylevel1Desc = f.maturitylevel1;
            objQuestion.maturitylevel2Desc = f.maturitylevel2;
            objQuestion.maturitylevel3Desc = f.maturitylevel3;
            objQuestion.maturitylevel4Desc = f.maturitylevel4;
            objQuestion.maturitylevel5Desc = f.maturitylevel5;
            objQuestion.columndata = f.columndata;
            objQuestion.response = '0';
            objQuestion.responsetarget = '0';
            objQuestion.responseOptions = [];
            if (f.questionCategory == 'MC' || f.questionCategory == 'MS') {
              if (f.maturitylevel1 != null && typeof f.maturitylevel1 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 1;
                if (f.maturitylevel1.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel1;
                  objOption.responseValue = 1;
                }
                else {
                  try {
                    var opt = f.maturitylevel1.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel1;
                    objOption.responseValue = 1;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel2 != null && typeof f.maturitylevel2 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 2;
                if (f.maturitylevel2.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel2;
                  objOption.responseValue = 2;
                }
                else {
                  try {
                    var opt = f.maturitylevel2.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel2;
                    objOption.responseValue = 2;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel3 != null && typeof f.maturitylevel3 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 3;
                if (f.maturitylevel3.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel3;
                  objOption.responseValue = 3;
                }
                else {
                  try {
                    var opt = f.maturitylevel3.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel3;
                    objOption.responseValue = 3;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel4 != null && typeof f.maturitylevel4 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 4;
                if (f.maturitylevel4.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel4;
                  objOption.responseValue = 4;
                }
                else {
                  try {
                    var opt = f.maturitylevel4.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel4;
                    objOption.responseValue = 4;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel5 != null && typeof f.maturitylevel5 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 5;
                if (f.maturitylevel5.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel5;
                  objOption.responseValue = 5;
                }
                else {
                  try {
                    var opt = f.maturitylevel5.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel5;
                    objOption.responseValue = 5;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
            }
            this.questionWithResponseListCache.push(objQuestion);
          }
        });
      }
      //let objQuestionsOpportunity = JSON.parse((JSON.parse(localStorage.getItem("currentTemplateDetails")) as Template).opportunityStructure);

      //if (objQuestionsOpportunity != null) {
      //  objQuestionsOpportunity.forEach(f => {

      //    let objQuestion = new AssessmentQuestions();
      //    objQuestion.questiontext = f.description;
      //    objQuestion.maturitylevel1Desc = f.base;
      //    objQuestion.maturitylevel2Desc = f.points;

      //    this.questionWithResponseListCache.push(objQuestion);

      //  });
      //}
    }
    if (this.questionWithResponseListCache.length != 0)
      this.quicklinkGenerator(0);
  }

  quicklinkGenerator(index: number) {
    this.moduleindex = 0;
    this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).treeStructure);

    this.temp = [];
    this.moduleTreeCache.forEach(f => {
      if (f.ischecked) {
        this.nestedToflatJSON(f);
        this.moduleCount += this.submoduleindex;
      }
    });

    let obj = this.temp;
    this.temp.forEach((item, index, obj) => {
      (item as any).index = index + 1;
    });
    this.calculateProgress();
  }

  calculateProgress() {
    if (this.currentModule[0]) {
      this.currentModule = [];
      this.currentModule.push({
        sectionIndex: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().indexno,
        sectionName: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().name,
        sectionComplete: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().complete,
        sectionTotal: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().total,
        subsectionIndex: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().indexno,
        subsectionName: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().name,
        subsectionComplete: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().complete,
        subsectionTotal: Enumerable.from(this.temp).where(x => x['index'] == this.itemIndex).firstOrDefault().total,
        progress: Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().complete / Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().total * 100,
        subsections: Enumerable.from(this.temp).where(x => x['dimension'] == Enumerable.from(this.temp).where(x => x['index'] < this.itemIndex && x.level == 0).lastOrDefault().name).toArray()
      });
    }
  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.level < 2) {
        if (obj.level == 0) {
          let total = Enumerable.from(this.questionWithResponseListCache).where(x => typeof x.columndata != 'undefined' && x.columndata[obj.prop] == obj.name).count();
          let complete = Enumerable.from(this.questionWithResponseListCache).where(x => typeof x.columndata != 'undefined' && x.columndata[obj.prop] == obj.name && (x.response != '0' || x.responsetarget != '0' || Enumerable.from(x.responseOptions).where(x => x.isChecked == true).count() != 0)).count();
          this.moduleindex++;
          this.temp.push({ name: obj.name, prop: obj.prop, level: obj.level, complete: complete, total: total, indexno: this.moduleindex + '.', dimension: '' });
          this.submoduleindex = 1;
        }
        else {
          let objQuestions = JSON.parse((JSON.parse(localStorage.getItem("currentTemplateDetails")) as Template).questionStructure);
          let cdata = Enumerable.from(objQuestions).select(x => x['columndata']).toArray();
          let sectionName = Enumerable.from(cdata).where(x => x['Sub-Dimension'] == obj.name && x['Question'] == obj.children[0].name).select(x => x['Dimension']).firstOrDefault();
          let subsectionName = obj.name;

          let total = Enumerable.from(this.questionWithResponseListCache).where(x => typeof x.columndata != 'undefined' && x.columndata['Dimension'] == sectionName && x.columndata['Sub-Dimension'] == subsectionName).count();
          let complete = Enumerable.from(this.questionWithResponseListCache).where(x => typeof x.columndata != 'undefined' && x.columndata['Dimension'] == sectionName && x.columndata['Sub-Dimension'] == subsectionName && (x.response != '0' || x.responsetarget != '0' || Enumerable.from(x.responseOptions).where(x => x.isChecked == true).count() != 0)).count();

          this.temp.push({ name: obj.name, prop: obj.prop, level: obj.level, complete: complete, total: total, indexno: this.moduleindex + '.' + this.submoduleindex + '.', dimension: sectionName });
          this.submoduleindex++;
        }

        if (obj.children.length > 0) {
          obj.children.forEach(c => {
            this.nestedToflatJSON(c);
          });
        }
      }
    }
  }

  back() {
    if (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment) {
      this.questionWithResponseList.forEach(f => {
        if (f.questionCategory == 'SQ' || typeof f.questionCategory == 'undefined' || f.questionCategory == '') {
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = f.response;
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = f.responsetarget;
        }
        else if (f.questionCategory == 'MC') {
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).firstOrDefault() * 10) / 10).toString();
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).firstOrDefault() * 10) / 10).toString();
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responseOptions = f.responseOptions;
        }
        else if (f.questionCategory == 'MS') {
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).sum() / Enumerable.from(f.responseOptions).select(y => y.responseValue).sum() * Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().benchmarkValue * 10) / 10).toString();
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).sum() / Enumerable.from(f.responseOptions).select(y => y.responseValue).sum() * Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().benchmarkValue * 10) / 10).toString();
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responseOptions = f.responseOptions;
        }
        else {
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = f.response;
          Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = f.responsetarget;
        }
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().questionCategory = f.questionCategory;
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().other = f.other;
      });

      var assessmentObj = new Assessment();
      assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment
      assessmentObj.isget = false;
      assessmentObj.userId = this.accountService.currentUser.id;
      assessmentObj.createdby = this.accountService.currentUser.id;
      assessmentObj.userResponses = JSON.stringify(this.questionWithResponseListCache);

      this.accountService.Set("assessment", assessmentObj).subscribe(
        results => {

          localStorage.setItem('currentUserDetails', JSON.stringify(results));

          let maxIndex = Enumerable.from(this.temp).select(s => s['index']).max();

          if (this.itemIndex == 2) {
            this.router.navigate(['home']);
          }
          else {
            this.itemIndex--;
            this.filterQuestion(this.itemIndex, 'prev');
            return;
          }
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }

  save() {
    if (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment) {
      if (this.moduleCount == this.itemIndex) {
        this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {
          this.saveData();
        });
      }
      else {
        this.saveData();
      }
    }
  }

  saveData() {
    this.questionWithResponseList.forEach(f => {
      if (f.questionCategory == 'SQ' || typeof f.questionCategory == 'undefined' || f.questionCategory == '') {
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = f.response;
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = f.responsetarget;
      }
      else if (f.questionCategory == 'MC') {
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).firstOrDefault() * 10) / 10).toString();
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).firstOrDefault() * 10) / 10).toString();
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responseOptions = f.responseOptions;
      }
      else if (f.questionCategory == 'MS') {
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).sum() / Enumerable.from(f.responseOptions).select(y => y.responseValue).sum() * Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().benchmarkValue * 10) / 10).toString();
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = (Math.round(Enumerable.from(f.responseOptions).where(x => x.isChecked).select(y => y.responseValue).sum() / Enumerable.from(f.responseOptions).select(y => y.responseValue).sum() * Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().benchmarkValue * 10) / 10).toString();
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responseOptions = f.responseOptions;
      }
      else {
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().response = f.response;
        Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().responsetarget = f.responsetarget;
      }
      Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().questionCategory = f.questionCategory;
      Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == f.customdbid).firstOrDefault().other = f.other;
    });
    var assessmentObj = new Assessment();
    assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment
    assessmentObj.isget = false;
    assessmentObj.userId = this.accountService.currentUser.id;
    assessmentObj.createdby = this.accountService.currentUser.id;
    assessmentObj.userResponses = JSON.stringify(this.questionWithResponseListCache);

    this.accountService.Set("assessment", assessmentObj).subscribe(
      results => {

        localStorage.setItem('currentUserDetails', JSON.stringify(results));

        let maxIndex = Enumerable.from(this.temp).select(s => s['index']).max();
        if (this.itemIndex < maxIndex) {
          this.itemIndex++;
          this.filterQuestion(this.itemIndex, 'next');
          return;
        }
        else {
          this.router.navigate(['reportqualitativecontent']);
        }
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  //checkParent(customdbid: any) {
  //  let flag = false;
  //  let obj = Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == customdbid).firstOrDefault();

  //  if (obj.columndata['Question Type'] == 'Basic') {
  //    flag = true;
  //  }
  //  else {

  //    let pId = obj.columndata['Depend On ID'];
  //    if (Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == pId).any()) {
  //      let pItem = Enumerable.from(this.questionWithResponseListCache).where(x => x.customdbid == pId).firstOrDefault();
  //      if (typeof pItem.response != 'undefined') {
  //        if (typeof obj.columndata['Response>Than'] != 'undefined' && Number.parseFloat(pItem.response) >= Number.parseFloat(obj.columndata['Response>Than']))
  //          flag = true;

  //        if (typeof obj.columndata['Response<Than'] != 'undefined' && Number.parseFloat(pItem.response) <= Number.parseFloat(obj.columndata['Response<Than']))
  //          flag = true;
  //      }
  //    }
  //  }

  //  return flag;
  //}

  onRadioChange(questiondbid, val, index) {
    this.questionWithResponseList.forEach(f => {
      if (f.customdbid == questiondbid) {
        f.responseOptions.forEach(o => {
          if (o.responseOption == val)
            o.isChecked = true;
          else
            o.isChecked = false;
        });
      }
    });
    this.quicklinkGenerator(index);
  }
}
