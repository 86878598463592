// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Component } from '@angular/core';



@Component({
    selector: 'banner-demo',
    templateUrl: './banner-demo.component.html'
})
export class BannerDemoComponent {
    banner1 = require('../../assets/images/demo/banner1.png');
    banner2 = require('../../assets/images/demo/banner2.png');
    banner3 = require('../../assets/images/demo/banner3.png');
    banner4 = require('../../assets/images/demo/banner4.png');
}
