import {
  Component,
  OnInit,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { Permission } from "../../models/permission.model";
import { AppComponent } from "../app.component";
import { Router } from "@angular/router";
import { Question, QuestionDTL } from "../../models/question.model";
import { Workbook } from "exceljs";
const EXCEL_EXTENSION = ".xlsx";
import * as fs from "file-saver";
import { DatePipe, getLocaleDateTimeFormat } from "@angular/common";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { QuestionEditorComponent } from "./question-editor.component";

@Component({
  selector: "question-management",
  templateUrl: "./question-management.component.html",
  styleUrls: ["./question-management.component.scss"],
})
export class QuestionManagementComponent implements OnInit, AfterViewInit {
  columns: string[] = [];
  columnsCache: string[] = [];
  rows: any[] = [];
  rowsCache: any[] = [];
  result: Question = new Question();

  popupcolumns: QuestionDTL[] = [];
  allPermissions: Permission[] = [];
  editedQuestion: Question;
  sourceQuestion: Question;
  questionName: any;
  editingQuestionName: { name: string };

  versionlist: any[] = [];
  version: number;
  currentversion: number = 0;
  questionDetailsData: Question[] = [];
  searchText: string;

  @ViewChild("detailsModal", { static: true })
  detailsModal: ModalDirective;

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  @ViewChild("questionEditor", { static: true })
  questionEditor: QuestionEditorComponent;

  module: any = "Question";
  obj: Question = new Question();
  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    public appComponent: AppComponent
  ) {}

  ngOnInit() {
    if (this.appComponent.isAdmin) {
      this.loadVersionList();
      this.loadData(0);
    }
  }

  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.questionEditor.changesSavedCallback = () => {
        this.loadData(0);
        this.editorModal.hide();
      };

      this.questionEditor.changesCancelledCallback = () => {
        this.editedQuestion = null;
        this.sourceQuestion = null;
        this.editorModal.hide();
      };
    }
  }

  inputFormData: string = "";
  closeModal() {
    this.inputFormData = "close";
    this.editorModal.hide();
  }

  loadVersionList() {
    this.accountService.Set("question/GetVersions", this.obj).subscribe(
      (results) => {
        if (results) {
          this.versionlist = [];
          Object.assign(this.versionlist, results);
        }
      },
      (error) => {
        this.appComponent.failedHelper(error);
      }
    );
  }

  loadData(version: number) {
    this.columnsCache = [];
    this.columns = [];
    this.rowsCache = [];
    this.rows = [];
    this.obj.isget = true;
    this.obj.version = version;

    this.accountService.Set(this.module, this.obj).subscribe(
      (results) => {
        Object.assign(this.result, results);
        this.obj.version = this.result.version;
        this.columnsCache =
          this.result.excelColumnName != null &&
          typeof this.result.excelColumnName != "undefined"
            ? this.result.excelColumnName.split(",")
            : [];
        this.columnsCache.forEach((f) => {
          if (
            !f.includes("Maturity Level") &&
            !f.includes("Depend On") &&
            !f.includes("Response")
          ) {
            this.columns.push(f);
          }
        });

        var _rows = [];
        Enumerable.from(this.result.questionDetails)
          .select((s) => s.questionDetailJSON)
          .forEach((f) => {
            _rows.push(JSON.parse(f));
          });

        this.rowsCache = [..._rows];
        this.rows = _rows;
      },
      (error) => {
        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve Questions from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        r[this.columns[0]],
        r[this.columns[1]]
      )
    );
  }

  downloadtemplate() {
    window.location.href = "../assets/QuestionnaireTemplate.xlsx";
  }

  downloadquestion() {
    if (
      this.result.dimensionInfoJSON != null &&
      typeof this.result.dimensionInfoJSON != "undefined"
    ) {
      //#region EXCEL CREATION

      var filename = "Question DB" + this.version;
      let workbook = new Workbook();
      let ws1 = workbook.addWorksheet("DB");
      let ws2 = workbook.addWorksheet("Info");

      //#region DB Tab
      let headerRow1 = ws1.addRow(this.columnsCache);
      headerRow1.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.font = { bold: true };
        cell.alignment = { wrapText: true };
      });

      for (let i = 1; i <= this.columnsCache.length; i++) {
        if (i == 2) ws1.getColumn(i).width = 60;
        else ws1.getColumn(i).width = 30;
        ws1.getColumn(i).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
      }

      let row1 = 2;
      this.rows.forEach((f) => {
        let columnindex = 1;
        this.columnsCache.forEach((c) => {
          ws1.getRow(row1).getCell(columnindex).value = {
            richText: [
              {
                text:
                  f[c] && f[c] != null && typeof f[c] != "undefined"
                    ? f[c]
                    : "",
              },
            ],
          };
          columnindex++;
        });

        ws1.getRow(row1).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "left",
        };
        row1++;
      });

      ws1.addRow([]);
      //#endregion

      //#region Info Tab
      let columnHeaderInfo = ["Dimension/Sub-Dimension", "Description"];
      let headerRow2 = ws2.addRow(columnHeaderInfo);
      headerRow2.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.font = { bold: true };
        cell.alignment = { wrapText: true };
      });

      for (let i = 1; i <= columnHeaderInfo.length; i++) {
        ws2.getColumn(i).width = 60;
        ws2.getColumn(i).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
      }

      let dimensionInfo = JSON.parse(this.result.dimensionInfoJSON);
      let row2 = 2;
      dimensionInfo.forEach((f) => {
        let columnindex = 1;
        columnHeaderInfo.forEach((c) => {
          ws2.getRow(row2).getCell(columnindex).value = {
            richText: [
              {
                text:
                  f[c] && f[c] != null && typeof f[c] != "undefined"
                    ? f[c]
                    : "",
              },
            ],
          };
          columnindex++;
        });

        ws2.getRow(row2).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "left",
        };
        row2++;
      });

      ws2.addRow([]);
      //#endregion

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(
          blob,
          new Date().getTime() + "_" + filename + EXCEL_EXTENSION
        );
      });

      //#endregion

      this.alertService.showStickyMessage(
        "Success",
        `Data download done. Excel creation pending.`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showStickyMessage(
        "Error",
        `Unable to download the questionnaire.`,
        MessageSeverity.error
      );
    }
  }

  showDetails(row: Question) {
    //this.detailsModal.show();
    //this.questionName = row.questiontext;
    //this.popupcolumns = [];
    //row.columndata.forEach(x => {
    //  var columnProperty = new QuestionTree();
    //  columnProperty.parentName = x.parentName;
    //  columnProperty.parentValue = x.parentValue;
    //  this.popupcolumns.push(columnProperty);
    //});
  }

  uploadquestion() {
    this.editingQuestionName = null;
    this.sourceQuestion = null;
    this.editedQuestion = this.questionEditor.newRole(this.allPermissions);
    this.inputFormData = "";
    this.editorModal.show();
  }

  onEditorModalHidden() {
    this.editingQuestionName = null;
    this.loadVersionList();
    this.questionEditor.resetForm(true);
    //this.searchText = "";
  }
}
