export class ExternalUserRegistration {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(firstName?: string, lastName?: string, mobileNumber?: string, password?: string, product?: string
    , email?: string, registrationToken?: string, preferredLanguage?: string, orgID?: string, apikey?: string, appid?: string, apiurl?: string) {

    this.firstName = firstName;
    this.lastName = lastName;
    this.mobileNumber = mobileNumber;
    this.password = password;
    this.product = product;
    this.email = email;
    this.registrationToken = registrationToken;
    preferredLanguage = preferredLanguage
    orgID = orgID;
    this.apikey = apikey;
    this.appid = appid;
    this.apiurl = apiurl;
  }


  public firstName: string;
  public lastName: string;
  public mobileNumber: string;
  public password: string;
  public product: string;
  public email: string;
  public registrationToken: string;
  public preferredLanguage: string;
  public orgID: string;
  public apikey: string;
  public appid: string;
  public apiurl: string;

}
