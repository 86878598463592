import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  DefaultUrlSerializer,
  UrlSerializer,
  UrlTree,
} from "@angular/router";

import { LoginComponent } from "./components/login/login.component";
import { HomeComponent } from "./components/home/home.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth-guard.service";
import { Utilities } from "./services/utilities";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { AssessmentComponent } from "./components/assessment/assessment.component";
import { QuestionnairesComponent } from "./components/questionnaires/questionnaires.component";
import { TemplateManagementComponent } from "./components/template/template-management.component";
import { UserInfoComponent } from "./components/controls/user-info.component";
import { ClientManagementComponent } from "./components/client/client-management.component";
import { QuestionManagementComponent } from "./components/question/question-management.component";
import { TemplateEditorComponent } from "./components/template/template-editor.component";
import { UsersManagementComponent } from "./components/controls/users-management.component";
import { CompletedManagementComponent } from "./components/completed/completed-management.component";
import { OpportunitiesManagementComponent } from "./components/opportunities/opportunities-management.component";
import { DigitalleversManagementComponent } from "./components/digitallevers/digitallevers-management.component";
import { DigitalleversNewManagementComponent } from "./components/digitallevers-with-upload/digitallevers-management.component";
import { ReportqualitativecontentComponent } from "./components/reportqualitativecontent/reportqualitativecontent.component";
import { ReportqualitativechartComponent } from "./components/reportqualitativechart/reportqualitativechart.component";
import { ReportbaselinedataComponent } from "./components/reportbaselinedata/reportbaselinedata.component";
import { ReportrelevantvaluepoolsComponent } from "./components/reportrelevantvaluepools/reportrelevantvaluepools.component";
import { ReportdigitizationleverscontentComponent } from "./components/reportdigitizationleverscontent/reportdigitizationleverscontent.component";
import { ReportdigitizationleverschartComponent } from "./components/reportdigitizationleverschart/reportdigitizationleverschart.component";
import { ReportprioritizationframeworkComponent } from "./components/reportprioritizationframework/reportprioritizationframework.component";
import { UserRegistrationComponent } from "./components/controls/user-registration.component";
import { UserTemplateComponent } from "./components/controls/user-template.component";
import { ReportassessmentComponent } from "./components/reportassessment/reportassessment.component";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const possibleSeparators = /[?;#]/;
    const indexOfSeparator = url.search(possibleSeparators);
    let processedUrl: string;

    if (indexOfSeparator > -1) {
      const separator = url.charAt(indexOfSeparator);
      const urlParts = Utilities.splitInTwo(url, separator);
      urlParts.firstPart = urlParts.firstPart.toLowerCase();

      processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
    } else {
      processedUrl = url.toLowerCase();
    }

    return super.parse(processedUrl);
  }
}

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { title: "Home" },
  },
  {
    path: "register",
    component: UserRegistrationComponent,
    data: { title: "Register" },
    children: [
      {
        path: ":subpath",
        component: UserRegistrationComponent,
      },
    ],
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
  {
    path: "privacy",
    component: PrivacyComponent,
    canActivate: [AuthGuard],
    data: { title: "Privacy" },
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { title: "Settings" },
  },
  {
    path: "userinfo",
    component: UserInfoComponent,
    canActivate: [AuthGuard],
    data: { title: "User Info" },
  },
  {
    path: "client",
    component: ClientManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Client Organisation" },
  },
  {
    path: "opportunities",
    component: OpportunitiesManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Opportunities" },
  },
  // {
  //   path: "digitallevers",
  //   component: DigitalleversManagementComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: "Digital Levers" },
  // },
  {
    path: "digitallevers",
    component: DigitalleversNewManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Digital Levers" },
  },
  {
    path: "completed",
    component: CompletedManagementComponent,
    data: { title: "Completed Assessment" },
  },
  {
    path: "questiondb",
    component: QuestionManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Question DB" },
  },
  {
    path: "template",
    component: TemplateManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Template" },
  },
  {
    path: "templateedit",
    component: TemplateEditorComponent,
    canActivate: [AuthGuard],
    data: { title: "Template Edit" },
  },
  {
    path: "users",
    component: UsersManagementComponent,
    canActivate: [AuthGuard],
    data: { title: "Users" },
  },
  { path: "about", component: AboutComponent, data: { title: "About Us" } },
  { path: "home", redirectTo: "/", pathMatch: "full" },
  {
    path: "assessment",
    component: AssessmentComponent,
    data: { title: "Assessment" },
  },
  {
    path: "questionnaires",
    component: QuestionnairesComponent,
    data: { title: "Questionnaires" },
  },
  {
    path: "reportqualitativecontent",
    component: ReportqualitativecontentComponent,
    data: { title: "Report" },
  },
  {
    path: "reportqualitativechart",
    component: ReportqualitativechartComponent,
    data: { title: "Report" },
  },
  {
    path: "reportbaselinedata",
    component: ReportbaselinedataComponent,
    data: { title: "Report" },
  },
  {
    path: "reportrelevantvaluepools",
    component: ReportrelevantvaluepoolsComponent,
    data: { title: "Report" },
  },
  {
    path: "reportdigitizationleverscontent",
    component: ReportdigitizationleverscontentComponent,
    data: { title: "Report" },
  },
  {
    path: "reportdigitizationleverschart",
    component: ReportdigitizationleverschartComponent,
    data: { title: "Report" },
  },
  {
    path: "reportprioritizationframework",
    component: ReportprioritizationframeworkComponent,
    data: { title: "Report" },
  },
  {
    path: "userprofile",
    component: UserRegistrationComponent,
    canActivate: [AuthGuard],
    data: { title: "User Profile" },
  },
  {
    path: "usertemplate",
    component: UserTemplateComponent,
    canActivate: [AuthGuard],
    data: {
      title: "User Template",
    },
  },
  {
    path: "reportassessment",
    component: ReportassessmentComponent,
    data: { title: "Assessment Report" },
  },

  {
    path: "**",
    component: NotFoundComponent,
    data: { title: "Page Not Found" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    AuthGuard,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
  ],
})
export class AppRoutingModule {}
