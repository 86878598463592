import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppComponent } from '../app.component';
import { AssessmentQuestions, Assessment } from '../../models/assessment.model';
import { Template } from '../../models/template.model';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'reportqualitativecontentprint',
  templateUrl: './reportqualitativecontentprint.component.html',
  styleUrls: ['./reportqualitativecontentprint.component.scss'],
  animations: [fadeInOut]
})

export class ReportqualitativecontentprintComponent implements OnInit {
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp = {};
  questionWithResponseList: any[] = [];
  currentAssessment: Assessment = new Assessment();
  obj: AssessmentQuestions = new AssessmentQuestions();
  generateReportFlag: number = 0;
  responseData: any = [];
  overallScore: number = 0;
  isQuantitative: boolean;

  constructor(private alertService: AlertService, public appComponent: AppComponent, private accountService: AccountService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.loadAssessment();
      this.loadResponses();
    }, 10000)
    
    
  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.children.length > 0) {
        obj.children.forEach(c => {
          this.temp = {};
          this.temp[obj.prop] = obj.name;
          this.temp[c.prop] = c.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(c);
        });
      }
    }
  }

  loadAssessment() {
    if (localStorage.getItem('printTemplate') != null && typeof localStorage.getItem('printTemplate') != 'undefined') {
      this.currentAssessment = JSON.parse(localStorage.getItem('printAssessment')) as Assessment;
      this.questionWithResponseList = JSON.parse((JSON.parse(localStorage.getItem('printAssessment')) as Assessment).userResponses);
      this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('printTemplate')) as Template).treeStructure);
      this.isQuantitative = (JSON.parse(localStorage.getItem('printTemplate')) as Template).isQuantitative;

      this.moduleTreeCache.forEach(f => {
        if (f.ischecked) {
          this.temp = {};
          this.temp[f.prop] = f.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(f);
        }
      });

      this.generateReportFlag = 0
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.response == 0 && (q.other == null || typeof q.other == 'undefined' || q.other == '')) {
            this.generateReportFlag++;
          }
        });
      }
    }
  }

  loadResponses() {
    let overallResponseScore = 0, overallResponseCount = 0;

    this.responseData = [];
    let questionStructure = Enumerable.from(JSON.parse((JSON.parse(localStorage.getItem('printTemplate')) as Template).questionStructure)).select(s => s['columndata']);

    this.moduleTree.forEach(f => {
      if (Object.keys(f).length == 1) {
        let desc = Enumerable.from(this.moduleTreeCache).where(x => x.name == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();
        this.responseData.push({ short: '', full: f[Object.keys(f)[0]], xxx: 0, xxxtarget: 0, target: 0, total: 0, dimension: '', checked: false, showmore: false, description: desc });
      }
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.columndata != null && typeof q.columndata != 'undefined') {
            if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]]) {
              if (typeof q.columndata[Object.keys(f)[1]] != 'undefined') {
                let short = '';
                if (Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).any()) {
                  short = Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).firstOrDefault().Abbreviation;
                }
                if (!Enumerable.from(this.responseData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                  var response = 0, responsecount = 0, responsetarget = 0, responsetargetcount = 0, benchmark = 0, benchmarkcount = 0;
                  this.questionWithResponseList.forEach(s => {
                    if (s.columndata != null && typeof s.columndata != 'undefined') {
                      if (s.columndata['Sub-Dimension'] == q.columndata[Object.keys(f)[0]]) {
                        response += parseFloat(s.response);
                        responsecount++;
                        responsetarget += parseFloat(s.responsetarget);
                        responsetargetcount++;
                        benchmark += parseFloat(s.benchmarkValue);
                        benchmarkcount++;
                      }
                    }
                  });
                  let subdim = Enumerable.from(this.moduleTreeCache).where(x => x.name == q.columndata['Dimension']).select(y => y["children"]).firstOrDefault();
                  let desc = Enumerable.from(subdim).where(x => x['name'] == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();

                  let xxx = 0, xxxtarget = 0, target = 0;
                  try {
                    if (!isNaN(response) && !isNaN(responsecount))
                      xxx = response / responsecount;
                    else
                      xxx = 0;
                    if (!isNaN(responsetarget) && !isNaN(responsetargetcount))
                      xxxtarget = responsetarget / responsetargetcount;
                    else
                      xxxtarget = 0;
                    if (!isNaN(benchmark) && !isNaN(benchmarkcount))
                      target = benchmark / benchmarkcount;
                    else
                      target = 0;

                    if (!isNaN(response) && !isNaN(responsecount)) {
                      overallResponseScore += response;
                      overallResponseCount += responsecount;
                    }
                  }
                  catch {
                    xxx = 0;
                    xxxtarget = 0;
                    target = 0;
                  }

                  this.responseData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: xxx, xxxtarget: xxxtarget, target: target, total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false, description: desc });
                }
              }
            }
          }
        });
      }
    });

    this.responseData.forEach((data, index, datas) => {
      (data as any).index = index + 1;
    });

    let dimcount = 0, dimscore = 0;

    this.responseData.forEach(f => {
      if (f.short != '') {
        try {
          if (!isNaN(f.xxx) && !isNaN(f.target))
            f.total = Math.round((f.xxx / f.target) * 100);
          else
            f.total = 0;
        }
        catch {
          f.total = 0;
        }
      }
      else {
        let nextIndex = Enumerable.from(this.responseData).max(m => m['index']) + 1;

        if (Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').any()) {
          nextIndex = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').firstOrDefault()['index'];
        }

        var dimensionAverage = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxx']);
        var dimensionAverageTarget = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxxtarget']);

        try {
          if (!isNaN(dimensionAverage) && !isNaN(dimensionAverageTarget)) {
            f.xxx = dimensionAverage;
            f.xxxtarget = dimensionAverageTarget;
            f.target = 5;
            f.total = Math.round((dimensionAverage / 5) * 100);
          }
          else {
            f.xxx = 0;
            f.xxxtarget = 0;
            f.target = 0;
            f.total = 0;
          }
        }
        catch {
          f.xxx = 0;
          f.xxxtarget = 0;
          f.target = 0;
          f.total = 0;
        }

        dimscore += f.total;
        dimcount++;
      }
    });

    //this.overallScore += Math.round(dimscore / dimcount);
    this.overallScore += Math.round(overallResponseScore / (overallResponseCount * 5) * 100);
  }
}
