import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { AlertService, DialogType, MessageSeverity } from "../../services/alert.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { User } from "../../models/user.model";
import { UserEdit } from "../../models/user-edit.model";
import { Role } from "../../models/role.model";
import { Permission } from "../../models/permission.model";
import { Client } from "../../models/client.model";
import { Template } from "../../models/template.model";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ExternalUserRegistration } from "src/app/models/external-user-registration.model";

@Component({
  selector: "user-registration",
  templateUrl: "./user-registration.component.html",
  styleUrls: ["./user-registration.component.scss"],
})
export class UserRegistrationComponent implements OnInit {
  public passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/;
  public isEditMode = false;
  public isNewUser = false;
  public isSaving = false;
  public isChangePassword = false;
  public isEditingSelf = false;
  public showValidationErrors = false;
  public uniqueId: string = Utilities.uniqueId();
  public user: User = new User();
  public userEdit: UserEdit;
  public allRoles: Role[] = [];
  public formResetToggle = true;
  public isRegistrationPage = false;
  public externalUser = new ExternalUserRegistration();
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @Input()
  isViewOnly: boolean = true;

  @Input()
  isGeneralEditor = false;

  @ViewChild("f", { static: false })
  public form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild("userName", { static: false })
  public userName;

  @ViewChild("userPassword", { static: false })
  public userPassword;

  @ViewChild("email", { static: false })
  public email;

  @ViewChild("currentPassword", { static: false })
  public currentPassword;

  @ViewChild("newPassword", { static: false })
  public newPassword;

  @ViewChild("confirmPassword", { static: false })
  public confirmPassword;

  @ViewChild("roles", { static: false })
  public roles;

  @ViewChild("rolesSelector", { static: false })
  public rolesSelector;
  @ViewChild("portfolio", { static: false })
  public portfolio;

  @ViewChild("Organisation", { static: false })
  public Organisation;

  @ViewChild("Sector", { static: false })
  public Sector;

  public clientObj: Client = new Client();
  public orgList: Client[] = [];
  public templObj: Template = new Template();
  public templList: Template[] = [];
  public clientlist: any[] = [];
  public portfoliolist: any[] = [];
  public clientname: string;
  public isVisible: boolean = false;
  public isProfileEdit: boolean = false;
  public inputData: any = {};
  public params: any = {};
  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
   
    this.inputData = this.activeRoute.data.subscribe((item) => {
      if (item.title === "Register") {
        this.isRegistrationPage = true;
        this.isEditMode = true;
        this.showValidationErrors = true;
        this.isViewOnly = false;
        this.isNewUser = true;
        this.userEdit = new UserEdit();
        this.isGeneralEditor = true;
      }
    });
    this.params = this.activeRoute.queryParams.subscribe((params) => {
      if (this.isRegistrationPage && params.registrationToken && params.email) {
        this.userEdit.userName = params.email;
        this.userEdit.email = params.email;
        this.externalUser.registrationToken = params.registrationToken;
      }
    });
    this.isProfileEdit = false;
    this.loadTemplates();
    this.allRoles;
    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
    }
  }

  ngOnDestroy() {
    this.inputData.unsubscribe();
    this.params.unsubscribe();
  }

  loadOrganisation() {
   
    this.orgList = [];
    this.clientObj.isget = true;
    if (this.accountService.currentUser != null && this.accountService.currentUser.roles[0] == 'administrator') {
      this.accountService.Set("client", this.clientObj).subscribe(
        (results) => {
          if (results) {
            Object.assign(this.orgList, results);
            if (this.isViewOnly) {
              this.clientname = Enumerable.from(this.orgList)
                .where((x) => x.id == this.user.orgId)
                .select((y) => y.name)
                .firstOrDefault();
              this.clientlist = [];
              this.orgList.forEach((f) => {
                if (
                  typeof f.name != "undefined" &&
                  f.name != null &&
                  f.name.trim().length > 0
                )
                  this.clientlist.push({ id: f.id, name: f.name });
              });

              //this.loadPortFolio(this.userEdit.orgId);
            } else {
              this.clientlist = [];
              this.orgList.forEach((f) => {
                if (
                  typeof f.name != "undefined" &&
                  f.name != null &&
                  f.name.trim().length > 0
                )
                  this.clientlist.push({ id: f.id, name: f.name });
              });

              this.loadPortFolio(this.userEdit.orgId);
            }
          }
        },
        (error) => {
          this.alertService.showStickyMessage(
            "Load Error",
            `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
              error
            )}"`,
            MessageSeverity.error,
            error
          );
        }
      );
    }
    else {
      this.accountService.Set("loginhandler/getclients", this.clientObj).subscribe(
        (results) => {
          if (results) {
            Object.assign(this.orgList, results);
            if (this.isViewOnly) {
              this.clientname = Enumerable.from(this.orgList)
                .where((x) => x.id == this.user.orgId)
                .select((y) => y.name)
                .firstOrDefault();
              this.clientlist = [];
              this.orgList.forEach((f) => {
                if (
                  typeof f.name != "undefined" &&
                  f.name != null &&
                  f.name.trim().length > 0
                )
                  this.clientlist.push({ id: f.id, name: f.name });
              });

              //this.loadPortFolio(this.userEdit.orgId);
            } else {
              this.clientlist = [];
              this.orgList.forEach((f) => {
                if (
                  typeof f.name != "undefined" &&
                  f.name != null &&
                  f.name.trim().length > 0
                )
                  this.clientlist.push({ id: f.id, name: f.name });
              });

              this.loadPortFolio(this.userEdit.orgId);
            }
          }
        },
        (error) => {
          this.alertService.showStickyMessage(
            "Load Error",
            `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
              error
            )}"`,
            MessageSeverity.error,
            error
          );
        }
      );
    }


    
  }

  loadPortFolio(orgId: number) {
    this.portfoliolist = [];

    if (
      Enumerable.from(this.orgList)
        .where((x) => x.id == orgId)
        .any()
    ) {
      let selectedObj = Enumerable.from(this.orgList)
        .where((x) => x.id == orgId)
        .firstOrDefault();

      if (
        typeof selectedObj.portfolio1 != "undefined" &&
        selectedObj.portfolio1 != null &&
        selectedObj.portfolio1.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio1,
        });
      if (
        typeof selectedObj.portfolio2 != "undefined" &&
        selectedObj.portfolio2 != null &&
        selectedObj.portfolio2.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio2,
        });
      if (
        typeof selectedObj.portfolio3 != "undefined" &&
        selectedObj.portfolio3 != null &&
        selectedObj.portfolio3.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio3,
        });
      if (
        typeof selectedObj.portfolio4 != "undefined" &&
        selectedObj.portfolio4 != null &&
        selectedObj.portfolio4.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio4,
        });
      if (
        typeof selectedObj.portfolio5 != "undefined" &&
        selectedObj.portfolio5 != null &&
        selectedObj.portfolio5.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio5,
        });
      if (
        typeof selectedObj.portfolio6 != "undefined" &&
        selectedObj.portfolio6 != null &&
        selectedObj.portfolio6.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio6,
        });
      if (
        typeof selectedObj.portfolio7 != "undefined" &&
        selectedObj.portfolio7 != null &&
        selectedObj.portfolio7.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio7,
        });
    }
  }

  loadTemplates() {
    this.templList = [];
    this.templObj.isget = true;
    if (this.accountService.currentUser != null && this.accountService.currentUser.roles[0] == 'administrator') {
      this.accountService.Set("template", this.templObj).subscribe(
        (results) => {
          if (results) {
            Object.assign(this.templList, results);
            //this.templList = results;
            //this.templList = Enumerable.from(this.templList).select(x => x.name).toArray();
            //Object.assign(this.templList, results);
          }
        },
        (error) => {
          this.alertService.showStickyMessage(
            "Load Error",
            `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
              error
            )}"`,
            MessageSeverity.error,
            error
          );
        }
      );
    }
    else {
      this.accountService.Set("loginhandler/gettemplates", this.templObj).subscribe(
        (results) => {
          if (results) {
            Object.assign(this.templList, results);
            //this.templList = results;
            //this.templList = Enumerable.from(this.templList).select(x => x.name).toArray();
            //Object.assign(this.templList, results);
          }
        },
        (error) => {
          this.alertService.showStickyMessage(
            "Load Error",
            `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
              error
            )}"`,
            MessageSeverity.error,
            error
          );
        }
      );
    }
  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService
        .getUserAndRoles(this.accountService.currentUser.id)
        .subscribe(
          (results) => {
            if (results)
              this.onCurrentUserDataLoadSuccessful(results[0], results[1]);
          },
          (error) => this.onCurrentUserDataLoadFailed(error)
        );
    } else {
      this.accountService.getUser(this.accountService.currentUser.id).subscribe(
        (user) => {
          if (user)
            this.onCurrentUserDataLoadSuccessful(
              user,
              user.roles.map((x) => new Role(x))
            );
        },
        (error) => this.onCurrentUserDataLoadFailed(error)
      );
    }
  }

  private loadUserRolesData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService
        .getUserAndRoles(this.accountService.currentUser.id)
        .subscribe(
          (results) => {
            if (results) this.onUserRolesLoadSuccessful(results[1]);
          },
          (error) => this.onCurrentUserDataLoadFailed(error)
        );
    } else {
      this.accountService.getUser(this.accountService.currentUser.id).subscribe(
        (user) => {
          if (user)
            this.onUserRolesLoadSuccessful(user.roles.map((x) => new Role(x)));
        },
        (error) => this.onCurrentUserDataLoadFailed(error)
      );
    }
  }

  private onUserRolesLoadSuccessful(roles: Role[]) {
    this.allRoles = roles;
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
    this.loadOrganisation();
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      "Load Error",
      `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
        error
      )}"`,
      MessageSeverity.error,
      error
    );

    this.user = new User();
  }

  getRoleByName(name: string) {
    return this.allRoles.find((r) => r.name == name);
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  deletePasswordFromUser(user: UserEdit | User) {
    const userEdit = user as UserEdit;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }

  edit() {
    if (!this.isGeneralEditor) {
      this.isProfileEdit = true;
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      Object.assign(this.userEdit, this.user);
      this.userEdit.clientOrganisation = this.clientname;
      this.userEdit.portfolioCompany = this.user.jobTitle;
      this.loadPortFolio(this.userEdit.orgId);
    } else {
      if (!this.userEdit) {
        this.userEdit = new UserEdit();
      }

      this.isEditingSelf = this.accountService.currentUser
        ? this.userEdit.id == this.accountService.currentUser.id
        : false;
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;
    this.isViewOnly = false;
  }

  save() {
   
    if (!this.isRegistrationPage) {
      if (
        this.userEdit.fullName != "" &&
        this.userEdit.fullName != null &&
        typeof this.userEdit.fullName != "undefined"
      ) {
        if (this.userEdit.fullName.trim() == "") {
          this.alertService.showDialog(
            "Please enter a valid full name.",
            DialogType.alert
          );
          return;
        }
      }
      this.isSaving = true;
      this.alertService.startLoadingMessage("Saving changes...");
      let template = this.templList.find(
        (x) => x.isDefault && x.industrySector == this.userEdit.industrySector
      );
      if (template != null) {
        this.userEdit.templId = template.id;

        this.userEdit.phoneNumber = Enumerable.from(this.templList)
          .where((x) => x.id == this.userEdit.templId)
          .select((s) => s.name)
          .firstOrDefault();
      }
      // Hard coded password fields as part of integration with product IAM
      this.userEdit.userName = this.userEdit.email;
      this.userEdit.newPassword = atob("dGVtcFBAc3MxMjM=");  //"tempP@ss123";
      this.userEdit.confirmPassword = atob("dGVtcFBAc3MxMjM="); //"tempP@ss123";
      if (this.isNewUser) {
        this.accountService.newUserRegistration(this.userEdit).subscribe(
          (user) => this.saveSuccessHelper(user),
          (error) => this.saveFailedHelper(error)
        );
      } else {
        this.accountService.updateUser(this.userEdit).subscribe(
          (response) => this.profileUpdateSuccessHelper(),
          (error) => this.saveFailedHelper(error)
        );
      }
    } else {
      this.externalUser.firstName = this.userEdit.firstName;
      this.externalUser.lastName = this.userEdit.lastName;
      this.externalUser.email = this.userEdit.email;
      this.externalUser.mobileNumber = "";
      this.externalUser.password = this.userEdit.newPassword;
      this.externalUser.product = environment.idam.client_app_id;
      this.externalUser.orgID = environment.idam.orgId;
      this.externalUser.preferredLanguage = "en";

      this.externalUser.appid = environment.idam.client_app_id;
      this.externalUser.apikey = environment.idam.client_secret;
      this.externalUser.apiurl = environment.idam.client_host;

      this.accountService
        .SetData<string>("IAMExternalUserRegistration", this.externalUser)
        .subscribe(
          (result: string) => {
            const response: any = JSON.parse(result);
            if (response.status === "SUCCESS") {
              this.alertService.showMessage(
                "Success",
                response.description,
                MessageSeverity.success
              );
              this.isSaving = true;
              this.alertService.startLoadingMessage("Saving changes...");
              let template = this.templList.find(
                (x) =>
                  x.isDefault &&
                  x.industrySector == this.userEdit.industrySector
              );
              if (template != null) {
                this.userEdit.templId = template.id;

                this.userEdit.phoneNumber = Enumerable.from(this.templList)
                  .where((x) => x.id == this.userEdit.templId)
                  .select((s) => s.name)
                  .firstOrDefault();
              }

              this.userEdit.roles = ["user"];
              this.userEdit.isEnabled = true;

              // Hard coded password fields as part of integration with product IAM
              this.userEdit.fullName =
                this.userEdit.firstName + " " + this.userEdit.lastName;
              this.userEdit.userName = this.userEdit.email;
              this.userEdit.newPassword = atob("dGVtcFBAc3MxMjM=");  //"tempP@ss123";
              this.userEdit.confirmPassword = atob("dGVtcFBAc3MxMjM="); //"tempP@ss123";
              this.accountService.newUserRegistration(this.userEdit).subscribe(
                (user) => this.saveSuccessHelper(user),
                (error) => this.saveFailedHelper(error)
              );
            } else {
              this.alertService.showMessage(
                "Error",
                response.description,
                MessageSeverity.error
              );
            }
          },
          (error) => {
            this.alertService.showStickyMessage(
              "Error",
              error.description,
              MessageSeverity.error,
              error
            );
          }
        );
    }
  }

  private profileUpdateSuccessHelper(user?: User) {
    //this.router.navigate(['userprofile']);

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    //this.deletePasswordFromUser(this.userEdit);
    //Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();

    if (this.isEditingSelf) {
      this.alertService.showMessage(
        "Success",
        "Changes to your User Profile was saved successfully",
        MessageSeverity.success
      );
    }
    if (!this.isGeneralEditor) {
      this.isEditMode = false;
      this.isProfileEdit = false;
      this.loadCurrentUserData();
    }
  }

  private saveSuccessHelper(user?: User) {
    this.testIsRoleUserCountChanged(this.user, this.userEdit);

    if (user) {
      Object.assign(this.userEdit, user);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    this.deletePasswordFromUser(this.userEdit);
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();

    if (this.isGeneralEditor) {
      if (this.isNewUser) {
        this.alertService.showMessage(
          "Success",
          `User \"${this.user.userName}\" was created successfully`,
          MessageSeverity.success
        );
      } else if (!this.isEditingSelf) {
        this.alertService.showMessage(
          "Success",
          `Changes to user \"${this.user.userName}\" was saved successfully`,
          MessageSeverity.success
        );
      }
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage(
        "Success",
        "Changes to your User Profile was saved successfully",
        MessageSeverity.success
      );
      this.refreshLoggedInUser();
    }

    this.isEditMode = false;

    if (this.isRegistrationPage) {
      this.router.navigate(["/login"]);
    }

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      "Save Error",
      "The below errors occured whilst saving your changes:",
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {
    const rolesAdded = this.isNewUser
      ? editedUser.roles
      : editedUser.roles.filter(
        (role) => currentUser.roles.indexOf(role) == -1
      );
    const rolesRemoved = this.isNewUser
      ? []
      : currentUser.roles.filter(
        (role) => editedUser.roles.indexOf(role) == -1
      );

    const modifiedRoles = rolesAdded.concat(rolesRemoved);

    if (modifiedRoles.length) {
      setTimeout(() =>
        this.accountService.onRolesUserCountChanged(modifiedRoles)
      );
    }
  }

  cancel() {
    if (this.isGeneralEditor) {
      this.userEdit = this.user = new UserEdit();
    } else {
      this.userEdit = new UserEdit();
    }

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
    this.isProfileEdit = false;
  }

  close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser().subscribe(
      (user) => {
        this.loadCurrentUserData();
      },
      (error) => {
        this.alertService.resetStickyMessage();
        this.alertService.showStickyMessage(
          "Refresh failed",
          "An error occured whilst refreshing logged in user information from the server",
          MessageSeverity.error,
          error
        );
      }
    );
  }

  changePassword() {
    this.isChangePassword = true;
  }

  unlockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage("Unblocking user...");

    this.accountService.unblockUser(this.userEdit.id).subscribe(
      () => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage(
          "Success",
          "User has been successfully unblocked",
          MessageSeverity.success
        );
      },
      (error) => {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(
          "Unblock Error",
          "The below errors occured whilst unblocking the user:",
          MessageSeverity.error,
          error
        );
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
      }
    );
  }

  resetForm(replace = false) {
    this.isChangePassword = false;

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  newUser(allRoles: Role[]) {
    this.isGeneralEditor = true;
    this.isNewUser = true;

    this.allRoles = [...allRoles];
    this.user = this.userEdit = new UserEdit();
    this.user.roles = [];
    this.user.roles.push("user");

    this.userEdit.isEnabled = true;
    this.loadOrganisation();
    this.edit();

    return this.userEdit;
  }

  editUser(user: User, allRoles: Role[]) {
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;

      this.setRoles(user, allRoles);
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      this.user.orgId = user.orgId;
      this.userEdit.orgId = user.orgId;
      this.user.templId = user.templId;
      this.userEdit.templId = user.templId;
      this.edit();
      this.loadOrganisation();

      return this.userEdit;
    } else {
      this.loadOrganisation();
      return this.newUser(allRoles);
    }
  }

  displayUser(user: User, allRoles?: Role[]) {
    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setRoles(user, allRoles);

    this.isEditMode = false;
  }

  register() {
  }

  private setRoles(user: User, allRoles?: Role[]) {
    this.allRoles = allRoles ? [...allRoles] : [];

    if (user.roles) {
      for (const ur of user.roles) {
        if (!this.allRoles.some((r) => r.name == ur)) {
          this.allRoles.unshift(new Role(ur));
        }
      }
    }

    if (allRoles == null || this.allRoles.length != allRoles.length) {
      setTimeout(() => {
        if (this.rolesSelector) {
          this.rolesSelector.refresh();
        }
      });
    }
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(
      Permission.viewRolesPermission
    );
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(
      Permission.assignRolesPermission
    );
  }
}
