import { Component, ViewChild } from "@angular/core";
import {
  AlertService,
  MessageSeverity,
  DialogType,
} from "../../services/alert.service";
import { AccountService } from "../../services/account.service";
import { Permission } from "../../models/permission.model";
import { AppComponent } from "../app.component";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { DigitalLevers } from "../../models/digitallevers.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: "digitallevers-editor-new",
  templateUrl: "./digitallevers-editor.component.html",
  styleUrls: ["./digitallevers-editor.component.scss"],
  providers: [DatePipe],
})
export class DigitalleversNewEditorComponent {
  private isNew = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public digitalleversEdit: DigitalLevers = new DigitalLevers();
  private editingDigitallevers: string;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild("f", { static: false })
  private form;

  obj: DigitalLevers = new DigitalLevers();
  module: any = "Digitallevers";

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private datePipe: DatePipe
  ) {}

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  save() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.digitalleversEdit.opportunityformula != "" &&
          this.digitalleversEdit.opportunityformula != null &&
          typeof this.digitalleversEdit.opportunityformula != "undefined"
        ) {
          if (
            this.digitalleversEdit.opportunityformula.trim() == "" ||
            !(
              this.digitalleversEdit.opportunityformula.indexOf("(") != -1 ||
              (this.digitalleversEdit.opportunityformula.indexOf(")") != -1 &&
                this.digitalleversEdit.opportunityformula.indexOf("(") != -1) ||
              this.digitalleversEdit.opportunityformula.indexOf("/") != -1 ||
              this.digitalleversEdit.opportunityformula.indexOf("*") != -1 ||
              this.digitalleversEdit.opportunityformula.indexOf("+") != -1 ||
              this.digitalleversEdit.opportunityformula.indexOf("-") != -1
            ) ||
            /\($|\/$|\*$|\+$|\-$/.test(
              this.digitalleversEdit.opportunityformula.trim()
            )
          ) {
            this.alertService.showDialog(
              "Please enter a valid opportunity formula.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.digitalleversEdit.paybackperiodonetime != null &&
          typeof this.digitalleversEdit.paybackperiodonetime != "undefined" &&
          this.digitalleversEdit.paybackperiodtotal != null &&
          typeof this.digitalleversEdit.paybackperiodtotal != "undefined"
        ) {
          if (
            this.digitalleversEdit.paybackperiodonetime >
            this.digitalleversEdit.paybackperiodtotal
          ) {
            this.alertService.showDialog(
              "Payback Period - Total (Years) should not be less than Payback Period - One Time (Years).",
              DialogType.alert
            );
            return;
          }
        }
        this.digitalleversEdit.createdby = this.accountService.currentUser.id;
        this.digitalleversEdit.isdeleted = false;
        this.digitalleversEdit.isget = false;
        this.digitalleversEdit.timestamp = new Date();
        this.digitalleversEdit.lever = this.digitalleversEdit.lever
          .replace("(", " ")
          .replace(")", " ")
          .replace("/", " ")
          .replace("*", " ")
          .replace("+", " ")
          .replace("-", " ");
        this.accountService.Set(this.module, this.digitalleversEdit).subscribe(
          () => {
            this.alertService.showDialog(
              "Saved Successfully.",
              DialogType.alert
            );
            this.digitalleversEdit = new DigitalLevers();
            this.resetForm();

            if (this.changesSavedCallback) {
              this.changesSavedCallback();
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  cancel() {
    this.digitalleversEdit = new DigitalLevers();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  new() {
    this.isNew = true;
    this.showValidationErrors = true;

    this.editingDigitallevers = null;
    this.digitalleversEdit = new DigitalLevers();

    this.digitalleversEdit.minimumcasecaselever = 25;
    this.digitalleversEdit.realisticcasecaselever = 100;
    this.digitalleversEdit.bestcasecaselever = 125;

    return this.digitalleversEdit;
  }

  edit(digitallevers: DigitalLevers) {
    if (digitallevers) {
      this.isNew = false;
      this.showValidationErrors = true;

      this.editingDigitallevers = digitallevers.lever;

      this.digitalleversEdit = new DigitalLevers();
      Object.assign(this.digitalleversEdit, digitallevers);

      if (this.digitalleversEdit.timestamp == "0001-01-01")
        this.digitalleversEdit.timestamp = null;
      this.digitalleversEdit.timestamp = this.datePipe.transform(
        this.digitalleversEdit.timestamp,
        "MM-dd-yyyy hh:mm:ss a"
      );

      return this.digitalleversEdit;
    } else {
      return this.new();
    }
  }

  calculateCase() {
    if (
      isNaN(this.digitalleversEdit.impact) ||
      this.digitalleversEdit.impact == null
    ) {
      this.digitalleversEdit.impact = 0;
    }
    if (
      isNaN(this.digitalleversEdit.minimumcasecaselever) ||
      this.digitalleversEdit.minimumcasecaselever == null
    ) {
      this.digitalleversEdit.minimumcasecaselever = 0;
    }
    if (
      isNaN(this.digitalleversEdit.realisticcasecaselever) ||
      this.digitalleversEdit.realisticcasecaselever == null
    ) {
      this.digitalleversEdit.realisticcasecaselever = 0;
    }
    if (
      isNaN(this.digitalleversEdit.bestcasecaselever) ||
      this.digitalleversEdit.bestcasecaselever == null
    ) {
      this.digitalleversEdit.bestcasecaselever = 0;
    }
    this.digitalleversEdit.minimumcase =
      Math.round(
        ((this.digitalleversEdit.impact *
          this.digitalleversEdit.minimumcasecaselever) /
          100) *
          100
      ) / 100;
    this.digitalleversEdit.realisticcase =
      Math.round(
        ((this.digitalleversEdit.impact *
          this.digitalleversEdit.realisticcasecaselever) /
          100) *
          100
      ) / 100;
    this.digitalleversEdit.bestcase =
      Math.round(
        ((this.digitalleversEdit.impact *
          this.digitalleversEdit.bestcasecaselever) /
          100) *
          100
      ) / 100;
  }
}
