// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { TemplateRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { Assessment, AssessmentModules } from '../../models/assessment.model';
import { Permission } from '../../models/permission.model';
import { Question, QuestionDTL } from '../../models/question.model';
import { Template } from '../../models/template.model';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'assessment-management',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  animations: [fadeInOut]
})
export class AssessmentComponent {
  public assessmentObj: Assessment = new Assessment();
  public assessmentModuleObj: AssessmentModules[] = [];
  public assessmentType: any[] = [];
  public moduleTree: any[] = [];
  public _obj: AssessmentModules = new AssessmentModules();

  public user: User = new User();
  columns: any[] = [];
  popupcolumns: any;
  QuestionRows: Question[] = [];
  moduleSubModules: any;
  rowsCache: Question[] = [];
  allPermissions: Permission[] = [];
  editedQuestion: Question;
  sourceQuestion: Question;
  questionName: any;
  editingQuestionName: { name: string };
  loadingIndicator: boolean;
  module: any = 'Question';
  questionObj: Question = new Question();
  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;
  public templObj: Template = new Template();
  public templList: Template[] = [];
  questionIds: number[];

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, public appComponent: AppComponent, private router: Router) {
    if (!this.appComponent.isAdmin) {
      this.loadCurrentUserData();
    }
  }

  private loadCurrentUserData() {

    if (localStorage.getItem('currentUserDetails') != null
      && typeof localStorage.getItem('currentUserDetails') != 'undefined'
      && localStorage.getItem('userModules') != null
      && typeof localStorage.getItem('userModules') != 'undefined'
      //&& localStorage.getItem('selectedModule') != null
      // && typeof localStorage.getItem('selectedModule') != 'undefined'
    ){

      this.assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
      Object.assign(this.assessmentModuleObj, JSON.parse(localStorage.getItem('userModules')));
      Object.assign(this.moduleTree, JSON.parse(localStorage.getItem('selectedModule')));

      this.assessmentType = Enumerable.from(this.assessmentModuleObj).orderBy(o => o.assessmenttype).select(s => s.assessmenttype).distinct().toArray();
    }
    else {
      this.router.navigate(['home']);
    }
  }

  loadTree(value?: any) {
    this.moduleTree = [];
    let _moduleTree = Enumerable.from(this.assessmentModuleObj).where(x => x.assessmenttype == value).toArray();

    _moduleTree.forEach(f => {

      if (Enumerable.from(this.moduleTree).where(x => x.parentValue == f.parentValue && x.sequence == f.sequence).any() == false) {
        this._obj = new AssessmentModules();

        this._obj.id = f.id;
        this._obj.parentValue = f.parentValue;
        this._obj.sequence = f.sequence;
        this._obj.questionId = f.id;
        this.moduleTree.push(this._obj);
      }
    });
  }

  save() {
    if (this.moduleTree != null && typeof (this.moduleTree) != 'undefined') {

      if (Enumerable.from(this.moduleTree).where(x => x.isChecked).any() == true) {
        localStorage.removeItem("selectedModule");
        localStorage.setItem("selectedModule", JSON.stringify(this.moduleTree));

        this.router.navigate(['questionnaires']);
      }
    }
  }

  back() {
    this.moduleTree = [];
    this.router.navigate(['privacy']);
  }

  ngOnInit() {
    //const gT = (key: string) => this.translationService.getTranslation(key);

    //this.columns = [
    // // { prop: 'index', name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
    //  { prop: 'Module Selection', name: 'Module Selection' },
    //  { name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
    //];
    //if (localStorage.getItem("currentUserDetails")) {
    //  var user = JSON.parse(localStorage.getItem("currentUserDetails")) as Assessment
    //  this.loadTemplates(user.orgId, user.templId)
    //}
  }

  loadModules(questionIds?: number[]) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.questionObj.isget = true;
    this.accountService.Set(this.module, this.questionObj).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const questions: Question[] = results;

        questions.forEach((question, index, questions) => {
          (question as any).index = index + 1;
        });

        this.rowsCache = [...questions];
        this.QuestionRows = [];
        this.QuestionRows = questions;
        if (questionIds) {
          this.moduleSubModules = []
          questionIds.forEach(q => {
            //var _moduleSubModules = Enumerable.from(this.QuestionRows).where(x => x.id == q).firstOrDefault().columndata;
            //if (_moduleSubModules) {
            //  _moduleSubModules.forEach((m, i) => {
            //    var moduleTreeObj = new QuestionTree();
            //    moduleTreeObj.id = m.id;
            //    moduleTreeObj.parentName = m.parentName;
            //    moduleTreeObj.parentValue = m.parentValue;
            //    moduleTreeObj.questionid = m.questionid;
            //    moduleTreeObj.sequence = m.sequence;
            //    this.moduleSubModules.push(moduleTreeObj)
            //  });
            //}
          });
          //if (this.moduleSubModules) {
          //  for (let i = 0; i < this.moduleSubModules.length; i++) {
          //    for (let j = i; j < this.moduleSubModules.length; j++) {
          //      if (this.moduleSubModules[i].sequence == "") {
          //        this.moduleSubModules[i].slNo = i.toString(); 
          //        if (this.moduleSubModules[i].parentValue == this.moduleSubModules[j].sequence) {
          //          this.moduleSubModules[j].slNo = i + "." + j;
          //          if()

          //          }
          //        }
          //      }
          //    }
          //  }
        }
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve Questions from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  loadTemplates(orgId?: number, templId?: number) {
    this.templList = [];
    this.templObj.id = 0;
    this.templObj.isget = true;
    this.accountService.Set('template', this.templObj).subscribe(
      results => {
        if (results) {
          this.questionIds = [];
          this.templList = results;

          if (orgId && templId) {
            this.templList = Enumerable.from(this.templList).where(x => x.clientid == orgId && x.id == templId).toArray();            

            if (this.templList) {
              this.templList.forEach(t => {
                if (t.templateDtl) {
                  t.templateDtl.forEach(d => {
                    this.questionIds.push(d.questionid);
                  });
                }
              });
              if (this.questionIds)
                this.questionIds = Enumerable.from(this.questionIds).distinct().toArray();
              this.loadModules(this.questionIds)
            }

          }
        }
      }, error => {
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  checkall(obj: AssessmentModules) {
    let _ids = Enumerable.from(this.assessmentModuleObj).where(x => x.parentValue == obj.parentValue).select(s => s.id).toArray();
   
    Enumerable.from(this.moduleTree).where(x => _ids.includes(x.id) && x.sequence >= obj.sequence).forEach(f => {
      f.isChecked = obj.isChecked;
    });
  }
}
