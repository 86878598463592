import { Component, OnInit, ViewChild } from "@angular/core";
import { ExternalUserInvite } from "src/app/models/external-user-invite.model";
import { AccountService } from "src/app/services/account.service";
import { AlertService, MessageSeverity } from "src/app/services/alert.service";
import { environment } from "src/environments/environment";
import { debug } from "util";

@Component({
  selector: "app-invite-external-user",
  templateUrl: "./invite-external-user.component.html",
  styleUrls: ["./invite-external-user.component.scss"],
})
export class InviteExternalUserComponent implements OnInit {
  public isSaving = false;
  public showValidationErrors = false;
  public user: ExternalUserInvite = new ExternalUserInvite();
  public formResetToggle = true;
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild("f", { static: false })
  public form;

  constructor(
    private alertService: AlertService,
    private accountService: AccountService
  ) {}

  ngOnInit() {}

  resetForm() {
    this.form.reset();
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  private saveSuccessHelper(user?: any) {
    const response: any = JSON.parse(user);
    if (response.status === "SUCCESS") {
      this.isSaving = false;
      this.alertService.stopLoadingMessage();

      this.user = new ExternalUserInvite();

      this.showValidationErrors = false;
      this.resetForm();

      this.alertService.showMessage(
        "Success",
        response.description,
        MessageSeverity.success
      );

      if (this.changesSavedCallback) {
        this.changesSavedCallback();
      }
    } else {
      this.saveFailedHelper(response.description);
    }
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      "Save Error",
      "The below errors occured whilst saving your changes:",
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  cancel() {
    this.user = new ExternalUserInvite();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  save() {
    
    this.user.Product = environment.idam.client_app_id;
    this.user.emailLanguage = "en";
    this.user.orgId = environment.idam.orgId;
    this.user.role = "ADMIN";
    this.user.state = "12345";
    this.isSaving = true;
    this.user.appid = environment.idam.client_app_id;
    this.user.apikey = environment.idam.client_secret;
    this.user.apiurl = environment.idam.client_host;

    this.alertService.startLoadingMessage("Saving changes...");

    // Hard coded password fields as part of integration with product IAM

    this.accountService.extUserInvitation(this.user).subscribe(
      (user) => this.saveSuccessHelper(user),
      (error) => this.saveFailedHelper(error)
    );
  }
}
