import { Component, ElementRef, ViewChild, Input } from "@angular/core";
import {
  AlertService,
  MessageSeverity,
  DialogType,
} from "../../services/alert.service";
import * as XLSX from "xlsx";
import { AccountService } from "../../services/account.service";
import { Permission } from "../../models/permission.model";
import { AppComponent } from "../app.component";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Digitalleversupload } from "src/app/models/digitalleversupload.model";

@Component({
  selector: "digitallevers-upload",
  templateUrl: "./digitallevers-upload.component.html",
  styleUrls: ["./digitallevers-upload.component.scss"],
})
export class DigitalleversUploadComponent {
  public isSaving: boolean;
  public showValidationErrors = true;
  public digitalleverupload: Digitalleversupload = new Digitalleversupload();

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  @ViewChild("f", { static: false })
  private form;

  @ViewChild("file", { static: false })
  myFileInputVar: ElementRef;

  @Input() modalCloseEvt = "";

  obj: Digitalleversupload = new Digitalleversupload();
  module: any = "DigitalleversUpload";
  public progress: number;
  protected formData: FormData;

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  ngAfterViewInit() {
    this.myFileInputVar.nativeElement.value = ""; //clear fileupload control
    this.formData = null; //clear file content.
  }

  ngOnChanges() {
    if (this.modalCloseEvt == "close") {
      this.myFileInputVar.nativeElement.value = ""; //clear fileupload control
      this.formData = null; //clear file content.
    }
  }

  convertToJson(csvData: string): string {
    const lines = csvData.split("\n");
    const result = [];
    const headers = lines[0].split("###");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split("###");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      result.push(obj);
    }
    return JSON.stringify(result);
  }

  upload(files) {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    var ext =
      fileToUpload.name.split(".")[fileToUpload.name.split(".").length - 1];

    if (ext.trim() != "xlsx" && ext.trim() != "xls") {
      this.alertService.showMessage(
        "Unsupported file",
        "Not supported file extension found",
        MessageSeverity.error
      );
      this.myFileInputVar.nativeElement.value = "";
      return false;
    }

    this.digitalleverupload.fileName = fileToUpload.name;

    this.formData = new FormData();
    const reader = new FileReader();
    reader.readAsDataURL(fileToUpload);
    reader.onload = () => {
      try {
        const base64Str = reader.result;
        const base64String = (base64Str as string).split("base64,")[1];
        const wb = XLSX.read(base64String, { type: "base64" });
        const wsname = wb.SheetNames[1];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws, { FS: "###" });
        const encoded = btoa(this.convertToJson(data));
        this.digitalleverupload.postedFile = encoded;
        this.digitalleverupload.createdby = this.accountService.currentUser.id;
        this.formData.append("postedFile", encoded);
        this.formData.append("fileName", fileToUpload.name);
        this.formData.append("createdby", this.accountService.currentUser.id);
      } catch (e) {
        this.alertService.showDialog("Excel template has been tampered.", DialogType.alert);
        this.myFileInputVar.nativeElement.value = "";
        this.formData = null;
        return false;
      }
    };
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  save() {
    const queryString = new URLSearchParams();
    queryString.set("fileName", this.digitalleverupload.fileName);
    queryString.set("createdby", this.digitalleverupload.createdby);
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (this.formData == null || typeof this.formData == "undefined") {
          this.alertService.showDialog(
            "Select a file to proceed.",
            DialogType.alert
          );
          return;
        }

        this.accountService
          .SetData(
            "DigitalLevers/ImportExcelDigitalLevers",
            this.digitalleverupload.postedFile,
            `?${queryString.toString()}`
          )
          .subscribe(
            (msg: string) => {
              this.alertService.showDialog(msg, DialogType.alert);
              if (this.changesSavedCallback) {
                this.changesSavedCallback();
              }
              this.myFileInputVar.nativeElement.value = ""; //clear fileupload control
              this.formData = null; //clear file content.
            },
            (e: any) => {
              this.myFileInputVar.nativeElement.value = ""; //clear fileupload control
              this.formData = null; //clear file content.
              let message =
                "Something went wrong. Please check the file format and try again.";
              if (Array.isArray(e.error)) {
                message =
                  `<ul style="padding: 4px 12px; max-height: 342px; overflow: auto;">` +
                  e.error.map((item) => `<li>${item}</li>`).join("") +
                  "</ul>";
              }
              this.alertService.showDialog(message, DialogType.alert);
            }
          );
      }
    );
  }

  cancel() {
    this.digitalleverupload = new Digitalleversupload();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }
}
