import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Assessment, AssessmentQuestions } from '../../models/assessment.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { AppComponent } from '../app.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { parseHTML } from 'jquery';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'completed-management',
  templateUrl: './completed-management.component.html',
  styleUrls: ['./completed-management.component.scss'],
  providers: [DatePipe],
  animations: [fadeInOut]
})
export class CompletedManagementComponent implements OnInit {
  module: any = "Assessment";
  columns: any[] = [];
  public isSaving: boolean;
  public showValidationErrors = true;
  public selectedValues: { [key: string]: boolean; } = {};
  public formResetToggle = true;
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;
  loadingIndicator: boolean;
  objtemp: Assessment = new Assessment();
  objtempList: any;
  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;
  @ViewChild('assessmentmodal', { static: true })
  assessmentmodal: ModalDirective;
  obj: Assessment = new Assessment();
  rows: Assessment[] = [];
  rowsCache: Assessment[] = [];
  results: any;
  userResponseList: AssessmentQuestions[] = [];
  currentModule: any;

  constructor(private alertService: AlertService, private accountService: AccountService, private router: Router, public appComponent: AppComponent, private translationService: AppTranslationService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      { prop: 'index', name: '#', cellTemplate: this.indexTemplate, canAutoResize: false },
      { prop: 'tempName', name: 'Template Name' },
      { prop: 'fullName', name: 'Full Name' },
      { prop: 'userName', name: 'User Name' },
      { prop: 'updatedDate', name: 'completed date' },

      { name: '', cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false },
    ];

    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.obj.isget = true;
    this.obj.userId = this.accountService.currentUser.id;
    this.obj.isdatabyuser = "1";
    this.accountService.Set(this.module, this.obj).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        const allcompassessmnt = results as any
        allcompassessmnt.forEach((allcompassessmnts, index, allcompassessmnt) => {
          (allcompassessmnts as any).index = index + 1;
          if ((allcompassessmnts as any).updatedDate == "0001-01-01")
            allcompassessmnts.updatedDate = null;
          (allcompassessmnts as any).updatedDate = this.datePipe.transform((allcompassessmnts as any).updatedDate, 'MM-dd-yyyy hh:mm:ss a');
        });

        this.rowsCache = [...allcompassessmnt];
        this.rows = allcompassessmnt;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.tempName, r.fullname, r.username));
  }

  viewAssessment(id) {
    localStorage.setItem('reportassessmentId', id);
    this.router.navigate(['reportassessment']);
  }

  back() {
    this.router.navigate(['home']);
  }
}
