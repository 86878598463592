import { Component, ViewChild } from '@angular/core';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AccountService } from '../../services/account.service';
import { Permission } from '../../models/permission.model';
import { AppComponent } from '../app.component';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { Opportunities } from '../../models/opportunities.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'opportunities-editor',
  templateUrl: './opportunities-editor.component.html',
  styleUrls: ['./opportunities-editor.component.scss'],
  providers: [DatePipe]
})

export class OpportunitiesEditorComponent {
  
  private isNew = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public opportunitiesEdit: Opportunities = new Opportunities();
  private editingOpportunities: string;

  public formResetToggle = true;

  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;


  @ViewChild('f', { static: false })
  private form;

  obj: Opportunities = new Opportunities;
  module: any = 'Opportunities';

  constructor(private alertService: AlertService, private accountService: AccountService, public appComponent: AppComponent, private datePipe: DatePipe) {
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }
  
  save() {
    this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {
      if (this.opportunitiesEdit.digitalkpi == null || typeof this.opportunitiesEdit.digitalkpi == 'undefined' || this.opportunitiesEdit.digitalkpi.length == 0) {
        this.alertService.showDialog('Digital KPI is required.', DialogType.alert);
        return;
      }
      this.opportunitiesEdit.createdby = this.accountService.currentUser.id;
      this.opportunitiesEdit.isdeleted = false;
      this.opportunitiesEdit.isget = false;
      this.opportunitiesEdit.timestamp = new Date();
      this.accountService.Set(this.module, this.opportunitiesEdit).subscribe(
        () => {
          this.alertService.showDialog('Saved Successfully.', DialogType.alert);
          this.opportunitiesEdit = new Opportunities();
          this.resetForm();

          if (this.changesSavedCallback) {
            this.changesSavedCallback();
          }
        },
        error => {
          this.appComponent.failedHelper(error);
        });
    });
  }

  cancel() {
    this.opportunitiesEdit = new Opportunities();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage('Cancelled', 'Operation cancelled by user', MessageSeverity.default);
    this.alertService.resetStickyMessage();

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  resetForm(replace = false) {

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  new() {
    this.isNew = true;
    this.showValidationErrors = true;

    this.editingOpportunities = null;
    this.opportunitiesEdit = new Opportunities();

    return this.opportunitiesEdit;
  }

  edit(opportunities: Opportunities) {
    if (opportunities) {
      this.isNew = false;
      this.showValidationErrors = true;

      this.editingOpportunities = opportunities.digitalkpi;

      this.opportunitiesEdit = new Opportunities();
      Object.assign(this.opportunitiesEdit, opportunities);

      if (this.opportunitiesEdit.timestamp == "0001-01-01")
        this.opportunitiesEdit.timestamp = null;
      this.opportunitiesEdit.timestamp = this.datePipe.transform(this.opportunitiesEdit.timestamp, 'MM-dd-yyyy hh:mm:ss a');

      return this.opportunitiesEdit;
    } else {
      return this.new();
    }
  }

  calculatePotential() {
    if (isNaN(this.opportunitiesEdit.currentlevel) || this.opportunitiesEdit.currentlevel == null) {
      this.opportunitiesEdit.currentlevel = 0;
    }
    if (isNaN(this.opportunitiesEdit.industrylevel) || this.opportunitiesEdit.industrylevel == null) {
      this.opportunitiesEdit.industrylevel = 0;
    }
    if (isNaN(this.opportunitiesEdit.bestcaselevel) || this.opportunitiesEdit.bestcaselevel == null) {
      this.opportunitiesEdit.bestcaselevel = 0;
    }
    this.opportunitiesEdit.potential = Math.round((((this.opportunitiesEdit.industrylevel + this.opportunitiesEdit.bestcaselevel) / 2) - this.opportunitiesEdit.currentlevel) * 100) / 100;
  }
}
