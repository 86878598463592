export class Auditentity {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(createdby?: any, createdate?: any, updatedby?: any, updateddate?: any, isdeleted?: any, isget?: boolean, isSubmit?: boolean) {
    this.createdby = createdby;
    this.createdate = createdate;
    this.updatedby = updatedby;
    this.updateddate = updateddate;
    this.isdeleted = isdeleted;
    this.isget = isget;
    this.isSubmit = isSubmit;
  }

  public createdby: any;
  public createdate: any;
  public updatedby: any;
  public updateddate: any;
  public isdeleted: any;
  public isget: boolean;
  public isSubmit: boolean;
}
