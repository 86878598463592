// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ToastaModule } from 'ngx-toasta';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CarouselModule } from 'ngx-bootstrap/carousel';
// import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { ThemeManager } from './services/theme-manager';
import { LocalStoreManager } from './services/local-store-manager.service';
import { AuthStorage } from './services/auth-storage';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';

import { EqualValidator } from './directives/equal-validator.directive';
import { LastElementDirective } from './directives/last-element.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import { BootstrapToggleDirective } from './directives/bootstrap-toggle.directive';
import { BootstrapSelectDirective } from './directives/bootstrap-select.directive';
import { BootstrapDatepickerDirective } from './directives/bootstrap-datepicker.directive';
import { GroupByPipe } from './pipes/group-by.pipe';

import { AppComponent } from './components/app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { BannerDemoComponent } from './components/controls/banner-demo.component';
import { TodoDemoComponent } from './components/controls/todo-demo.component';
import { StatisticsDemoComponent } from './components/controls/statistics-demo.component';
import { NotificationsViewerComponent } from './components/controls/notifications-viewer.component';
import { SearchBoxComponent } from './components/controls/search-box.component';
import { UserInfoComponent } from './components/controls/user-info.component';
import { UserPreferencesComponent } from './components/controls/user-preferences.component';
import { UsersManagementComponent } from './components/controls/users-management.component';
import { RolesManagementComponent } from './components/controls/roles-management.component';
import { RoleEditorComponent } from './components/controls/role-editor.component';
import { ClientManagementComponent } from './components/client/client-management.component';
import { ClientEditorComponent } from './components/client/client-editor.component';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { QuestionEditorComponent } from './components/question/question-editor.component';
import { QuestionManagementComponent } from './components/question/question-management.component';
import { TemplateEditorComponent } from './components/template/template-editor.component';
import { TemplateManagementComponent } from './components/template/template-management.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { QuestionnairesComponent } from './components/questionnaires/questionnaires.component';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { DataTableModule } from "angular-6-datatable";
import { HighchartsChartModule } from 'highcharts-angular';
import { CompletedManagementComponent } from './components/completed/completed-management.component';
import { OpportunitiesEditorComponent } from './components/opportunities/opportunities-editor.component';
import { OpportunitiesManagementComponent } from './components/opportunities/opportunities-management.component';
import { DigitalleversEditorComponent } from './components/digitallevers/digitallevers-editor.component';
import { DigitalleversManagementComponent } from './components/digitallevers/digitallevers-management.component';
import { DigitalleversNewEditorComponent } from './components/digitallevers-with-upload/digitallevers-editor.component';
import { DigitalleversNewManagementComponent } from './components/digitallevers-with-upload/digitallevers-management.component';
import { DigitalleversUploadComponent } from './components/digitallevers-with-upload/digitallevers-upload.component';
import { ReportqualitativecontentComponent } from './components/reportqualitativecontent/reportqualitativecontent.component';
import { ReportqualitativechartComponent } from './components/reportqualitativechart/reportqualitativechart.component';
import { ReportbaselinedataComponent } from './components/reportbaselinedata/reportbaselinedata.component';
import { ReportrelevantvaluepoolsComponent } from './components/reportrelevantvaluepools/reportrelevantvaluepools.component';
import { ReportdigitizationleverscontentComponent } from './components/reportdigitizationleverscontent/reportdigitizationleverscontent.component';
import { ReportdigitizationleverschartComponent } from './components/reportdigitizationleverschart/reportdigitizationleverschart.component';
import { ReportprioritizationframeworkComponent } from './components/reportprioritizationframework/reportprioritizationframework.component';
import { UserRegistrationComponent } from './components/controls/user-registration.component';
import { UserForgetPasswordComponent } from './components/controls/user-forget-password.component';
import { UserTemplateComponent } from './components/controls/user-template.component';
import { ReportassessmentComponent } from './components/reportassessment/reportassessment.component';
import { ReportqualitativecontentprintComponent } from './components/reportassessment/reportqualitativecontentprint.component';
import { ReportqualitativechartprintComponent } from './components/reportassessment/reportqualitativechartprint.component';
import { ReportbaselinedataprintComponent } from './components/reportassessment/reportbaselinedataprint.component';
import { ReportrelevantvaluepoolsprintComponent } from './components/reportassessment/reportrelevantvaluepoolsprint.component';
import { ReportdigitizationleverscontentprintComponent } from './components/reportassessment/reportdigitizationleverscontentprint.component';
import { ReportdigitizationleverschartprintComponent } from './components/reportassessment/reportdigitizationleverschartprint.component';
import { ReportprioritizationframeworkprintComponent } from './components/reportassessment/reportprioritizationframeworkprint.component';
import { InviteExternalUserComponent } from './components/controls/invite-external-user/invite-external-user.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    NgxDatatableModule,
    OAuthModule.forRoot(),
    ToastaModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    // ChartsModule,
    NgxUiLoaderModule, NgxUiLoaderRouterModule.forRoot({ showForeground: true }), NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    DataTableModule,
    HighchartsChartModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SettingsComponent,
    UsersManagementComponent, UserForgetPasswordComponent, UserInfoComponent, UserPreferencesComponent,
    RolesManagementComponent, RoleEditorComponent,
    AboutComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    SearchBoxComponent,
    StatisticsDemoComponent, TodoDemoComponent, BannerDemoComponent,
    EqualValidator,
    LastElementDirective,
    AutofocusDirective,
    BootstrapTabDirective,
    BootstrapToggleDirective,
    BootstrapSelectDirective,
    BootstrapDatepickerDirective,
    GroupByPipe,
    ClientManagementComponent, ClientEditorComponent,
    PrivacyComponent,
    QuestionEditorComponent, QuestionManagementComponent,
    TemplateEditorComponent, TemplateManagementComponent,
    AssessmentComponent, QuestionnairesComponent,
    CompletedManagementComponent,
    OpportunitiesEditorComponent, OpportunitiesManagementComponent,
    DigitalleversEditorComponent, DigitalleversManagementComponent,
    DigitalleversNewEditorComponent, DigitalleversNewManagementComponent, DigitalleversUploadComponent,
    ReportqualitativecontentComponent, ReportqualitativechartComponent, ReportbaselinedataComponent,
    ReportrelevantvaluepoolsComponent, ReportdigitizationleverscontentComponent,
    ReportdigitizationleverschartComponent, ReportprioritizationframeworkComponent,
    UserRegistrationComponent,
    UserTemplateComponent, ReportassessmentComponent,
    ReportqualitativecontentprintComponent, ReportqualitativechartprintComponent,
    ReportbaselinedataprintComponent, ReportrelevantvaluepoolsprintComponent,
    ReportdigitizationleverscontentprintComponent, ReportdigitizationleverschartprintComponent,
    ReportprioritizationframeworkprintComponent,
    InviteExternalUserComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: OAuthStorage, useClass: AuthStorage },
    AlertService,
    ThemeManager,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents: [UserForgetPasswordComponent]
})
export class AppModule {
}
