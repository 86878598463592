import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'user-forget-password',
  templateUrl: './user-forget-password.component.html',
  styleUrls: ['./user-forget-password.component.scss']
})

export class UserForgetPasswordComponent implements OnInit {

  /*
   * 
   * Constructor function ()
   * @param modalRef?: BsModalRef
   *
   */
  constructor(public modalRef?: BsModalRef) { }

  /*
   *
   * Lifecycle method ()
   * @method ngOnInit - Runs while class intialize.
   *
   */
  ngOnInit() { }

}
