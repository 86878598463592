// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Component, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { Client } from '../../models/client.model';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { Assessment, AssessmentModules, AssessmentQuestions, AssessmentResponseOptions } from '../../models/assessment.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Template } from '../../models/template.model';
import { ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { UserTemplateComponent } from '../../components/controls/user-template.component';
import { ExternalUserInvite } from '../../models/external-user-invite.model';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeInOut]
})
export class HomeComponent {
  public allRoles: Role[] = [];
  public orgList: Client[] = [];
  public clientObj: Client = new Client();
  public roleslist: Role[] = [];
  public templateObj: Template = new Template();
  public assessmentObj: Assessment = new Assessment();
  public assessmentModuleObj: AssessmentModules = new AssessmentModules();
  public questionWithResponseList: AssessmentQuestions[] = [];
  public questionWithResponseListCache: AssessmentQuestions[] = [];
  template: any;
  organisation: any;
  isAdmin: boolean;
  industrySectorDisabled: boolean = false;
  defaultTemplateScore: any;
  defaultTemplateObject: any;
  module = "template";
  @ViewChild('detailsModal', { static: false })
  detailsModal: ModalDirective;

  constructor(public configurations: ConfigurationService, private accountService: AccountService, private alertService: AlertService, private router: Router, private appComponent: AppComponent, private modalService: BsModalService) {
    $(document).ready(function () {
      // video tab
      $(".langVersion").find("a").removeClass("active");
      $(".langVersion").find("a").eq(0).addClass("active");
      $(".videoSec").find("iframe").hide();
      $(".videoSec").find("iframe").eq(0).show();
      $(".langVersion").find("a").eq(0).click(function () {
        $(".langVersion").find("a").removeClass("active");
        $(".langVersion").find("a").eq(0).addClass("active");
        $(".videoSec").find("iframe").hide();
        $(".videoSec").find("iframe").eq(0).show();
      });
      $(".langVersion").find("a").eq(1).click(function () {
        $(".langVersion").find("a").removeClass("active");
        $(".langVersion").find("a").eq(1).addClass("active");
        $(".videoSec").find("iframe").hide();
        $(".videoSec").find("iframe").eq(1).show();
      });
      // view more
      $(".viewMore").show();
      $(".viewLess, .viewMoreSec").hide();
      $(".viewMore").click(function () {
        $(".viewLess, .viewMoreSec").show("slow");
        $(".viewMore").hide();
      });
      $(".viewLess").click(function () {
        $(".viewLess, .viewMoreSec").hide("fast");
        $(".viewMore").show();
      });
    });
  }

  ngOnInit() {
    if (!this.appComponent.isAdmin) {
      this.loadCurrentUserData();
    }
  }

  setDefaultScore() {
    
    this.defaultTemplateScore = this.templateObj.defaultScore;
    this.defaultTemplateObject = JSON.parse(this.assessmentObj.userResponses);

    if (this.defaultTemplateObject === null) {
      let objQuestions = JSON.parse((JSON.parse(localStorage.getItem("currentTemplateDetails")) as Template).questionStructure);
      if (objQuestions) {
        objQuestions.forEach(f => {
          if (f.isChecked) {
            let objQuestion = new AssessmentQuestions();
            objQuestion.customdbid = f.customdbid;
            objQuestion.questiontext = f.questiontext;
            objQuestion.questionCategory = f.questionCategory;
            objQuestion.weightage = f.weightage;
            objQuestion.benchmarkValue = f.benchmarkValue;
            objQuestion.maturitylevel1Desc = f.maturitylevel1;
            objQuestion.maturitylevel2Desc = f.maturitylevel2;
            objQuestion.maturitylevel3Desc = f.maturitylevel3;
            objQuestion.maturitylevel4Desc = f.maturitylevel4;
            objQuestion.maturitylevel5Desc = f.maturitylevel5;
            objQuestion.columndata = f.columndata;
            objQuestion.response = '0';
            objQuestion.responsetarget = '0';
            objQuestion.responseOptions = [];
            if (f.questionCategory == 'MC' || f.questionCategory == 'MS') {
              if (f.maturitylevel1 != null && typeof f.maturitylevel1 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 1;
                if (f.maturitylevel1.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel1;
                  objOption.responseValue = 1;
                }
                else {
                  try {
                    var opt = f.maturitylevel1.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel1;
                    objOption.responseValue = 1;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel2 != null && typeof f.maturitylevel2 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 2;
                if (f.maturitylevel2.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel2;
                  objOption.responseValue = 2;
                }
                else {
                  try {
                    var opt = f.maturitylevel2.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel2;
                    objOption.responseValue = 2;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel3 != null && typeof f.maturitylevel3 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 3;
                if (f.maturitylevel3.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel3;
                  objOption.responseValue = 3;
                }
                else {
                  try {
                    var opt = f.maturitylevel3.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel3;
                    objOption.responseValue = 3;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel4 != null && typeof f.maturitylevel4 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 4;
                if (f.maturitylevel4.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel4;
                  objOption.responseValue = 4;
                }
                else {
                  try {
                    var opt = f.maturitylevel4.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel4;
                    objOption.responseValue = 4;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
              if (f.maturitylevel5 != null && typeof f.maturitylevel5 != 'undefined') {
                let objOption = new AssessmentResponseOptions();
                objOption.maturityLevel = 5;
                if (f.maturitylevel5.indexOf('(') == -1) {
                  objOption.responseOption = f.maturitylevel5;
                  objOption.responseValue = 5;
                }
                else {
                  try {
                    var opt = f.maturitylevel5.split('(');
                    var popped = opt.pop();
                    objOption.responseOption = opt.join('(');
                    objOption.responseValue = parseFloat(popped);
                  }
                  catch {
                    objOption.responseOption = f.maturitylevel5;
                    objOption.responseValue = 5;
                  }
                }
                objOption.isChecked = false;
                objQuestion.responseOptions.push(objOption);
              }
            }
            this.questionWithResponseListCache.push(objQuestion);
          }
        });
        this.defaultTemplateObject = this.questionWithResponseListCache;
      }
      else {
        this.alertService.showDialog('No question associated with this assessment template.<br> Please connect with the administrator.', DialogType.alert);
        return;
      }
    } else {
      this.defaultTemplateObject = JSON.parse(this.assessmentObj.userResponses);
    }


    this.defaultTemplateObject.forEach((questions, index) => {
      if (this.defaultTemplateScore) {

        this.defaultTemplateObject[index].response = (this.defaultTemplateScore / 100) * this.defaultTemplateObject[index].benchmarkValue;
      }
    });

    this.assessmentObj.isget = false;
    this.assessmentObj.userId = this.accountService.currentUser.id;
    this.assessmentObj.createdby = this.accountService.currentUser.id;
    this.assessmentObj.userResponses = JSON.stringify(this.defaultTemplateObject);


    // save default score
    this.accountService.Set('assessment', this.assessmentObj).subscribe(
      result => {
        if (result) {
          this.assessmentObj = new Assessment();
          Object.assign(this.assessmentObj, result);
          localStorage.setItem("currentUserDetails", JSON.stringify(result));

          // finally route to report section
          this.router.navigate(['reportqualitativecontent']);
        }
      },
      error => {
        this.appComponent.failedHelper(error);
      });


  }

  loadCurrentUserData() {
    this.templateObj.id = this.accountService.currentUser.templId;
    if (this.templateObj.id != null) {
      this.templateObj.isget = true;
      this.accountService.Set('loginhandler/gettemplates', this.templateObj).subscribe(
        result => {
          if (result) {
            this.templateObj = new Template();
            Object.assign(this.templateObj, result);
            localStorage.setItem("currentTemplateDetails", JSON.stringify(result));
            this.assessmentObj.sector = this.templateObj.industrySector;
            if (this.templateObj.industrySector) {
              this.industrySectorDisabled = true;

            }
          }
        },
        error => {
          this.appComponent.failedHelper(error);
        });
    }



    if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined') {
      this.assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
    }
    else {
      this.organisation = this.accountService.currentUser.jobTitle;
      this.assessmentObj.userId = this.accountService.currentUser.id;
      this.assessmentObj.isget = true;
      this.accountService.Set('assessment', this.assessmentObj).subscribe(
        result => {
          if (result) {
            this.assessmentObj = new Assessment();
            this.assessmentModuleObj = new AssessmentModules();
            Object.assign(this.assessmentObj, result);
            if (this.assessmentObj.id == 0) {
              this.assessmentObj.sector = "";
              this.assessmentObj.subSector = "";
              this.assessmentObj.role = "";
              this.assessmentObj.assessmentType = "";
            }
            this.assessmentObj.orgId = this.accountService.currentUser.orgId;
            this.assessmentObj.templId = this.accountService.currentUser.templId;

            localStorage.setItem("currentUserDetails", JSON.stringify(result));
          }
        },
        error => {
          this.appComponent.failedHelper(error);
        });
    }
  }

  save() {
    if (this.assessmentObj) {
      if (this.assessmentObj.sector == null || typeof (this.assessmentObj.sector) == 'undefined' || this.assessmentObj.sector.length == 0) {
        this.alertService.showDialog('Industry sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.subSector == null || typeof (this.assessmentObj.subSector) == 'undefined' || this.assessmentObj.subSector.length == 0) {
        this.alertService.showDialog('Sub-sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.role == null || typeof (this.assessmentObj.role) == 'undefined' || this.assessmentObj.role.length == 0) {
        this.alertService.showDialog('Role is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.assessmentType == null || typeof (this.assessmentObj.assessmentType) == 'undefined' || this.assessmentObj.assessmentType.length == 0) {
        this.alertService.showDialog('Assessment type is mandatory.', DialogType.alert);
        return;
      }

      this.assessmentObj.isget = false;
      this.assessmentObj.userId = this.accountService.currentUser.id;
      this.assessmentObj.createdby = this.accountService.currentUser.id;


      if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined') {
        this.assessmentObj.plStructure = (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).plStructure;
        this.assessmentObj.assumptionStructure = (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).assumptionStructure;
      }

      this.accountService.Set("assessment", this.assessmentObj).subscribe(
        results => {
          localStorage.setItem("currentUserDetails", JSON.stringify(results));
          localStorage.setItem("userModules", JSON.stringify(this.assessmentModuleObj));

          if (!this.assessmentObj.isSubmitted) {
            if (!this.assessmentObj.isAcceptedPrivacy) {
              this.router.navigate(['privacy']);
            }
            else {
              this.router.navigate(['questionnaires']);
            }
          }
          else {
            this.router.navigate(['reportqualitativecontent']);
          }
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }

  completed() {
    this.router.navigate(['completed']);
  }

  showDetailsModal() {
    this.organisation = this.accountService.currentUser.jobTitle;
    this.assessmentObj.userId = this.accountService.currentUser.id;
    this.assessmentObj.isget = true;
    this.accountService.Set('assessment', this.assessmentObj).subscribe(
      result => {
        if (result) {
          this.assessmentObj = new Assessment();
          this.assessmentModuleObj = new AssessmentModules();
          Object.assign(this.assessmentObj, result);
          if (this.assessmentObj.id == 0) {
            this.assessmentObj.sector = "";
            this.assessmentObj.subSector = "";
            this.assessmentObj.role = "";
            this.assessmentObj.assessmentType = "";
          }
          this.assessmentObj.orgId = this.accountService.currentUser.orgId;
          this.assessmentObj.templId = this.accountService.currentUser.templId;
          this.assessmentObj.sector = this.templateObj.industrySector;

          localStorage.setItem("currentUserDetails", JSON.stringify(result));
          this.detailsModal.show();
        }
      },
      error => {
        this.appComponent.failedHelper(error);
      });


  }

  editUserTemplate() {
    if (this.assessmentObj) {
      if (this.assessmentObj.sector == null || typeof (this.assessmentObj.sector) == 'undefined' || this.assessmentObj.sector.length == 0) {
        this.alertService.showDialog('Industry sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.subSector == null || typeof (this.assessmentObj.subSector) == 'undefined' || this.assessmentObj.subSector.length == 0) {
        this.alertService.showDialog('Sub-sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.role == null || typeof (this.assessmentObj.role) == 'undefined' || this.assessmentObj.role.length == 0) {
        this.alertService.showDialog('Role is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.assessmentType == null || typeof (this.assessmentObj.assessmentType) == 'undefined' || this.assessmentObj.assessmentType.length == 0) {
        this.alertService.showDialog('Assessment type is mandatory.', DialogType.alert);
        return;
      }

      if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined') {
        this.assessmentObj.plStructure = (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).plStructure;
        this.assessmentObj.assumptionStructure = (JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).assumptionStructure;
      }

      this.assessmentObj.isget = false;
      this.assessmentObj.templId = this.accountService.currentUser.templId;
      this.assessmentObj.userId = this.accountService.currentUser.id;
      this.assessmentObj.createdby = this.accountService.currentUser.id;

      this.accountService.Set("assessment", this.assessmentObj).subscribe(
        results => {
          localStorage.setItem("currentUserDetails", JSON.stringify(results));
          localStorage.setItem("userModules", JSON.stringify(this.assessmentModuleObj));

          this.detailsModal.hide();
          this.modalService.show(UserTemplateComponent);
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }



  //InviteUser() {
    
  //  let externaluser = new ExternalUserInvite();
  //  externaluser.email = "mribasu@gmail.com";
  //  externaluser.orgId = "d8602888-f5c6-4ae2-94b6-f1a7f54c343f";
  //  externaluser.Product = "deals_technology_platform_dev";
  //  externaluser.role = "ADMIN";
  //  externaluser.state = "123456";
  //  externaluser.emailLanguage = "en";
  //  //externaluser.requestingUserEmail = "mrinmoy.basu@pwc.com";


  //  this.accountService.SetData<string>("IAMExternalUserInvite", externaluser).subscribe(
  //    result => {
  //      if (result != '' && result != null) {
        

  //      }
  //    },
  //    error => {
  //      localStorage.removeItem("idamcode");
  //      this.alertService.showStickyMessage('Load Error', 'Unable to retrieve data from the server.\r\nErrors: "' + Utilities.getHttpResponseMessages(error),
  //        MessageSeverity.error, error);
  //    });
  //}

}
