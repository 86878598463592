import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppComponent } from '../app.component';
import { AssessmentQuestions, Assessment } from '../../models/assessment.model';
import { Template } from '../../models/template.model';
import { AccountService } from '../../services/account.service';
import { AuthService } from '../../services/auth.service';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import { Router } from '@angular/router';
import { DigitalLevers } from '../../models/digitallevers.model';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'reportprioritizationframework-management',
  templateUrl: './reportprioritizationframework.component.html',
  styleUrls: ['./reportprioritizationframework.component.scss'],
  animations: [fadeInOut]
})

export class ReportprioritizationframeworkComponent implements OnInit {
  moduleTreeCache: any[] = [];
  moduleTree: any[] = [];
  temp = {};
  questionWithResponseList: any[] = [];
  currentAssessment: Assessment = new Assessment();
  obj: AssessmentQuestions = new AssessmentQuestions();
  generateReportFlag: number = 0;
  responseData: any = [];
  Highcharts = Highcharts;
  overallScore: number = 0;
  isQuantitative: boolean;
  templInput: any = [];
  waterfallData: any = [];
  rptData: any;
  digitalleversobj: DigitalLevers = new DigitalLevers();
  public digitalleversalldata: DigitalLevers[] = [];
  highVal: number = 0;
  midVal: number = 0;
  lowVal: number = 0;
  leverList: any = [];
  @ViewChild('detailsModal', { static: false })
  detailsModal: ModalDirective;
  modalData: any = [];
  flag: boolean = false;

  chartOptionsScatter = {
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    xAxis: {
      //gridLineWidth: 1,
      title: {
        enabled: true,
        text: 'Overall risk for impact realisation'
      },
      labels: {
        formatter: function () {
          return this.value + '%';
        }
      },
      //startOnTick: true,
      //endOnTick: true,
      showLastLabel: true,
    },
    yAxis: {
      title: {
        text: 'Impact value realistic'
      },
      labels: {
        formatter: function () {
          return '€' + Highcharts.numberFormat(Math.abs(this.value), 1, '.', ',') + 'M';
        }
      },
      showLastLabel: true,
    },
    legend: {
      enabled: false
    },
    series: [],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function (e) { },
          }
        },
      }
    }
  }

  constructor(private alertService: AlertService, public appComponent: AppComponent, private accountService: AccountService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.loadAssessment();
    this.loadResponses();
    this.loadDigitalLevers();
  }

  nestedToflatJSON(obj: any) {
    if (obj.ischecked) {
      if (obj.children.length > 0) {
        obj.children.forEach(c => {
          this.temp = {};
          this.temp[obj.prop] = obj.name;
          this.temp[c.prop] = c.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(c);
        });
      }
    }
  }

  loadAssessment() {
    if (localStorage.getItem('currentTemplateDetails') != null && typeof localStorage.getItem('currentTemplateDetails') != 'undefined') {
      this.currentAssessment = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
      this.questionWithResponseList = JSON.parse((JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment).userResponses);
      this.moduleTreeCache = JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).treeStructure);
      this.isQuantitative = (JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).isQuantitative;

      this.moduleTreeCache.forEach(f => {
        if (f.ischecked) {
          this.temp = {};
          this.temp[f.prop] = f.name;
          this.moduleTree.push(this.temp);
          this.nestedToflatJSON(f);
        }
      });

      this.generateReportFlag = 0
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.response == 0 && (q.other == null || typeof q.other == 'undefined' || q.other == '')) {
            this.generateReportFlag++;
          }
        });
      }
    }
  }

  loadResponses() {
    this.responseData = [];
    let questionStructure = Enumerable.from(JSON.parse((JSON.parse(localStorage.getItem('currentTemplateDetails')) as Template).questionStructure)).select(s => s['columndata']);

    this.moduleTree.forEach(f => {
      if (Object.keys(f).length == 1) {
        let desc = Enumerable.from(this.moduleTreeCache).where(x => x.name == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();
        this.responseData.push({ short: '', full: f[Object.keys(f)[0]], xxx: 0, xxxtarget: 0, target: 0, total: 0, dimension: '', checked: false, showmore: false, description: desc });
      }
      if (this.questionWithResponseList) {
        this.questionWithResponseList.forEach(q => {
          if (q.columndata != null && typeof q.columndata != 'undefined') {
            if (f[Object.keys(f)[0]] == q.columndata[Object.keys(f)[0]]) {
              if (typeof q.columndata[Object.keys(f)[1]] != 'undefined') {
                let short = '';
                if (Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).any()) {
                  short = Enumerable.from(questionStructure).where(x => x[Object.keys(f)[1]] == q.columndata[Object.keys(f)[1]]).firstOrDefault().Abbreviation;
                }
                if (!Enumerable.from(this.responseData).where(x => x['full'] == q.columndata[Object.keys(f)[0]]).any()) {
                  var response = 0, responsecount = 0, responsetarget = 0, responsetargetcount = 0, benchmark = 0, benchmarkcount = 0;
                  this.questionWithResponseList.forEach(s => {
                    if (s.columndata != null && typeof s.columndata != 'undefined') {
                      if (s.columndata['Sub-Dimension'] == q.columndata[Object.keys(f)[0]]) {
                        response += parseFloat(s.response);
                        responsecount++;
                        responsetarget += parseFloat(s.responsetarget);
                        responsetargetcount++;
                        benchmark += parseFloat(s.benchmarkValue);
                        benchmarkcount++;
                      }
                    }
                  });
                  let subdim = Enumerable.from(this.moduleTreeCache).where(x => x.name == q.columndata['Dimension']).select(y => y["children"]).firstOrDefault();
                  let desc = Enumerable.from(subdim).where(x => x['name'] == f[Object.keys(f)[0]]).select(y => y["description"]).firstOrDefault();

                  let xxx = 0, xxxtarget = 0, target = 0;
                  try {
                    if (!isNaN(response) && !isNaN(responsecount))
                      xxx = response / responsecount;
                    else
                      xxx = 0;
                    if (!isNaN(responsetarget) && !isNaN(responsetargetcount))
                      xxxtarget = responsetarget / responsetargetcount;
                    else
                      xxxtarget = 0;
                    if (!isNaN(benchmark) && !isNaN(benchmarkcount))
                      target = benchmark / benchmarkcount;
                    else
                      target = 0;
                  }
                  catch {
                    xxx = 0;
                    xxxtarget = 0;
                    target = 0;
                  }

                  this.responseData.push({ short: short, full: q.columndata[Object.keys(f)[0]], xxx: xxx, xxxtarget: xxxtarget, target: target, total: 0, dimension: q.columndata['Dimension'], checked: false, showmore: false, description: desc });
                }
              }
            }
          }
        });
      }
    });

    this.responseData.forEach((data, index, datas) => {
      (data as any).index = index + 1;
    });

    let dimcount = 0, dimscore = 0;

    this.responseData.forEach(f => {
      if (f.short != '') {
        try {
          if (!isNaN(f.xxx) && !isNaN(f.target))
            f.total = Math.round((f.xxx / f.target) * 100);
          else
            f.total = 0;
        }
        catch {
          f.total = 0;
        }
      }
      else {
        let nextIndex = Enumerable.from(this.responseData).max(m => m['index']) + 1;

        if (Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').any()) {
          nextIndex = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['short'] == '').firstOrDefault()['index'];
        }

        var dimensionAverage = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxx']);
        var dimensionAverageTarget = Enumerable.from(this.responseData).where(x => x['index'] > f['index'] && x['index'] < nextIndex).average(a => a['xxxtarget']);

        try {
          if (!isNaN(dimensionAverage) && !isNaN(dimensionAverageTarget)) {
            f.xxx = dimensionAverage;
            f.xxxtarget = dimensionAverageTarget;
            f.target = 5;
            f.total = Math.round((dimensionAverage / 5) * 100);
          }
          else {
            f.xxx = 0;
            f.xxxtarget = 0;
            f.target = 0;
            f.total = 0;
          }
        }
        catch {
          f.xxx = 0;
          f.xxxtarget = 0;
          f.target = 0;
          f.total = 0;
        }

        dimscore += f.total;
        dimcount++;
      }
    });

    this.overallScore += Math.round(dimscore / dimcount);
  }

  loadDigitalLevers() {
    this.digitalleversobj.isget = true;
    this.accountService.Set('loginhandler/getdigitallever', this.digitalleversobj).subscribe(
      results => {
        if (results) {
          Object.assign(this.digitalleversalldata, results);
          this.loadReportData();
          this.flag = true;
          this.loadScatterChartData();
        }
      },
      error => {
        this.appComponent.failedHelper(error);
      });
  }

  loadReportData() {
    let templpl: any;

    if (this.currentAssessment.plStructure != null) {
      templpl = JSON.parse(this.currentAssessment.plStructure);
    }
    else if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['plStructure']) {
      let templpl = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['plStructure']);
    }

    if (templpl != null) {
      templpl.children01.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children02.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children03.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children04.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children05.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children06.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children07.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children08.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children09.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
      templpl.children10.forEach(f => {
        if (f.name != null && typeof f.name != 'undefined' && f.name != '' && f.value1 != null && typeof f.value1 != 'undefined' && f.value1 != '' && !isNaN(f.value1))
          this.templInput.push({ name: f.name, value: f.value1, format: 'Currency(M€)' });
      });
    }
    let templassumption: any;
    if (this.currentAssessment.assumptionStructure != null) {
      templassumption = JSON.parse(this.currentAssessment.assumptionStructure);
      templassumption.forEach(f => {
        this.templInput.push({ name: f.kpi, value: f.value, format: 'Currency(M€)' });
      });
    }
    else if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['assumptionStructure']) {
      templassumption = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['assumptionStructure']);
      templassumption.forEach(f => {
        this.templInput.push({ name: f.kpi, value: f.value, format: 'Currency(M€)' });
      });
    }
    if (this.digitalleversalldata) {
      this.digitalleversalldata.forEach(f => {
        this.templInput.push({ name: f.lever + " | Minimum case", value: f.minimumcase, format: 'Percentage', });
        this.templInput.push({ name: f.lever + " | Realistic case", value: f.realisticcase, format: 'Percentage' });
        this.templInput.push({ name: f.lever + " | Best case", value: f.bestcase, format: 'Percentage' });
      });
    }

    //generate report data
    this.rptData = [];
    if (this.responseData) {
      Enumerable.from(this.responseData).where(x => x['short'] == '').forEach(f => {
        let obj = [];
        Enumerable.from(this.responseData).where(x => x['short'] != '' && x['dimension'] == f['full']).forEach(g => {
          if (JSON.parse(localStorage.getItem('currentTemplateDetails'))['opportunityStructure']) {
            let templop = JSON.parse(JSON.parse(localStorage.getItem('currentTemplateDetails'))['opportunityStructure']);
            Enumerable.from(templop).where(x => x['subdimension'] == g['full']).forEach(h => {
              let equation = h['opportunityformula'];
              if (equation) {
                let minimumcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Minimum case");
                let realisticcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Realistic case");
                let bestcase = h['opportunityformula'].replace("Impact value", h['lever'] + " | Best case");

                minimumcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      minimumcase = minimumcase.replace(t, v.toString());
                    }
                  }
                });

                realisticcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      realisticcase = realisticcase.replace(t, v.toString());
                    }
                  }
                });

                bestcase.split(/[()*/%+-]+/g).forEach(t => {
                  if (t != null && typeof t != 'undefined' && t != '' && isNaN(t)) {
                    let v = 0;
                    if (Enumerable.from(this.templInput).where(x => x['name'] === t).any()) {
                      var item = Enumerable.from(this.templInput).where(x => x['name'] === t).firstOrDefault();
                      v = item['value'];
                      if (item['format'] == 'Percentage')
                        v = v * 0.01;
                      bestcase = bestcase.replace(t, v.toString());
                    }
                  }
                });

                let mval = 0, rval = 0, bval = 0;
                try {
                  mval = Math.round(eval(minimumcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  mval = 0;
                }
                try {
                  rval = Math.round(eval(realisticcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  rval = 0;
                }
                try {
                  bval = Math.round(eval(bestcase) * (1 - (f['total'] * 0.01)) * 10) / 10;
                }
                catch {
                  bval = 0;
                }
                if (rval > this.highVal)
                  this.highVal = rval;
                obj.push({ dimension: f['full'], subdimension: g['full'], businessunit: h['businessunit'], lever: h['lever'], formula: equation + '*(1-Maturity)', equation: realisticcase + '*(1-' + f['total'] + '%)', minimumcasevalue: mval, realisticcasevalue: rval, bestcasevalue: bval });
              }
            })
          }
        });

        this.rptData.push({ dimension: f['full'], digitallever: Enumerable.from(obj).orderByDescending(x => x['realisticcasevalue']).toArray() })
      });

      this.midVal = Math.round(this.highVal / 3 * 2);
      this.lowVal = Math.round(this.highVal / 3);
    }
  }

  loadScatterChartData() {
    if (this.rptData) {
      let leverList = [];
      this.rptData.forEach(f => {
        if (f.digitallever) {
          f.digitallever.forEach(g => {
            leverList.push(g);
          });
        }
      });

      this.chartOptionsScatter.series.push({
        name: 'Not Relevant',
        type: 'polygon',
        data: [[0, 0], [100, 0], [100, this.highVal / 3 * 2], [0, this.highVal / 3]],
        color: '#FEB791',
        enableMouseTracking: false,
        accessibility: {
          exposeAsGroupOnly: true,
          description: 'Not Relevant'
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
        {
          name: 'Strategic',
          type: 'polygon',
          data: [[0, this.highVal / 3], [100, this.highVal / 3 * 2], [100, this.highVal], [45, this.highVal]],
          color: '#FD6412',
          enableMouseTracking: false,
          accessibility: {
            exposeAsGroupOnly: true,
            description: 'Strategic'
          },
          states: {
            inactive: {
              opacity: 1
            }
          }
        },
        {
          name: 'Quick Win',
          type: 'polygon',
          data: [[0, this.highVal / 3], [45, this.highVal], [0, this.highVal]],
          color: '#D04A02',
          enableMouseTracking: false,
          accessibility: {
            exposeAsGroupOnly: true,
            description: 'Quick Win'
          },
          states: {
            inactive: {
              opacity: 1
            }
          }
        })
      //this.chartOptionsScatter.series.push({ name: 'Observations', type: 'scatter', color: '#000', data: [[15, 51.6]], enableMouseTracking: true, accessibility: { exposeAsGroupOnly: false, description: '' } });

      Enumerable.from(leverList).orderByDescending(o => o['realisticcasevalue']).take(10).forEach(f => {
        if (f['realisticcasevalue'] != 0) {
          let implementationrisk = Enumerable.from(this.digitalleversalldata).where(x => x.lever == f['lever']).select(y => y.implementationrisk).firstOrDefault();
          let likelihood = Enumerable.from(this.digitalleversalldata).where(x => x.lever == f['lever']).select(y => y.likelihood).firstOrDefault();
          this.chartOptionsScatter.series.push({
            name: f['lever'],
            type: 'scatter',
            color: '#000',
            data: [{ x: ((implementationrisk * likelihood) / 25) * 100, y: f['realisticcasevalue'], name: f['lever'] }],
            enableMouseTracking: true,
            marker: {
              symbol: 'cross',
              lineColor: null,
              lineWidth: 2
            },
            dataLabels: {
              enabled: true,
              align: "center",
              verticalAlign: "middle",
              useHTML: true,
              allowOverlap: true,
              //pointFormat: '',
              formatter: function () {
                return '<span style="border: 1px solid #000; padding: 2px; background-color: #ddd;">' + this.point.name + '</span><br>';
              },
              style: {
                fontSize: "11px",
                textOutline: "none"
              }
            },
            tooltip: {
              headerFormat: '<b>{series.name}</b><br>',
              pointFormat: '<b>Impact value: </b>€{point.y}M'
            },
            states: {
              inactive: {
                opacity: 0.1
              }
            }
          });
        }
      });

      let modal = this.detailsModal;
      let digitalleversalldata = this.digitalleversalldata;
      let modalData = [];
      this.chartOptionsScatter.plotOptions.series.point.events.click = function (e) {
        let lever = e.point.name;
        let category = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['dimension']).firstOrDefault();
        let subcategory = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['subdimension']).firstOrDefault();
        let description = Enumerable.from(digitalleversalldata).where(x => x['lever'] == lever).select(y => y['description']).firstOrDefault();
        let minimumcasevalue = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['minimumcasevalue']).firstOrDefault();
        let realisticcasevalue = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['realisticcasevalue']).firstOrDefault();
        let bestcasevalue = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['bestcasevalue']).firstOrDefault();
        let formula = Enumerable.from(leverList).where(x => x['lever'] == lever).select(y => y['formula']).firstOrDefault();
        let paybackperiodonetime = Enumerable.from(digitalleversalldata).where(x => x.lever == lever).select(y => y.paybackperiodonetime).firstOrDefault();
        let paybackperiodtotal = Enumerable.from(digitalleversalldata).where(x => x.lever == lever).select(y => y.paybackperiodtotal).firstOrDefault();
        let strategicrelevance = Enumerable.from(digitalleversalldata).where(x => x.lever == lever).select(y => y.strategicrelevance).firstOrDefault();
        let implementationcomplexity = Enumerable.from(digitalleversalldata).where(x => x.lever == lever).select(y => y.implementationcomplexity).firstOrDefault();
        let implementationtime = Enumerable.from(digitalleversalldata).where(x => x.lever == lever).select(y => y.implementationtime).firstOrDefault();
        modalData.splice(0);
        modalData.push({ category: category, subcategory: subcategory, lever: lever, description: description, minimumcasevalue: minimumcasevalue, realisticcasevalue: realisticcasevalue, bestcasevalue: bestcasevalue, formula: formula, implementationcostonetime: Math.round(realisticcasevalue * paybackperiodonetime * 10) / 10, implementationcosttotal: Math.round(realisticcasevalue * paybackperiodtotal * 10) / 10, strategicrelevance: strategicrelevance, implementationcomplexity: implementationcomplexity, implementationtime: implementationtime });
        modal.show();
      };
      this.modalData = [];
      this.modalData = modalData;
    }
  }

  submit() {
    this.alertService.showDialog('Are you sure you want to submit the assessment?', DialogType.confirm, () => {
      this.currentAssessment.createdby = this.accountService.currentUser.id;
      this.currentAssessment.isdeleted = false;
      this.currentAssessment.isget = false;
      this.currentAssessment.isSubmitted = true;

      this.accountService.Set("assessment", this.currentAssessment).subscribe(
        () => {
          localStorage.removeItem("currentTemplateDetails");
          localStorage.removeItem('currentUserDetails');
          this.router.navigate(['home']);
        },
        error => { this.appComponent.failedHelper(error); });
    });
  }
}
