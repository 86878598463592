import { Auditentity } from './auditentity.model';
import { DigitalLevers } from './digitallevers.model';

export class Template extends Auditentity {

  constructor(id?: number,
    name?: string,
    description?: string,
    version?: number,
    industrySector?: string,
    defaultScore?: number,
    isQuantitative?: boolean,
    isEnabled?: boolean,
    isDefault?: boolean,
    treeStructure?: string,
    questionStructure?: string,
    improvementAreaStructure?: string,
    observationStructure?: string,
    plStructure?: string,
    assumptionStructure?: string,
    opportunityStructure?: string,
    userCount?: number,
    versionName?: string,
    //FOR UI LAYER ONLY
    clientid?: number,
    orgname?: string,
    templateDtl?: TemplateDtl[],
    questionCount?: number,
  ) {
    super();
    this.id = id;
    this.name = name;
    this.description = description;
    this.industrySector = industrySector;
    this.defaultScore = defaultScore;
    this.version = version;
    this.isQuantitative = isQuantitative;
    this.isEnabled = isEnabled;
    this.isDefault = isDefault;
    this.treeStructure = treeStructure;
    this.questionStructure = questionStructure;
    this.improvementAreaStructure = improvementAreaStructure;
    this.observationStructure = observationStructure;
    this.plStructure = plStructure;
    this.assumptionStructure = assumptionStructure;
    this.opportunityStructure = opportunityStructure;
    this.userCount = userCount;
    this.versionName = versionName;

    this.clientid = clientid;
    this.orgname = orgname;
    this.templateDtl = templateDtl;
    this.questionCount = questionCount;
  }

  public id: number;
  public name: string;
  public description: string;
  public industrySector: string;
  public defaultScore: number;
  public version: number;
  public isQuantitative: boolean;
  public isEnabled: boolean;
  public isDefault: boolean;
  public treeStructure: string;
  public questionStructure: string;
  public improvementAreaStructure: string;
  public observationStructure: string;
  public plStructure: string;
  public assumptionStructure: string;
  public opportunityStructure: string;
  public userCount: number;
  public versionName: string;

  public clientid: number;
  public orgname: string;
  public templateDtl: TemplateDtl[];
  public questionCount: number;
}

export class TemplateDtl {

  constructor(
    id?: number,
    templateid?: number,
    questionid?: number,
    isChecked?: boolean,
    weightage?: number,
    benchmarkValue?: number,
    maturitylevel1?: string,
    maturitylevel2?: string,
    maturitylevel3?: string,
    maturitylevel4?: string,
    maturitylevel5?: string,
    slNo?: number,

    questiontext?: string,
    customdbid?: string,
    columndata?: any,
    sequence?: number,
    isActive?: boolean,
    questionCategory?: string,
  ) {
    this.id = id;
    this.templateid = templateid;
    this.questionid = questionid;
    this.isChecked = isChecked;
    this.weightage = weightage;
    this.benchmarkValue = benchmarkValue;
    this.maturitylevel1 = maturitylevel1;
    this.maturitylevel2 = maturitylevel2;
    this.maturitylevel3 = maturitylevel3;
    this.maturitylevel4 = maturitylevel4;
    this.maturitylevel5 = maturitylevel5;
    this.slNo = slNo;

    this.questiontext = questiontext;
    this.customdbid = customdbid;
    this.columndata = columndata;
    this.sequence = sequence;
    this.isActive = isActive;
    this.questionCategory = questionCategory;
  }

  public id: number;
  public templateid: number;
  public questionid: number;
  public isChecked: boolean;
  public weightage: number;
  public benchmarkValue: number;
  public maturitylevel1: string;
  public maturitylevel2: string;
  public maturitylevel3: string;
  public maturitylevel4: string;
  public maturitylevel5: string;
  public slNo: number;
  //for angular layer only
  public questiontext: string;
  public customdbid: string;
  public columndata: any;
  public sequence: number;
  public isActive: boolean;
  public questionCategory: string;
}
