import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Assessment, AssessmentQuestions } from '../../models/assessment.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Utilities } from '../../services/utilities';
import { Location } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Template } from '../../models/template.model';

@Component({
  selector: 'reportassessment',
  templateUrl: './reportassessment.component.html',
  styleUrls: ['./reportassessment.component.scss']
})
export class ReportassessmentComponent implements OnInit {

  assessmentId: any;
  objAssessmentTemp: Assessment = new Assessment();
  objAssessment: any;
  templateId: any;
  objTemplateTemp: Template = new Template();
  objTemplate: any;
  userResponseList: AssessmentQuestions[] = [];

  constructor(private _activatedRoute: ActivatedRoute, private accountService: AccountService, private alertService: AlertService, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('reportassessmentId') != '' && localStorage.getItem('reportassessmentId') != null && typeof localStorage.getItem('reportassessmentId') != 'undefined') {
      this.assessmentId = localStorage.getItem('reportassessmentId');
      this.loadReport();
    }
  }

  loadReport() {
    this.objAssessmentTemp.isget = true;
    this.objAssessmentTemp.id = this.assessmentId;
    this.objAssessmentTemp.isdatabyuser = "-1"
    this.accountService.Set('Assessment', this.objAssessmentTemp).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.objAssessment = results as Assessment;
        this.userResponseList = JSON.parse((this.objAssessment as Assessment).userResponses);
        this.templateId = (this.objAssessment as Assessment).templId;
        localStorage.setItem("printAssessment", JSON.stringify(this.objAssessment));
        this.loadTemplate();
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  loadTemplate() {
    this.objTemplateTemp.id = this.templateId;
    this.objTemplateTemp.isget = true;
    this.accountService.Set('loginhandler/gettemplates', this.objTemplateTemp).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.objTemplate = results as Template;
        localStorage.setItem("printTemplate", JSON.stringify(this.objTemplate));
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  downloadPDF() {
    html2canvas(document.querySelector(".assessment-content")).then(function (canvas) {
      canvas.getContext('2d');
      var HTML_Width = canvas.width;
      var HTML_Height = canvas.height;
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + (top_left_margin * 2);
      var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width.toString(), 'p');
        let margin = -(PDF_Height * i) + (top_left_margin * 4);
        if (i > 1) {
          margin = margin + i * 8;
        }
        pdf.addImage(imgData, 'JPG', top_left_margin, margin, canvas_image_width, canvas_image_height);
      }

      let dt = new Date().toJSON("yyyyMMddHHmmss");
      pdf.save('Assessment_' + dt + '.pdf');
    });
  }

  printPage() {
    window.print();
  }

  backToCompletedPage() {
    localStorage.removeItem('reportassessmentId');
    this.router.navigate(['completed']);
  }
}
