import { Question } from './question.model';
import { Auditentity } from './auditentity.model';

export class Assessment extends Auditentity {

  constructor(
    id?: number,
    userId?: string,
    sector?: string,
    subSector?: string,
    role?: string,
    assessmentType?: string,
    orgId?: number,
    templId?: number,
    isAcceptedPrivacy?: boolean,
    assessmentQuestions?: AssessmentQuestions[],
    orgName?: string,
    userResponses?: string,
    isSubmitted?: boolean,
    isdatabyuser?: string,
    tempName?: string,
    fullname?: string,
    username?: string,
    plStructure?: string,
    assumptionStructure?: string,
  ) {
    super();
    this.id = id;
    this.userId = userId;
    this.sector = sector;
    this.subSector = subSector;
    this.role = role;
    this.assessmentType = assessmentType;
    this.orgId = orgId;
    this.templId = templId;
    this.isAcceptedPrivacy = isAcceptedPrivacy;
    this.assessmentQuestions = assessmentQuestions;
    this.orgName = orgName;
    this.userResponses = userResponses;
    this.isSubmitted = isSubmitted;
    this.isdatabyuser = isdatabyuser;
    this.tempName = tempName;
    this.fullname = fullname;
    this.username = username;
    this.plStructure = plStructure;
    this.assumptionStructure = assumptionStructure;
  }
  public id: number;
  public userId: string;
  public sector: string;
  public subSector: string;
  public role: string;
  public assessmentType: string;
  public orgId: number;
  public templId: number;
  public isAcceptedPrivacy: boolean;
  public assessmentQuestions: AssessmentQuestions[];
  public orgName: string;
  public userResponses: string;
  public isSubmitted: boolean;
  public isdatabyuser: string;
  public tempName: string;
  public fullname: string;
  public username: string;
  public plStructure: string;
  public assumptionStructure: string;
}

export class AssessmentModules {

  constructor(
    id?: number,
    assessmenttype?: string,
    parentName?: string,
    parentValue?: string,
    sequence?: number,
    totalQuestion?: number,
    completedQuestion?: number,
    isChecked?: boolean,
    questionId?: number,
    questions?: AssessmentQuestions[]
  ) {
    this.id = id;
    this.assessmenttype = assessmenttype;
    this.parentName = parentName;
    this.parentValue = parentValue;
    this.sequence = sequence;
    this.questions = questions;
    this.totalQuestion = totalQuestion;
    this.completedQuestion = completedQuestion;
    this.isChecked = isChecked;
    this.questionId = questionId;
  }
  public id: number;
  public assessmenttype: string;
  public parentName: string;
  public parentValue: string;
  public sequence: number;
  public totalQuestion: number;
  public completedQuestion: number;
  public isChecked: boolean;
  public questionId: number;
  public questions: AssessmentQuestions[];
}

export class AssessmentQuestions {

  constructor(
    id?: number,
    assessmentId?: number,
    templateDtlId?: number,
    response?: string,
    responsetarget?: string,
    other?: string,
    responseOptions?: AssessmentResponseOptions[],
    parentValue?: string,
    questiontext?: string,
    questionCategory?: string,
    weightage?: number,
    benchmarkValue?: number,
    maturitylevel1Desc?: string,
    maturitylevel2Desc?: string,
    maturitylevel3Desc?: string,
    maturitylevel4Desc?: string,
    maturitylevel5Desc?: string,
    userId?: string,
    sequence?: number,
    total?: number,
    columndata?: any,
    customdbid?: any,
  ) {
    this.id = id;
    this.assessmentId = assessmentId;
    this.templateDtlId = templateDtlId;
    this.response = response;
    this.responsetarget = responsetarget;
    this.other = other;
    this.responseOptions = responseOptions;
    this.parentValue = parentValue;
    this.questiontext = questiontext;
    this.questionCategory = questionCategory;

    this.weightage = weightage;
    this.benchmarkValue = benchmarkValue;
    this.maturitylevel1Desc = maturitylevel1Desc;
    this.maturitylevel2Desc = maturitylevel2Desc;
    this.maturitylevel3Desc = maturitylevel3Desc;
    this.maturitylevel4Desc = maturitylevel4Desc;
    this.maturitylevel5Desc = maturitylevel5Desc;
    this.userId = userId;
    this.sequence = sequence;
    this.total = total;
    this.columndata = columndata;
    this.customdbid = customdbid;
  }

  public id: number;
  public assessmentId: number;
  public templateDtlId: number;
  public response: string;
  public responsetarget: string;
  public other: string;
  public responseOptions: AssessmentResponseOptions[];
  public parentValue: string;
  public questiontext: string;
  public questionCategory: string;
  public weightage: any;
  public benchmarkValue: any;
  public maturitylevel1Desc: string;
  public maturitylevel2Desc: string;
  public maturitylevel3Desc: string;
  public maturitylevel4Desc: string;
  public maturitylevel5Desc: string;
  public userId: string;
  public sequence: number;
  public total: number;
  public columndata: any;
  public customdbid: any;
}

export class AssessmentResponseOptions {
  constructor(
    maturityLevel?: number,
    responseOption?: string,
    responseValue?: number,
    isChecked?: boolean,
  ) {
    this.maturityLevel = maturityLevel;
    this.responseOption = responseOption;
    this.responseValue = responseValue;
    this.isChecked = isChecked;
  }

  public maturityLevel: number;
  public responseOption: string;
  public responseValue: number;
  public isChecked: boolean;
}
