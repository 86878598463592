// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import {
  Component,
  OnInit,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { User } from "../../models/user.model";
import { Role } from "../../models/role.model";
import { Permission } from "../../models/permission.model";
import { UserEdit } from "../../models/user-edit.model";
import { UserInfoComponent } from "./user-info.component";
import { AppComponent } from "../app.component";
import { InviteExternalUserComponent } from "./invite-external-user/invite-external-user.component";

@Component({
  selector: "users-management",
  templateUrl: "./users-management.component.html",
  styleUrls: ["./users-management.component.scss"],
})
export class UsersManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: User[] = [];
  rowsCache: User[] = [];
  editedUser: UserEdit;
  sourceUser: UserEdit;
  editingUserName: { name: string };
  loadingIndicator: boolean;
  inviteExtEmail: string;

  allRoles: Role[] = [];
  obj: User = new User();
  module: any = "account";

  @ViewChild("indexTemplate", { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild("userNameTemplate", { static: true })
  userNameTemplate: TemplateRef<any>;

  @ViewChild("rolesTemplate", { static: true })
  rolesTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  @ViewChild("userEditor", { static: true })
  userEditor: UserInfoComponent;

  @ViewChild("extUserModal", { static: true })
  extUserModal: ModalDirective;

  @ViewChild("extUser", { static: true })
  extUser: InviteExternalUserComponent;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    public appComponent: AppComponent
  ) {}

  ngOnInit() {
    if (this.appComponent.isAdmin) {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        {
          prop: "index",
          name: "#",
          width: 40,
          cellTemplate: this.indexTemplate,
          canAutoResize: false,
        },
        { prop: "email", name: gT("users.management.Email"), width: 140 },
        { prop: "fullName", name: gT("users.management.FullName"), width: 120 },
        { prop: "jobTitle", name: gT("users.management.Org"), width: 50 },
        { prop: "phoneNumber", name: gT("users.management.Templ"), width: 100 },
        {
          prop: "userName",
          name: gT("users.management.UserName"),
          width: 90,
          cellTemplate: this.userNameTemplate,
        },
        {
          prop: "roles",
          name: gT("users.management.Roles"),
          width: 120,
          cellTemplate: this.rolesTemplate,
        },
      ];

      if (this.canManageUsers) {
        this.columns.push({
          name: "",
          width: 160,
          cellTemplate: this.actionsTemplate,
          resizeable: false,
          canAutoResize: false,
          sortable: false,
          draggable: false,
        });
      }

      this.loadData();
    }
  }

  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.userEditor.changesSavedCallback = () => {
        this.addNewUserToList();
        this.editorModal.hide();
      };
      this.extUser.changesSavedCallback = () => {
        this.extUserModal.hide();
      };

      this.userEditor.changesCancelledCallback = () => {
        this.editedUser = null;
        this.sourceUser = null;
        this.editorModal.hide();
      };
      this.extUser.changesCancelledCallback = () => {
        this.extUserModal.hide();
      };
    }
  }

  addNewUserToList() {
    if (this.sourceUser) {
      Object.assign(this.sourceUser, this.editedUser);

      let sourceIndex = this.rowsCache.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceUser, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedUser = null;
      this.sourceUser = null;
    } else {
      const user = new User();
      Object.assign(user, this.editedUser);
      this.editedUser = null;

      let maxIndex = 0;
      for (const u of this.rowsCache) {
        if ((u as any).index > maxIndex) {
          maxIndex = (u as any).index;
        }
      }

      (user as any).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, user);
      this.rows.splice(0, 0, user);
      this.rows = [...this.rows];
    }
  }

  loadData() {
    if (this.canViewRoles) {
      this.accountService.getUsersAndRoles().subscribe(
        (results) => this.onDataLoadSuccessful(results[0], results[1]),
        (error) => this.onDataLoadFailed(error)
      );
    } else {
      this.accountService.getUsers().subscribe(
        (users) =>
          this.onDataLoadSuccessful(
            users,
            this.accountService.currentUser.roles.map((x) => new Role(x))
          ),
        (error) => this.onDataLoadFailed(error)
      );
    }
  }

  onDataLoadSuccessful(users: User[], roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    users.forEach((user, index, users) => {
      (user as any).index = index + 1;
    });

    this.rowsCache = [...users];
    this.rows = users;
    this.allRoles = roles;
  }

  onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage(
      "Load Error",
      `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
        error
      )}"`,
      MessageSeverity.error,
      error
    );
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        r.userName,
        r.fullName,
        r.email,
        r.phoneNumber,
        r.jobTitle,
        r.roles
      )
    );
  }

  onEditorModalHidden() {
    this.editingUserName = null;
    this.userEditor.resetForm(true);
  }

  newUser() {
    this.editingUserName = null;
    this.sourceUser = null;
    this.editedUser = this.userEditor.newUser(this.allRoles);
    this.editorModal.show();
  }

  editUser(row: any) {
    this.editingUserName = { name: row.userName };
    this.sourceUser = row;
    this.editedUser = this.userEditor.editUser(row, this.allRoles);
    this.editorModal.show();
  }

  deleteUser(row: UserEdit) {
    this.alertService.showDialog(
      'Are you sure you want to delete "' + row.userName + '"?',
      DialogType.confirm,
      () => this.deleteUserHelper(row)
    );
  }

  deleteUserHelper(row: UserEdit) {
    this.alertService.startLoadingMessage("Deleting...");
    this.loadingIndicator = true;

    this.accountService.deleteUser(row).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows = this.rows.filter((item) => item !== row);
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          "Delete Error",
          `An error occured whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  newExtUser() {
    this.inviteExtEmail = null;
    this.extUserModal.show();
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(
      Permission.assignRolesPermission
    );
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(
      Permission.viewRolesPermission
    );
  }

  get canManageUsers() {
    return this.accountService.userHasPermission(
      Permission.manageUsersPermission
    );
  }
}
