import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { Template, TemplateDtl } from '../../models/template.model';
import { TemplateEditorComponent } from './template-editor.component';
import { User } from '../../models/user.model';
import * as Enumerable from '../../assets/scripts/Linq/linq';


@Component({
  selector: 'template-management',
  templateUrl: './template-management.component.html',
  styleUrls: ['./template-management.component.scss']
})
export class TemplateManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: Template[] = [];
  rowsCache: Template[] = [];
  allPermissions: Permission[] = [];
  sourceTemplate: Template;
  editedTemplate: Template;
  editingAssessmenttemplName: { name: string };
  loadingIndicator: boolean;
  obj: Template = new Template();
  objCopy: Template = new Template();
  module: any = 'Template';
  templatenamecopy: any;
  searchText: string;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal', { static: true })
  editorModal: ModalDirective;

  @ViewChild('assessmenttemplEditor', { static: true })
  assessmenttemplEditor: TemplateEditorComponent;

  @ViewChild('copyModal', { static: false })
  copyModal: ModalDirective;

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, public appComponent: AppComponent, private router: Router) {
  }
   
  ngOnInit() {
    if (this.appComponent.isAdmin) {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        { prop: 'index', name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
        { prop: 'name', name: 'Template Name', width: 160 },
        { prop: 'description', name: 'Description', width: 200 },
        { prop: 'versionName', name: 'Question DB Version', width: 140 },
        { prop: 'questionCount', name: 'Question(s)', width: 100 },
        { prop: 'userCount', name: 'User(s)', width: 100 },
        { name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
      ];

      this.loadData();
    }
  }

  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.assessmenttemplEditor.changesSavedCallback = () => {
        this.editorModal.hide();
      };

      this.assessmenttemplEditor.changesCancelledCallback = () => {
        this.editedTemplate = null;
        this.sourceTemplate = null;
        this.editorModal.hide();
      };
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.name, r.orgname, r.description));
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.obj.isget = true;
    this.obj.id = 0;
    this.accountService.Set(this.module, this.obj).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const assmnts = results;

        assmnts.forEach((assmnt, index, assmnts) => {
          (assmnt as any).index = index + 1;
          if ((assmnt as Template).questionStructure != null) {
            (assmnt as any).questionCount = JSON.parse((assmnt as Template).questionStructure).length;
          }
          else {
            (assmnt as any).questionCount = 0;
          }
        });

        this.rowsCache = [...assmnts];
        this.rows = assmnts;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve roles from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  onEditorModalHidden() {
    this.editingAssessmenttemplName = null;
    this.assessmenttemplEditor.resetForm(true);
    this.searchText = "";
  }


  newAssessmenttempl() {
    this.editingAssessmenttemplName = null;
    localStorage.removeItem('tmplTreeStuct');
    localStorage.removeItem('tmplSelected');
    this.sourceTemplate = null;
    //this.editedTemplate = this.assessmenttemplEditor.newTemplate(this.allPermissions);   
  }

  edit(row: Template) {   
    this.editingAssessmenttemplName = { name: row.name };
    localStorage.removeItem('tmplTreeStuct');
    localStorage.removeItem('tmplSelected');
    localStorage.setItem('tmplTreeStuct', row.treeStructure);
    localStorage.setItem('tmplSelected', JSON.stringify(row));
    this.sourceTemplate = row;
    //this.editedTemplate = this.assessmenttemplEditor.editTemplate(row, this.allPermissions);
  }

  get canManageAssessmenttempl() {
    return this.accountService.userHasPermission(Permission.manageAssmntPermission);
  }

  delete(obj: Template) {
    this.alertService.showDialog('Are you sure you want to delete \"' + obj.name + '\"?', DialogType.confirm, () => {
      obj.createdby = this.accountService.currentUser.id;
      obj.isdeleted = true;
      obj.isget = false;
      this.accountService.Set(this.module, obj).subscribe(
        () => {
          this.alertService.showDialog(`Deleted Successfully.`, DialogType.alert);
          this.rowsCache = this.rowsCache.filter(item => item !== obj);
          this.rows = this.rows.filter(item => item !== obj);
        }, error => { this.appComponent.failedHelper(error); });
    });
  }

  copy(obj: Template) {
    this.copyModal.show();
    this.templatenamecopy = obj.name;
    this.objCopy = obj;
   
  }

  copyTemplate() {
    this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {
      if (this.objCopy.name == null || typeof this.objCopy.name == 'undefined' || this.objCopy.name.length == 0) {
        this.alertService.showDialog('Template name is required.', DialogType.alert);
        return;
      }

      this.objCopy.name = this.templatenamecopy;
      this.objCopy.createdby = this.accountService.currentUser.id;
      this.objCopy.isdeleted = false;
      this.objCopy.isget = false;
      this.objCopy.id = 0;
      this.objCopy.isDefault = false;
      this.accountService.Set(this.module, this.objCopy).subscribe(
        () => {
          this.alertService.showDialog(`Copied Successfully.`, DialogType.alert);
          this.loadData();
          this.copyModal.hide();
        }, error => { this.appComponent.failedHelper(error); });
    });
  }
}
