import { Component, OnInit, ViewChild, Input } from "@angular/core";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "../../services/alert.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { User } from "../../models/user.model";
import { UserEdit } from "../../models/user-edit.model";
import { Role } from "../../models/role.model";
import { Permission } from "../../models/permission.model";
import { Client } from "../../models/client.model";
import { Template } from "../../models/template.model";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { forEach } from "lodash";
import { stream, Workbook } from "exceljs";
import * as fs from "file-saver";
import { Assessment, AssessmentQuestions } from "../../models/assessment.model";

const EXCEL_EXTENSION = ".xlsx";

@Component({
  selector: "user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
  public isEditMode = false;
  public isNewUser = false;
  public isSaving = false;
  public isChangePassword = false;
  public isEditingSelf = false;
  public showValidationErrors = false;
  public uniqueId: string = Utilities.uniqueId();
  public user: User = new User();
  public userEdit: UserEdit;
  public allRoles: Role[] = [];
  public formResetToggle = true;
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  obj: Assessment = new Assessment();
  objtempList: any;
  assessmentRows: Assessment[] = [];
  assessmentRowsCache: Assessment[] = [];

  questionWithResponseList: any[] = [];

  @Input()
  isViewOnly: boolean;

  @Input()
  isGeneralEditor = false;

  @ViewChild("f", { static: false })
  public form;

  // ViewChilds Required because ngIf hides template variables from global scope
  @ViewChild("userName", { static: false })
  public userName;

  @ViewChild("userPassword", { static: false })
  public userPassword;

  @ViewChild("email", { static: false })
  public email;

  @ViewChild("currentPassword", { static: false })
  public currentPassword;

  @ViewChild("newPassword", { static: false })
  public newPassword;

  @ViewChild("confirmPassword", { static: false })
  public confirmPassword;

  @ViewChild("roles", { static: false })
  public roles;

  @ViewChild("rolesSelector", { static: false })
  public rolesSelector;
  public clientObj: Client = new Client();
  public orgList: Client[] = [];
  public templObj: Template = new Template();
  public templList: Template[] = [];
  public templListNew: Template[] = [];
  public clientlist: any[] = [];
  public portfoliolist: any[] = [];
  public clientname: string;
  isEnableRole: boolean = true;
  constructor(
    private alertService: AlertService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.loadTemplates();
    if (!this.isGeneralEditor) {
      this.loadCurrentUserData();
      this.loadTemplates();
    }

    if (this.accountService.currentUser.roles[0] == "administrator") {
      this.isEnableRole = false;
    }
  }

  loadOrganisation() {

    this.orgList = [];
    this.clientObj.isget = true;
    this.accountService.Set("client", this.clientObj).subscribe(
      (results) => {
        if (results) {
          Object.assign(this.orgList, results);
          let enabledOrgList;

          enabledOrgList = this.orgList.filter(o => o.isEnabled);
          this.orgList = [];
          Object.assign(this.orgList, enabledOrgList);

          /*  if (this.isViewOnly) {*/
          if (!this.isEditMode) {
            this.clientname = Enumerable.from(this.orgList)
              .where((x) => x.id == this.user.orgId)
              .select((y) => y.name)
              .firstOrDefault();
          } else {
            this.clientlist = [];
            this.orgList.forEach((f) => {
              if (
                typeof f.name != "undefined" &&
                f.name != null &&
                f.name.trim().length > 0
              )
                this.clientlist.push({ id: f.id, name: f.name });
            });
            if (typeof this.userEdit !== "undefined") {
              this.loadPortFolio(this.userEdit.orgId, 1);
            }
          }
        }
      },
      (error) => {
        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  loadPortFolio(orgId: number, flag: number) {
    this.portfoliolist = [];
    const selectedOrg = this.orgList.filter(org => org.id == orgId);
    if (selectedOrg.length > 0) {
      this.userEdit.clientOrganisation = selectedOrg[0].name;
    }  

    if (
      Enumerable.from(this.orgList)
        .where((x) => x.id == orgId)
        .any()
    ) {
      let selectedObj = Enumerable.from(this.orgList)
        .where((x) => x.id == orgId)
        .firstOrDefault();

      //this.portfoliolist.push({ id: 'undefined', name: '--Select Portfolio--' });
      if (
        typeof selectedObj.portfolio1 != "undefined" &&
        selectedObj.portfolio1 != null &&
        selectedObj.portfolio1.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio1,
        });
      if (
        typeof selectedObj.portfolio2 != "undefined" &&
        selectedObj.portfolio2 != null &&
        selectedObj.portfolio2.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio2,
        });
      if (
        typeof selectedObj.portfolio3 != "undefined" &&
        selectedObj.portfolio3 != null &&
        selectedObj.portfolio3.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio3,
        });
      if (
        typeof selectedObj.portfolio4 != "undefined" &&
        selectedObj.portfolio4 != null &&
        selectedObj.portfolio4.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio4,
        });
      if (
        typeof selectedObj.portfolio5 != "undefined" &&
        selectedObj.portfolio5 != null &&
        selectedObj.portfolio5.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio5,
        });
      if (
        typeof selectedObj.portfolio6 != "undefined" &&
        selectedObj.portfolio6 != null &&
        selectedObj.portfolio6.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio6,
        });
      if (
        typeof selectedObj.portfolio7 != "undefined" &&
        selectedObj.portfolio7 != null &&
        selectedObj.portfolio7.trim().length > 0
      )
        this.portfoliolist.push({
          id: selectedObj.id,
          name: selectedObj.portfolio7,
        });
    }
    if (this.isGeneralEditor) {
      if (this.portfoliolist.length > 0 && this.isNewUser) {
        this.userEdit.jobTitle = this.portfoliolist[0].name;
      }
      else if (this.portfoliolist.length > 0 && !this.isNewUser) {
        if (flag == 0)
          this.userEdit.jobTitle = this.portfoliolist[0].name;
      }
      else {
        this.userEdit.jobTitle = null;
      }
    }
    else {
      if (this.portfoliolist.length > 0) {
        if (flag == 0)
          this.userEdit.jobTitle = this.portfoliolist[0].name;
        else
          this.userEdit.jobTitle = this.user.jobTitle;
      }
      else {
        this.userEdit.jobTitle = null;
      }
    }
  }

  loadTemplates() {
    this.templList = [];
    this.templObj.isget = true;
    this.accountService.Set("template", this.templObj).subscribe(
      (results) => {
        if (results) {
          Object.assign(this.templList, results);
          let enableTemplates;
          enableTemplates = this.templList.filter(x => x.isEnabled);
          this.templList = [];
          Object.assign(this.templList, enableTemplates);

          //this.templList = results;
          //this.templList = Enumerable.from(this.templList).select(x => x.name).toArray();
          //Object.assign(this.templList, results);
        }
      },
      (error) => {
        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve users from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  private loadCurrentUserData() {
    this.alertService.startLoadingMessage();

    if (this.canViewAllRoles) {
      this.accountService
        .getUserAndRoles(this.accountService.currentUser.id)
        .subscribe(
          (results) => {
            if (results)
              this.onCurrentUserDataLoadSuccessful(results[0], results[1]);
          },
          (error) => this.onCurrentUserDataLoadFailed(error)
        );
    } else {
      this.accountService.getUser(this.accountService.currentUser.id).subscribe(
        (user) => {
          if (user)
            this.onCurrentUserDataLoadSuccessful(
              user,
              user.roles.map((x) => new Role(x))
            );
        },
        (error) => this.onCurrentUserDataLoadFailed(error)
      );
    }
  }

  private onCurrentUserDataLoadSuccessful(user: User, roles: Role[]) {
    this.alertService.stopLoadingMessage();
    this.user = user;
    this.allRoles = roles;
    this.loadOrganisation();
  }

  private onCurrentUserDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      "Load Error",
      `Unable to retrieve user data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
        error
      )}"`,
      MessageSeverity.error,
      error
    );

    this.user = new User();
  }

  getRoleByName(name: string) {
    return this.allRoles.find((r) => r.name == name);
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  deletePasswordFromUser(user: UserEdit | User) {
    const userEdit = user as UserEdit;

    delete userEdit.currentPassword;
    delete userEdit.newPassword;
    delete userEdit.confirmPassword;
  }

  checkRoleDisabled(role) {

    if (this.userEdit.email && this.userEdit.email.includes("@pwc.")) {
      if (this.isEditingSelf && this.accountService.currentUser.roles[0] == "administrator") {
        return true;
      }

    }
    else {
      if (
        this.userEdit.email &&
        !this.userEdit.email.includes("@pwc.") &&
        role.toLowerCase() === "administrator"
      ) {
        return true;
      }
      return false;
    }
  }

  checkIsEnabled() {
    if (this.userEdit.email && this.userEdit.email.includes("@pwc.")) {
      if (this.isEditingSelf && this.accountService.currentUser.roles[0] == "administrator") {
        return true;
      }
    }

  }


  edit() {
    if (!this.isGeneralEditor) {
      this.isEditingSelf = true;
      this.userEdit = new UserEdit();
      
      Object.assign(this.userEdit, this.user);
      this.allRoles.forEach((l) => {
        this.userEdit.roles.forEach((k) => {
          if (k == l.name) l.isactive = true;
        });
      });
      this.loadOrganisation();
      this.loadPortFolio(this.userEdit.orgId, 1);
      
      this.getselectedTemplate(this.userEdit.industrySector, 1);
    } else {
      if (!this.userEdit) {
        this.userEdit = new UserEdit();
      }

      this.isEditingSelf = this.accountService.currentUser
        ? this.userEdit.id == this.accountService.currentUser.id
        : false;
      this.allRoles.forEach((l) => {
        this.userEdit.roles.forEach((k) => {
          if (k == l.name) l.isactive = true;
        });
      });
    }

    this.isEditMode = true;
    this.showValidationErrors = true;
    this.isChangePassword = false;
  }

  save() {
   
    let checkedRolesCount = 0;
    this.allRoles.forEach((l) => {
      if (l.isactive == true) checkedRolesCount++;
    });
    if (checkedRolesCount == 0) {
      this.alertService.showDialog(
        "Please select at least one role.",
        DialogType.alert
      );
      return;
    }
    if (
      this.userEdit.fullName != "" &&
      this.userEdit.fullName != null &&
      typeof this.userEdit.fullName != "undefined"
    ) {
      if (this.userEdit.fullName.trim() == "") {
        this.alertService.showDialog(
          "Please enter a valid full name.",
          DialogType.alert
        );
        return;
      }
    }
    this.isSaving = true;
    this.alertService.startLoadingMessage("Saving changes...");

    this.userEdit.roles = [];
    this.allRoles.forEach((f) => {
      if (f.isactive == true) this.userEdit.roles.push(f.name);
    });

    this.userEdit.phoneNumber = Enumerable.from(this.templList)
      .where((x) => x.id == this.userEdit.templId)
      .select((s) => s.name)
      .firstOrDefault();
    // Hard coded password fields as part of integration with product IAM
    this.userEdit.newPassword = atob("dGVtcFBAc3MxMjM=");// "tempP@ss123";
    this.userEdit.confirmPassword = atob("dGVtcFBAc3MxMjM="); //"tempP@ss123";
    this.userEdit.userName = this.userEdit.email;

    if (this.isNewUser) {
      if (this.userEdit.email.includes("@pwc.com")) {
        this.accountService.newUser(this.userEdit).subscribe(
          (user) => this.saveSuccessHelper(user),
          (error) => this.saveFailedHelper(error)
        );
      } else {
        this.alertService.showMessage(
          "Error",
          "Only PwC domain email addresses are allowed. For external users please use Invite External User.",
          MessageSeverity.error
        );
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
      }
    } else {
      this.accountService.updateUser(this.userEdit).subscribe(
        (response) => this.saveSuccessHelper(),
        (error) => this.saveFailedHelper(error)
      );
    }
  }

  private saveSuccessHelper(user?: User) {
    this.testIsRoleUserCountChanged(this.user, this.userEdit);

    if (user) {
      Object.assign(this.userEdit, user);
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.isChangePassword = false;
    this.showValidationErrors = false;

    this.deletePasswordFromUser(this.userEdit);
    Object.assign(this.user, this.userEdit);
    this.userEdit = new UserEdit();
    this.resetForm();

    if (this.isGeneralEditor) {
      if (this.isNewUser) {
        this.alertService.showMessage(
          "Success",
          `User \"${this.user.userName}\" was created successfully`,
          MessageSeverity.success
        );
      } else if (!this.isEditingSelf) {
        this.alertService.showMessage(
          "Success",
          `Changes to user \"${this.user.userName}\" was saved successfully`,
          MessageSeverity.success
        );
      }
    }

    if (this.isEditingSelf) {
      this.alertService.showMessage(
        "Success",
        "Changes to your User Profile was saved successfully",
        MessageSeverity.success
      );
      this.refreshLoggedInUser();
    }

    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private saveFailedHelper(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(
      "Save Error",
      "The below errors occured whilst saving your changes:",
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    if (this.changesFailedCallback) {
      this.changesFailedCallback();
    }
  }

  private testIsRoleUserCountChanged(currentUser: User, editedUser: User) {
    const rolesAdded = this.isNewUser
      ? editedUser.roles
      : editedUser.roles.filter(
        (role) => currentUser.roles.indexOf(role) == -1
      );
    const rolesRemoved = this.isNewUser
      ? []
      : currentUser.roles.filter(
        (role) => editedUser.roles.indexOf(role) == -1
      );

    const modifiedRoles = rolesAdded.concat(rolesRemoved);

    if (modifiedRoles.length) {
      setTimeout(() =>
        this.accountService.onRolesUserCountChanged(modifiedRoles)
      );
    }
  }

  cancel() {
    if (this.isGeneralEditor) {
      this.userEdit = this.user = new UserEdit();
    } else {
      this.userEdit = new UserEdit();
    }

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (!this.isGeneralEditor) {
      this.isEditMode = false;
    }

    if (this.changesCancelledCallback) {
      this.changesCancelledCallback();
    }
  }

  close() {
    this.userEdit = this.user = new UserEdit();
    this.showValidationErrors = false;
    this.resetForm();
    this.isEditMode = false;

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  private refreshLoggedInUser() {
    this.accountService.refreshLoggedInUser().subscribe(
      (user) => {
        this.loadCurrentUserData();
      },
      (error) => {
        this.alertService.resetStickyMessage();
        this.alertService.showStickyMessage(
          "Refresh failed",
          "An error occured whilst refreshing logged in user information from the server",
          MessageSeverity.error,
          error
        );
      }
    );
  }

  changePassword() {
    this.isChangePassword = true;
  }

  unlockUser() {
    this.isSaving = true;
    this.alertService.startLoadingMessage("Unblocking user...");

    this.accountService.unblockUser(this.userEdit.id).subscribe(
      () => {
        this.isSaving = false;
        this.userEdit.isLockedOut = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showMessage(
          "Success",
          "User has been successfully unblocked",
          MessageSeverity.success
        );
      },
      (error) => {
        this.isSaving = false;
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(
          "Unblock Error",
          "The below errors occured whilst unblocking the user:",
          MessageSeverity.error,
          error
        );
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
      }
    );
  }

  resetForm(replace = false) {
    this.isChangePassword = false;

    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  newUser(allRoles: Role[]) {
    this.isGeneralEditor = true;
    this.isNewUser = true;

    this.allRoles = [...allRoles];
    this.user = this.userEdit = new UserEdit();
    this.user.roles = [];
    //this.allRoles.forEach(f => {
    //  this.user.roles.push(f.name);
    //});
    this.userEdit.isEnabled = true;
    this.loadOrganisation();
    this.templListNew = [];
    this.edit();

    return this.userEdit;
  }

  LoadAssessmentByUser(id) {
    var promise = new Promise((resolve, reject) => {
      this.obj.isget = true;
      this.obj.userId = id;
      this.obj.isdatabyuser = "1";
      this.accountService.Set("Assessment", this.obj).subscribe(
        (results) => {
          const allcompassessmnt = results as any;
          allcompassessmnt.forEach(
            (allcompassessmnts, index, allcompassessmnt) => {
              (allcompassessmnts as any).index = index + 1;
            }
          );

          this.assessmentRowsCache = [...allcompassessmnt];
          this.assessmentRows = allcompassessmnt;
          resolve(this.assessmentRows);
        },
        (error) => {
          reject(Utilities.getHttpResponseMessages(error));
        }
      );
    });
    return promise;
  }

  downloadAssessmentReport(user: any) {
    this.LoadAssessmentByUser(user.id).then((assessmentData) => {
      this.prepareAssessmentReportAndExport(user, assessmentData);
    });
  }

  prepareAssessmentReportAndExport(user, assessmentData) {
    if (user != null && assessmentData != null) {
      //#region
      //EXCEL CREATION
      var title = "Digital Opportunity Assessment";
      var filename = "Digital Opportunity Assessment";

      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet("Data");

      worksheet.getRow(1).getCell(3).value = {
        richText: [
          {
            font: {
              name: "Arial",
              family: 4,
              size: 16,
              underline: "single",
              bold: true,
            },
            text: title,
          },
        ],
      };
      worksheet.getRow(1).getCell(3).alignment = { horizontal: "center" };
      worksheet.getRow(2).getCell(3).value = {
        richText: [
          {
            font: { bold: true },
            text: "Assessment executed by: " + user.email,
          },
        ],
      };
      worksheet.getRow(2).getCell(3).alignment = { horizontal: "center" };

      worksheet.addRow([]);

      worksheet.getColumn(1).width = 5;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(3).width = 50;
      worksheet.getColumn(4).width = 50;
      worksheet.getColumn(5).width = 50;
      worksheet.getColumn(6).width = 10;

      worksheet.addRow([]);

      var row = 5;
      var count = 1;

      assessmentData.forEach((assessments) => {
        var columnindex = 2;
        worksheet.getRow(row).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row).getCell(columnindex - 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row).getCell(columnindex - 1).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: count + "." },
          ],
        };
        worksheet.getRow(row).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row).getCell(columnindex).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: "Assessment name",
            },
          ],
        };

        worksheet.getRow(row).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        worksheet.getRow(row).getCell(columnindex + 1).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: assessments.tempName,
            },
          ],
        };

        // completion date
        worksheet.getRow(row + 1).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 1).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 1).getCell(columnindex).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: "Completion date",
            },
          ],
        };

        worksheet.getRow(row + 1).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 1).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 1).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        worksheet.getRow(row + 1).getCell(columnindex + 1).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: assessments.updatedDate,
            },
          ],
        };

        // Sector
        worksheet.getRow(row + 2).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 2).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 2).getCell(columnindex).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: "Sector" },
          ],
        };

        worksheet.getRow(row + 2).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 2).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 2).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        var _sector = "";
        if (
          assessments.sector &&
          assessments.sector != null &&
          typeof assessments.sector != "undefined"
        ) {
          _sector = assessments.sector;
        }

        worksheet.getRow(row + 2).getCell(columnindex + 1).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: _sector },
          ],
        };

        // SubSector
        worksheet.getRow(row + 3).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 3).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 3).getCell(columnindex).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: "Sub-Sector",
            },
          ],
        };

        worksheet.getRow(row + 3).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 3).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 3).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        var _subSector = "";
        if (
          assessments.subSector &&
          assessments.subSector != null &&
          typeof assessments.subSector != "undefined"
        ) {
          _subSector = assessments.subSector;
        }
        worksheet.getRow(row + 3).getCell(columnindex + 1).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: _subSector },
          ],
        };

        // Role
        worksheet.getRow(row + 4).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 4).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 4).getCell(columnindex).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: "Role" },
          ],
        };

        worksheet.getRow(row + 4).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 4).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 4).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        var _role = "";
        if (
          assessments.role &&
          assessments.role != null &&
          typeof assessments.role != "undefined"
        ) {
          _role = assessments.role;
        }
        worksheet.getRow(row + 4).getCell(columnindex + 1).value = {
          richText: [
            { font: { name: "Arial", family: 4, size: 10 }, text: _role },
          ],
        };

        // assessment type
        worksheet.getRow(row + 5).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 5).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 5).getCell(columnindex).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: "Assessment Type",
            },
          ],
        };

        worksheet.getRow(row + 5).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getRow(row + 5).getCell(columnindex + 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row + 5).getCell(columnindex + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };

        var _assessmentType = "";
        if (
          assessments.assessmentType &&
          assessments.assessmentType != null &&
          typeof assessments.assessmentType != "undefined"
        ) {
          _assessmentType = assessments.assessmentType;
        }
        worksheet.getRow(row + 5).getCell(columnindex + 1).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10 },
              text: _assessmentType,
            },
          ],
        };

        worksheet.addRow([]);
        worksheet.addRow([]);

        worksheet.getRow(row + 7).getCell(2).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10, bold: true },
              text: "Value Driver",
            },
          ],
        };
        worksheet.getRow(row + 7).getCell(3).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10, bold: true },
              text: "Value Lever",
            },
          ],
        };
        worksheet.getRow(row + 7).getCell(4).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10, bold: true },
              text: "Sub Lever",
            },
          ],
        };
        worksheet.getRow(row + 7).getCell(5).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10, bold: true },
              text: "Score (Current)",
            },
          ],
        };
        worksheet.getRow(row + 7).getCell(6).value = {
          richText: [
            {
              font: { name: "Arial", family: 4, size: 10, bold: true },
              text: "Score (Target)",
            },
          ],
        };
        row += 8;

        var _categoryValue = 0;
        var _categoryWeitage = 0;
        var _categoryTotal = 0;

        var userResponses = JSON.parse(assessments.userResponses);
        for (var i = 0; i < userResponses.length; i++) {
          var _dimension = "";
          var _subDimension = "";
          var _question = "";
          var _score = "";
          var _scoreTarget = "";

          _categoryValue += parseFloat(isNaN(userResponses[i].response) ? 0 : userResponses[i].response); //parseFloat(userResponses[i].response);
          _categoryWeitage += parseFloat(userResponses[i].benchmarkValue);
          _categoryTotal = (_categoryValue / _categoryWeitage) * 100;

          if (
            userResponses[i].columndata["Dimension"] &&
            userResponses[i].columndata["Dimension"] != null &&
            typeof userResponses[i].columndata["Dimension"] != "undefined"
          ) {
            _dimension = userResponses[i].columndata["Dimension"];
          }
          if (
            userResponses[i].columndata["Sub-Dimension"] &&
            userResponses[i].columndata["Sub-Dimension"] != null &&
            typeof userResponses[i].columndata["Sub-Dimension"] != "undefined"
          ) {
            _subDimension = userResponses[i].columndata["Sub-Dimension"];
          }
          if (
            userResponses[i].questiontext &&
            userResponses[i].questiontext != null &&
            typeof userResponses[i].questiontext != "undefined"
          ) {
            _question = userResponses[i].questiontext;
          }

          _score = isNaN(userResponses[i].response) ? 0 : userResponses[i].response;
          _scoreTarget = isNaN(userResponses[i].responsetarget) ? 0 : userResponses[i].responsetarget; // userResponses[i].responsetarget;

          var columnindex = 2;
          worksheet.getRow(row).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "left",
          };
          worksheet.getRow(row).getCell(columnindex).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d8d8d8" },
            bgColor: { argb: "d8d8d8" },
          };
          worksheet.getRow(row).getCell(columnindex).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: _dimension }],
          };

          columnindex++;
          worksheet.getRow(row).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "left",
          };
          worksheet.getRow(row).getCell(columnindex).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: _subDimension }],
          };
          columnindex++;

          worksheet.getRow(row).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "left",
          };
          worksheet.getRow(row).getCell(columnindex).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: _question }],
          };
          columnindex++;

          worksheet.getRow(row).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "left",
          };
          worksheet.getRow(row).getCell(columnindex).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: _score }],
          };
          columnindex++;

          worksheet.getRow(row).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "left",
          };
          worksheet.getRow(row).getCell(columnindex).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: _scoreTarget }],
          };

          row++;
        }

        worksheet.addRow([]);

        worksheet.getRow(row).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "left",
        };
        worksheet.getRow(row).getCell(columnindex - 1).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getRow(row).getCell(columnindex - 1).value = {
          richText: [{ text: "Overall Score" }],
        };

        worksheet.getRow(row).alignment = {
          wrapText: true,
          vertical: "middle",
          horizontal: "left",
        };
        worksheet.getRow(row).getCell(columnindex).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        if (isNaN(_categoryTotal) == false) {
          worksheet.getRow(row).getCell(columnindex).value = {
            richText: [{ text: Math.round(_categoryTotal) + "%" }],
          };
        }

        row++;
        row++;
        columnindex++;
        count++;
      });

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(
          blob,
          new Date().getTime() + "_" + filename + EXCEL_EXTENSION
        );
      });

      //end
      this.alertService.showStickyMessage(
        "Success",
        `Report data downloaded successfully.`,
        MessageSeverity.success
      );
    }
  }

  editUser(user: User, allRoles: Role[]) {
    if (user) {
      this.isGeneralEditor = true;
      this.isNewUser = false;

      this.setRoles(user, allRoles);
      this.user = new User();
      this.userEdit = new UserEdit();
      Object.assign(this.user, user);
      Object.assign(this.userEdit, user);
      this.user.orgId = user.orgId;
      this.userEdit.orgId = user.orgId;
      this.user.templId = user.templId;
      this.userEdit.templId = user.templId;
      this.edit();
      this.loadOrganisation();
      this.getselectedTemplate(this.userEdit.industrySector, 1);
      return this.userEdit;
    } else {
      this.loadOrganisation();
      return this.newUser(allRoles);
    }
  }

  displayUser(user: User, allRoles?: Role[]) {
    this.user = new User();
    Object.assign(this.user, user);
    this.deletePasswordFromUser(this.user);
    this.setRoles(user, allRoles);

    this.isEditMode = false;
  }

  private setRoles(user: User, allRoles?: Role[]) {
    this.allRoles = allRoles ? [...allRoles] : [];

    if (user.roles) {
      for (const ur of user.roles) {
        if (!this.allRoles.some((r) => r.name == ur)) {
          this.allRoles.unshift(new Role(ur));
        }
      }
    }

    if (allRoles == null || this.allRoles.length != allRoles.length) {
      setTimeout(() => {
        if (this.rolesSelector) {
          this.rolesSelector.refresh();
        }
      });
    }
  }

  get canViewAllRoles() {
    return this.accountService.userHasPermission(
      Permission.viewRolesPermission
    );
  }

  get canAssignRoles() {
    return this.accountService.userHasPermission(
      Permission.assignRolesPermission
    );
  }

  getselectedTemplate(industrysector: string, flag: number) {
    this.templListNew = [];
    let sectorWiseTemplate = this.templList.filter(
      x => x.industrySector == industrysector
    );
    if (sectorWiseTemplate.length > 0) {
      sectorWiseTemplate.forEach(s => {
        if (s.isDefault && !s.name.includes("(Default)")) {
          s.name = s.name + " " + "(Default)";
          this.userEdit.templId = s.id;
        }
        this.templListNew.push(s);
      });
    } else {
      this.userEdit.templId = null;
      //this.loadTemplates();
    }

    if (this.templListNew.length > 0) {
      if (flag == 0)
        this.userEdit.templId = this.templListNew[0].id;
    }
    else {
      this.userEdit.templId = null;
    }
  }

  clicked(event, i) {
    if (event.target.checked) {
      this.allRoles.forEach((f) => {
        f.isactive = false;
      });
      this.allRoles[i].isactive = true;
    }
  }
}
