import { Component, ViewChild } from "@angular/core";
import {
  AlertService,
  MessageSeverity,
  DialogType,
} from "../../services/alert.service";
import { AccountService } from "../../services/account.service";
import { Template, TemplateDtl } from "../../models/template.model";
import { AppComponent } from "../app.component";
import { Question } from "../../models/question.model";
import { Utilities } from "../../services/utilities";
import * as Enumerable from "../../assets/scripts/Linq/linq";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Router } from "@angular/router";
import { HttpClient, HttpEventType } from "@angular/common/http";
import * as XLSX from "xlsx";
import { DigitalLevers } from "../../models/digitallevers.model";
import { result, values } from "lodash";

@Component({
  selector: "template-editor",
  templateUrl: "./template-editor.component.html",
  styleUrls: ["./template-editor.component.scss"],
})
export class TemplateEditorComponent {
  willDownload = false;

  @ViewChild("f", { static: false })
  public changesSavedCallback: () => void;
  public changesFailedCallback: () => void;
  public changesCancelledCallback: () => void;

  columns: string[] = [];
  obj: Template = new Template();
  module: any = "Template";
  private isNewTemplate = false;
  public isSaving: boolean;
  public showValidationErrors = true;
  public templEdit: Template = new Template();
  public selectedValues: { [key: string]: boolean } = {};
  quenList: Template[] = [];
  rows: Question[] = [];
  public formResetToggle = true;
  public questionObj: Question = new Question();
  public versionlist: number[] = [];
  questionDtls: any;
  rowsCache: any;
  public moduleTree: any[] = [];
  moduleTreeCache: any[] = [];
  atStage: number = 0;
  private form;
  improvementAreaSection: any;
  observationSection: any;
  plSection: any;
  assumptionSection: any;
  opportunitySection: any;
  subdimensionlist: any;
  @ViewChild("digitalLeverModal", { static: false })
  digitalleverModal: ModalDirective;
  @ViewChild("calculationModal", { static: false })
  calculationModal: ModalDirective;
  opportunityIndex: number = null;
  public progress: number;
  public filename: string;
  protected formData: FormData;
  plDataList: any[] = [];
  saveexit: boolean = false;
  digitalleversobj: DigitalLevers = new DigitalLevers();
  public digitalleversalldata: DigitalLevers[] = [];
  industryDataList: any[] = [];
  leverDataList: any[] = [];
  inputLabelList: any[] = [];
  inputDataList: any[] = [];

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.moduleTree = [];
    this.isSaving = false;

    if (
      localStorage.getItem("tmplSelected") != null &&
      typeof localStorage.getItem("tmplSelected") != "undefined" &&
      localStorage.getItem("tmplTreeStuct") != null &&
      typeof localStorage.getItem("tmplTreeStuct") != "undefined"
    ) {
      this.moduleTree = JSON.parse(localStorage.getItem("tmplTreeStuct"));
      this.editTemplate(
        JSON.parse(localStorage.getItem("tmplSelected")) as Template
      );
    } else {
      this.newTemplate();
    }
  }

  ngAfterViewInit() {
    this.collapsibleFn();
  }

  collapsibleFn() {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel != null) {
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
          }
        }
      });
    }
  }

  toggleAccordianTree(event, index) {
    var element = event.target;
    element.classList.toggle("active");
    if (this.moduleTree[index].isActive) {
      this.moduleTree[index].isActive = false;
    } else {
      this.moduleTree[index].isActive = true;
    }
    var panel = element.nextElementSibling;

    if (panel != null) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }

  toggleAccordian(event, index) {
    var element = event.target;
    element.classList.toggle("active");
    if (this.templEdit.templateDtl[index].isActive) {
      this.templEdit.templateDtl[index].isActive = false;
    } else {
      this.templEdit.templateDtl[index].isActive = true;
    }
    var panel = element.nextElementSibling;

    if (panel != null) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }

  loadVersionList() {
    this.accountService.Set("question/GetVersions", this.questionObj).subscribe(
      (results) => {
        if (results) {
          this.versionlist = [];
          Object.assign(this.versionlist, results);
        }
      },
      (error) => {
        this.appComponent.failedHelper(error);
      }
    );
  }

  editTemplate(Temp: Template) {
    this.isNewTemplate = false;
    this.showValidationErrors = true;
    //this.editingAssessmenttemplName = Temp.name;
    this.selectedValues = {};

    this.templEdit = new Template();
    this.moduleTree = [];

    this.loadVersionList();

    Object.assign(this.templEdit, Temp);

    this.loadModuleTreeByVersion(true, this.templEdit.version.toString());

    return this.templEdit;
  }

  newTemplate() {
    this.isNewTemplate = true;
    this.showValidationErrors = true;
    //this.editingAssessmenttemplName = null;
    this.selectedValues = {};

    this.loadVersionList();

    this.templEdit = new Template();
    this.templEdit.templateDtl = [];

    return this.templEdit;
  }

  convertToTree(items) {
    var tree = [],
      stack = [];
    items.forEach(function (item) {
      var leaf = { text: item.lable, children: [] };
      if (stack.length <= item.level) stack.push(leaf);
      item.level ? stack[item.level - 1].children.push(leaf) : tree.push(leaf);
      stack[item.level] = leaf;
    });
    return tree;
  }

  loadModuleTreeByVersion(flag: boolean, version?: string) {
    if (version != "") {
      this.questionObj.isget = true;
      this.questionObj.version = parseInt(version);
      this.accountService.Set("question", this.questionObj).subscribe(
        (results) => {
          if (results) {
            Object.assign(this.questionObj, results);

            this.columns = [];

            this.columns.push("Dimension");
            this.columns.push("Sub-Dimension");

            if (this.questionObj.excelColumnName != null) {
              for (
                let i = 16;
                i < this.questionObj.excelColumnName.split(",").length;
                i++
              ) {
                this.columns.push(
                  this.questionObj.excelColumnName.split(",")[i]
                );
              }
            }

            var _rows = [];
            Enumerable.from(this.questionObj.questionDetails)
              .select((s) => s.questionDetailJSON)
              .forEach((f) => {
                let subset = this.columns.reduce(
                  (a, e) => ((a[e] = JSON.parse(f)[e]), a),
                  {}
                );
                let flag = false;
                _rows.forEach((r) => {
                  if (JSON.stringify(r) === JSON.stringify(subset)) {
                    flag = true;
                  }
                });
                if (flag == false) {
                  _rows.push(subset);
                }
              });

            this.moduleTreeCache = [..._rows];
            this.moduleTree = [];

            //check if moduletree present in localstorage
            if (
              localStorage.getItem("tmplTreeStuct") != null &&
              typeof localStorage.getItem("tmplTreeStuct") != "undefined"
            ) {
              this.moduleTree = JSON.parse(
                localStorage.getItem("tmplTreeStuct")
              );
            } else {
              if (this.columns.length > 0) {
                //Enumerable.from(this.moduleTreeCache).orderBy(o => o[this.columns[0]]).select(s => s[this.columns[0]]).distinct().toArray().forEach(f => {
                Enumerable.from(this.moduleTreeCache)
                  .select((s) => s[this.columns[0]])
                  .distinct()
                  .toArray()
                  .forEach((f) => {
                    let desc = Enumerable.from(
                      JSON.parse(this.questionObj.dimensionInfoJSON)
                    )
                      .where((x) => x["Dimension/Sub-Dimension"] == f)
                      .select((y) => y["Description"])
                      .firstOrDefault();
                    let obj = {
                      prop: this.columns[0],
                      name: f,
                      ischecked: false,
                      children: [],
                      level: 0,
                      description: desc,
                    };
                    this.moduleTree.push(obj);
                  });
              }
            }
            this.atStage = 1;
          }
        },
        (error) => {
          this.appComponent.failedHelper(error);
        }
      );
    } else {
      this.moduleTreeCache = [];
      this.moduleTree = [];
      this.atStage = 0;
    }
  }

  checkReverse(obj: any) {
    if (typeof obj.children != "undefined" && obj.children.length > 0) {
      let checkedChildCount = Enumerable.from(obj.children)
        .where((x) => x["ischecked"] == true)
        .count();
      obj.ischecked = checkedChildCount > 0 ? true : false;
      obj.children.forEach((f) => {
        this.checkReverse(f);
      });
    }
  }

  checkChild(obj: any) {
    if (typeof obj.children != "undefined" && obj.children.length > 0) {
      obj.children.forEach((c) => {
        c.ischecked = obj.ischecked;
        this.checkall(c);
      });
    }
  }

  checkall(obj: any) {
    var selectedItem = obj;
    var selectedLevel = selectedItem.level;

    if (this.columns.length > 0 && this.columns.length >= selectedLevel + 1) {
      selectedItem.children = [];

      Enumerable.from(this.moduleTreeCache)
        .where((x) => x[this.columns[selectedLevel]] == selectedItem.name)
        //.orderBy(o => o[this.columns[selectedLevel + 1]])
        .select((s) => s[this.columns[selectedLevel + 1]])
        .distinct()
        .toArray()
        .forEach((f) => {
          let desc = Enumerable.from(
            JSON.parse(this.questionObj.dimensionInfoJSON)
          )
            .where((x) => x["Dimension/Sub-Dimension"] == f)
            .select((y) => y["Description"])
            .firstOrDefault();
          let obj = {
            prop: this.columns[selectedLevel + 1],
            name: f,
            ischecked: false,
            children: [],
            level: selectedLevel + 1,
            description: desc,
          };
          if (typeof obj.prop != "undefined") {
            selectedItem.children.push(obj);
          }
        });
    }

    var flatArray = [];
    for (var i = 0; i < this.moduleTree.length; i++) {
      if (this.moduleTree[i].ischecked == true) {
        var item1 = this.moduleTree[i];
        for (var j = 0; j < this.moduleTree[i].children.length; j++) {
          if (this.moduleTree[i].children[j].ischecked == true) {
            var item2 = this.moduleTree[i].children[j];
            if (item2.children.length > 0) {
              for (
                var k = 0;
                k < this.moduleTree[i].children[j].children.length;
                k++
              ) {
                if (
                  this.moduleTree[i].children[j].children[k].ischecked == true
                ) {
                  var item3 = this.moduleTree[i].children[j].children[k];

                  Enumerable.from(this.questionObj.questionDetails).forEach(
                    (f) => {
                      var qObj = JSON.parse(f.questionDetailJSON);
                      if (
                        qObj[this.columns[0]] === item1.name &&
                        qObj[this.columns[1]] === item2.name &&
                        qObj[this.columns[2]] === item3.name
                      ) {
                        flatArray.push(f);

                        let obj = {
                          prop: "Question",
                          name: JSON.parse(f.questionDetailJSON).Question,
                          ischecked: false,
                          children: [],
                          level: 999,
                        };
                        this.moduleTree[i].children[j].children[k].children =
                          [];
                        if (
                          typeof this.moduleTree[i].children[j].children[k]
                            .children != "undefined"
                        ) {
                          this.moduleTree[i].children[j].children[
                            k
                          ].children.push(obj);
                        }
                      }
                    }
                  );
                }
              }
            } else {
              Enumerable.from(this.questionObj.questionDetails).forEach((f) => {
                var qObj = JSON.parse(f.questionDetailJSON);
                if (
                  qObj[this.columns[0]] === item1.name &&
                  qObj[this.columns[1]] === item2.name
                ) {
                  flatArray.push(f);

                  let obj = {
                    prop: "Question",
                    name: JSON.parse(f.questionDetailJSON).Question,
                    ischecked: false,
                    children: [],
                    level: 999,
                  };
                  //this.moduleTree[i].children[j].children = [];
                  if (
                    typeof this.moduleTree[i].children[j].children !=
                    "undefined"
                  ) {
                    this.moduleTree[i].children[j].children.push(obj);
                  }
                }
              });
            }
          }
        }
      }
    }

    if (obj.ischecked) {
      this.checkChild(obj);
    }

    this.moduleTree.forEach((f) => {
      this.checkReverse(f);
      if (typeof f.children != "undefined" && f.children.length > 0) {
        let checkedChildCount = Enumerable.from(f.children)
          .where((x) => x["ischecked"] == true)
          .count();
        f.ischecked = checkedChildCount > 0 ? true : false;
      }
    });
  }

  contains(arr, key, val) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][key] === val) return true;
    }
    return false;
  }

  loadAllQuestions() {
    if (this.moduleTree) {
      this.templEdit.templateDtl = [];
      this.columns.push("Question");
      var flatArray = [];
      for (var i = 0; i < this.moduleTree.length; i++) {
        if (this.moduleTree[i].ischecked == true) {
          var item1 = this.moduleTree[i];
          for (var j = 0; j < this.moduleTree[i].children.length; j++) {
            if (this.moduleTree[i].children[j].ischecked == true) {
              var item2 = this.moduleTree[i].children[j];

              if (item2.children.length > 0) {
                for (
                  var k = 0;
                  k < this.moduleTree[i].children[j].children.length;
                  k++
                ) {
                  if (
                    this.moduleTree[i].children[j].children[k].ischecked == true
                  ) {
                    var item3 = this.moduleTree[i].children[j].children[k];

                    Enumerable.from(this.questionObj.questionDetails).forEach(
                      (f) => {
                        var qObj = JSON.parse(f.questionDetailJSON);
                        if (
                          qObj[this.columns[0]] === item1.name &&
                          qObj[this.columns[1]] === item2.name &&
                          qObj[this.columns[2]] === item3.name
                        ) {
                          flatArray.push(f);
                        }
                      }
                    );
                  }
                }
              } else {
                Enumerable.from(this.questionObj.questionDetails).forEach(
                  (f) => {
                    var qObj = JSON.parse(f.questionDetailJSON);
                    if (
                      qObj[this.columns[0]] === item1.name &&
                      qObj[this.columns[1]] === item2.name
                    ) {
                      flatArray.push(f);
                    }
                  }
                );
              }
            }
          }
        }
      }
      flatArray.forEach((r) => {
        let _new = new TemplateDtl();

        _new.columndata = JSON.parse(r.questionDetailJSON);
        _new.isChecked = true;
        _new.questionid = r.questionid;
        _new.customdbid = r.customdbid;
        _new.questiontext = JSON.parse(r.questionDetailJSON).Question;
        _new.questionCategory = JSON.parse(r.questionDetailJSON)[
          "Question Category"
        ];
        _new.weightage = JSON.parse(r.questionDetailJSON)["Weightage"];
        _new.benchmarkValue = JSON.parse(r.questionDetailJSON)[
          "Benchmark Value"
        ];
        _new.maturitylevel1 = JSON.parse(r.questionDetailJSON)[
          "Maturity Level1"
        ];
        _new.maturitylevel2 = JSON.parse(r.questionDetailJSON)[
          "Maturity Level2"
        ];
        _new.maturitylevel3 = JSON.parse(r.questionDetailJSON)[
          "Maturity Level3"
        ];
        _new.maturitylevel4 = JSON.parse(r.questionDetailJSON)[
          "Maturity Level4"
        ];
        _new.maturitylevel5 = JSON.parse(r.questionDetailJSON)[
          "Maturity Level5"
        ];

        this.templEdit.templateDtl.push(_new);
      });

      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .questionStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .questionStructure != "undefined"
      ) {
        let savedQuestions = JSON.parse(
          (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
            .questionStructure
        ) as TemplateDtl[];

        savedQuestions.forEach((f) => {
          if (
            Enumerable.from(this.templEdit.templateDtl)
              .where((x) => x.customdbid == f.customdbid)
              .any()
          ) {
            let x = Enumerable.from(this.templEdit.templateDtl)
              .where((x) => x.customdbid == f.customdbid)
              .firstOrDefault();
            x.isChecked = f.isChecked;
            x.weightage = f.weightage;
            x.benchmarkValue = f.benchmarkValue;
            x.questionCategory = f.questionCategory;
            x.maturitylevel1 = f.maturitylevel1;
            x.maturitylevel2 = f.maturitylevel2;
            x.maturitylevel3 = f.maturitylevel3;
            x.maturitylevel4 = f.maturitylevel4;
            x.maturitylevel5 = f.maturitylevel5;
          }
        });
      }
    }

    if (this.templEdit.templateDtl.length > 0 && this.atStage == 1) {
      this.collapsibleFn();
      this.atStage++;
    }
  }

  loadBack() {
    if (
      (this.isNewTemplate && this.atStage == 0) ||
      (!this.isNewTemplate && this.atStage == 1)
    ) {
      this.router.navigate(["template"]);
    } else {
      if (this.atStage > 0) this.atStage--;

      if (this.atStage == 1) {
        if (
          localStorage.getItem("tmplSelected") != null &&
          typeof localStorage.getItem("tmplSelected") != "undefined" &&
          localStorage.getItem("tmplTreeStuct") != null &&
          typeof localStorage.getItem("tmplTreeStuct") != "undefined"
        ) {
          this.moduleTree = JSON.parse(localStorage.getItem("tmplTreeStuct"));

          this.editTemplate(
            JSON.parse(localStorage.getItem("tmplSelected")) as Template
          );
        }
      }
    }
  }

  loadNext() {
    if (this.atStage == 0) {
      this.checkStage0();
    }

    if (this.atStage == 1) {
      this.saveStage1();
    }

    if (this.atStage == 2) {
      this.saveStage2();
    }

    if (this.atStage == 3) {
      this.saveStage3();
    }

    if (this.atStage == 4) {
      this.saveStage4();
    }

    if (this.atStage == 5) {
      this.saveStage5();
    }
  }

  loadNextExit() {
    this.saveexit = true;
    if (this.atStage == 0) {
      this.checkStage0();
    }

    if (this.atStage == 1) {
      this.saveStage1();
    }

    if (this.atStage == 2) {
      this.saveStage2();
    }

    if (this.atStage == 3) {
      this.saveStage3();
    }

    if (this.atStage == 4) {
      this.saveStage4();
    }

    if (this.atStage == 5) {
      this.saveStage5();
    }
  }

  checkStage0() {
    if (
      this.templEdit.name == null ||
      typeof this.templEdit.name == "undefined" ||
      this.templEdit.name.length == 0
    ) {
      this.alertService.showDialog(
        "Template name is required.",
        DialogType.alert
      );
      return;
    }
    if (
      this.templEdit.name != "" &&
      this.templEdit.name != null &&
      typeof this.templEdit.name != "undefined"
    ) {
      if (this.templEdit.name.trim() == "") {
        this.alertService.showDialog(
          "Please enter a valid template name.",
          DialogType.alert
        );
        return;
      }
    }
    if (
      this.templEdit.description != "" &&
      this.templEdit.description != null &&
      typeof this.templEdit.description != "undefined"
    ) {
      if (this.templEdit.description.trim() == "") {
        this.alertService.showDialog(
          "Please enter a valid description.",
          DialogType.alert
        );
        return;
      }
    }
    if (
      this.templEdit.industrySector == "" ||
      this.templEdit.industrySector == null ||
      typeof this.templEdit.industrySector == "undefined"
    ) {
      this.alertService.showDialog(
        "Please select industry sector",
        DialogType.alert
      );
      return;
    }
    if (
      this.templEdit.defaultScore == null ||
      typeof this.templEdit.defaultScore == "undefined"
    ) {
      this.alertService.showDialog(
        "Please enter a valid default score",
        DialogType.alert
      );
      return;
    }
    if (
      this.templEdit.versionName == "" ||
      this.templEdit.versionName == null ||
      typeof this.templEdit.versionName == "undefined"
    ) {
      this.alertService.showDialog(
        "Please select the DB version",
        DialogType.alert
      );
      return;
    }
  }

  saveStage1() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.industrySector == "" ||
          this.templEdit.industrySector == null ||
          typeof this.templEdit.industrySector == "undefined"
        ) {
          this.alertService.showDialog(
            "Please select industry sector",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.defaultScore == null ||
          typeof this.templEdit.defaultScore == "undefined"
        ) {
          this.alertService.showDialog(
            "Please enter a valid default score",
            DialogType.alert
          );
          return;
        }

        if (
          this.templEdit.defaultScore < 0 ||
          this.templEdit.defaultScore > 5
        ) {
          this.alertService.showDialog(
            "Specified value for the default score is not a numeric number in the range of 0 to 5.",
            DialogType.alert
          );
          return;
        }

        let checkcount = 0;
        this.moduleTree.forEach((m) => {
          if (m["ischecked"] == true) {
            checkcount = checkcount + 1;
          }
        });
        if (checkcount == 0) {
          this.alertService.showDialog(
            "You must select a module to proceed further.",
            DialogType.alert
          );
          return;
        }
        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        this.templEdit.treeStructure = JSON.stringify(this.moduleTree);

        this.accountService.Set(this.module, this.templEdit).subscribe(
          (results) => {
            if (results) {
              Object.assign(this.templEdit, results);
            }
            localStorage.removeItem("tmplTreeStuct");
            localStorage.setItem(
              "tmplTreeStuct",
              JSON.stringify(this.moduleTree)
            );
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            if (this.saveexit == false) {
              this.loadAllQuestions();
            } else {
              this.router.navigate(["template"]);
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  saveStage2() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.industrySector == "" ||
          this.templEdit.industrySector == null ||
          typeof this.templEdit.industrySector == "undefined"
        ) {
          this.alertService.showDialog(
            "Please select industry sector",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.defaultScore == null ||
          typeof this.templEdit.defaultScore == "undefined"
        ) {
          this.alertService.showDialog(
            "Please enter a valid default score",
            DialogType.alert
          );
          return;
        }

        if (
          this.templEdit.defaultScore < 0 ||
          this.templEdit.defaultScore > 5
        ) {
          this.alertService.showDialog(
            "Specified value for the default score is not a numeric number in the range of 0 to 5.",
            DialogType.alert
          );
          return;
        }
        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        this.templEdit.questionStructure = JSON.stringify(
          this.templEdit.templateDtl
        );

        this.accountService.Set(this.module, this.templEdit).subscribe(
          () => {
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            if (this.saveexit == false) {
              this.loadDefineImprovementArea();
              this.loadDefineObservation();
            } else {
              this.router.navigate(["template"]);
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  saveStage3() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.industrySector == "" ||
          this.templEdit.industrySector == null ||
          typeof this.templEdit.industrySector == "undefined"
        ) {
          this.alertService.showDialog(
            "Please select industry sector",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.defaultScore == null ||
          typeof this.templEdit.defaultScore == "undefined"
        ) {
          this.alertService.showDialog(
            "Please enter a valid default score",
            DialogType.alert
          );
          return;
        }

        if (
          this.templEdit.defaultScore < 0 ||
          this.templEdit.defaultScore > 5
        ) {
          this.alertService.showDialog(
            "Specified value for the default score is not a numeric number in the range of 0 to 5.",
            DialogType.alert
          );
          return;
        }
        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        this.templEdit.improvementAreaStructure = JSON.stringify(
          this.improvementAreaSection
        );
        this.templEdit.observationStructure = JSON.stringify(
          this.observationSection
        );

        this.accountService.Set(this.module, this.templEdit).subscribe(
          () => {
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            if (this.saveexit == false) {
              this.loadDefinePL();
            } else {
              this.router.navigate(["template"]);
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  saveStage4() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.industrySector == "" ||
          this.templEdit.industrySector == null ||
          typeof this.templEdit.industrySector == "undefined"
        ) {
          this.alertService.showDialog(
            "Please select industry sector",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.defaultScore == null ||
          typeof this.templEdit.defaultScore == "undefined"
        ) {
          this.alertService.showDialog(
            "Please enter a valid default score",
            DialogType.alert
          );
          return;
        }

        if (
          this.templEdit.defaultScore < 0 ||
          this.templEdit.defaultScore > 5
        ) {
          this.alertService.showDialog(
            "Specified value for the default score is not a numeric number in the range of 0 to 5.",
            DialogType.alert
          );
          return;
        }

        let isPlSectionNameRequire: boolean = false;
        if (
          this.plSection != null &&
          typeof this.plSection != "undefined" &&
          this.plSection.length != 0
        ) {
          if (
            this.plSection.children01 != null &&
            typeof this.plSection.children01 != "undefined" &&
            this.plSection.children01.length != 0
          ) {
            this.plSection.children01.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children02 != null &&
            typeof this.plSection.children02 != "undefined" &&
            this.plSection.children02.length != 0
          ) {
            this.plSection.children02.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children03 != null &&
            typeof this.plSection.children03 != "undefined" &&
            this.plSection.children03.length != 0
          ) {
            this.plSection.children03.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children04 != null &&
            typeof this.plSection.children04 != "undefined" &&
            this.plSection.children04.length != 0
          ) {
            this.plSection.children04.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children05 != null &&
            typeof this.plSection.children05 != "undefined" &&
            this.plSection.children05.length != 0
          ) {
            this.plSection.children05.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children06 != null &&
            typeof this.plSection.children06 != "undefined" &&
            this.plSection.children06.length != 0
          ) {
            this.plSection.children06.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children07 != null &&
            typeof this.plSection.children07 != "undefined" &&
            this.plSection.children07.length != 0
          ) {
            this.plSection.children07.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children08 != null &&
            typeof this.plSection.children08 != "undefined" &&
            this.plSection.children08.length != 0
          ) {
            this.plSection.children08.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children09 != null &&
            typeof this.plSection.children09 != "undefined" &&
            this.plSection.children09.length != 0
          ) {
            this.plSection.children09.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
          if (
            this.plSection.children10 != null &&
            typeof this.plSection.children10 != "undefined" &&
            this.plSection.children10.length != 0
          ) {
            this.plSection.children10.forEach((m) => {
              if (m.name.trim() == "") {
                isPlSectionNameRequire = true;
              }
            });
          }
        }

        if (isPlSectionNameRequire) {
          this.alertService.showDialog(
            "P&L items name missing",
            DialogType.alert
          );
          return;
        }

        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        this.templEdit.plStructure = JSON.stringify(this.plSection);

        this.accountService.Set(this.module, this.templEdit).subscribe(
          () => {
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            if (this.saveexit == false) {
              this.loadDefineAssumption();
            } else {
              this.router.navigate(["template"]);
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  saveStage5() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.industrySector == "" ||
          this.templEdit.industrySector == null ||
          typeof this.templEdit.industrySector == "undefined"
        ) {
          this.alertService.showDialog(
            "Please select industry sector",
            DialogType.alert
          );
          return;
        }
        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        this.templEdit.assumptionStructure = JSON.stringify(
          this.assumptionSection
        );

        this.accountService.Set(this.module, this.templEdit).subscribe(
          () => {
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            if (this.saveexit == false) {
              this.loadDefineOpportunity();
            } else {
              this.router.navigate(["template"]);
            }
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  loadDefineImprovementArea() {
    if (this.atStage === 2) {
      this.improvementAreaSection = [];
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .improvementAreaStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .improvementAreaStructure != "undefined"
      ) {
        Object.assign(
          this.improvementAreaSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .improvementAreaStructure
          )
        );
      }
      this.templEdit.improvementAreaStructure = JSON.stringify(
        this.improvementAreaSection
      );
    }
  }

  loadDefineObservation() {
    if (this.atStage === 2) {
      this.observationSection = [];
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .observationStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .observationStructure != "undefined"
      ) {
        Object.assign(
          this.observationSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .observationStructure
          )
        );
      }
      this.templEdit.observationStructure = JSON.stringify(
        this.observationSection
      );
    }
    this.atStage++;
  }

  loadDefinePL() {
    if (this.atStage === 3) {
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != "undefined"
      ) {
        this.plSection = {};
        Object.assign(
          this.plSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .plStructure
          )
        );
      } else {
        this.plSection = {
          currentYear: new Date().getFullYear(),
          targetYear: new Date().getFullYear() + 4,
          growth: 15,
          children01: [],
          children02: [],
          children03: [],
          children04: [],
          children05: [],
          children06: [],
          children07: [],
          children08: [],
          children09: [],
          children10: [],
        };
        this.plSection.children01.push({
          name: "Revenues from Non Digital products",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children01.push({
          name: "Revenues from Digital Products",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children02.push({
          name: "",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children03.push({
          name: "Total Revenues",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children04.push({
          name: "",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children05.push({
          name: "Contribution Margin",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children06.push({
          name: "",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children07.push({
          name: "Gross Margin",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children08.push({
          name: "",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children09.push({
          name: "",
          description: "",
          value1: 0,
          value2: 0,
        });
        this.plSection.children10.push({
          name: "EBITDA",
          description: "",
          value1: 0,
          value2: 0,
        });
      }

      this.atStage++;
    }
  }

  calculatePL() {
    if (
      this.plSection != null &&
      typeof this.plSection != "undefined" &&
      this.plSection.length != 0
    ) {
      if (
        this.plSection.children01 != null &&
        typeof this.plSection.children01 != "undefined" &&
        this.plSection.children01.length != 0
      ) {
        this.plSection.children01.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children02 != null &&
        typeof this.plSection.children02 != "undefined" &&
        this.plSection.children02.length != 0
      ) {
        this.plSection.children02.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children03 != null &&
        typeof this.plSection.children03 != "undefined" &&
        this.plSection.children03.length != 0
      ) {
        this.plSection.children03.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children04 != null &&
        typeof this.plSection.children04 != "undefined" &&
        this.plSection.children04.length != 0
      ) {
        this.plSection.children04.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children05 != null &&
        typeof this.plSection.children05 != "undefined" &&
        this.plSection.children05.length != 0
      ) {
        this.plSection.children05.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children06 != null &&
        typeof this.plSection.children06 != "undefined" &&
        this.plSection.children06.length != 0
      ) {
        this.plSection.children06.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children07 != null &&
        typeof this.plSection.children07 != "undefined" &&
        this.plSection.children07.length != 0
      ) {
        this.plSection.children07.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children08 != null &&
        typeof this.plSection.children08 != "undefined" &&
        this.plSection.children08.length != 0
      ) {
        this.plSection.children08.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children09 != null &&
        typeof this.plSection.children09 != "undefined" &&
        this.plSection.children09.length != 0
      ) {
        this.plSection.children09.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (
        this.plSection.children10 != null &&
        typeof this.plSection.children10 != "undefined" &&
        this.plSection.children10.length != 0
      ) {
        this.plSection.children10.forEach((f) => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
    }

    let level1sum01: number = 0;
    let level1sum02: number = 0;
    let level1sum04: number = 0;
    let level1sum06: number = 0;
    let level1sum08: number = 0;
    let level1sum09: number = 0;
    let level2sum01: number = 0;
    let level2sum02: number = 0;
    let level2sum04: number = 0;
    let level2sum06: number = 0;
    let level2sum08: number = 0;
    let level2sum09: number = 0;
    if (
      this.plSection != null &&
      typeof this.plSection != "undefined" &&
      this.plSection.length != 0
    ) {
      if (
        this.plSection.children01 != null &&
        typeof this.plSection.children01 != "undefined" &&
        this.plSection.children01.length != 0
      ) {
        level1sum01 = this.plSection.children01
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children02 != null &&
        typeof this.plSection.children02 != "undefined" &&
        this.plSection.children02.length != 0
      ) {
        level1sum02 = this.plSection.children02
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children04 != null &&
        typeof this.plSection.children04 != "undefined" &&
        this.plSection.children04.length != 0
      ) {
        level1sum04 = this.plSection.children04
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children06 != null &&
        typeof this.plSection.children06 != "undefined" &&
        this.plSection.children06.length != 0
      ) {
        level1sum06 = this.plSection.children06
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children08 != null &&
        typeof this.plSection.children08 != "undefined" &&
        this.plSection.children08.length != 0
      ) {
        level1sum08 = this.plSection.children08
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children09 != null &&
        typeof this.plSection.children09 != "undefined" &&
        this.plSection.children09.length != 0
      ) {
        level1sum09 = this.plSection.children09
          .map((a) => a.value1)
          .reduce(function (a, b) {
            return a + b;
          });
      }
    }
    this.plSection.children03[0].value1 =
      Math.round((level1sum01 + level1sum02) * 100) / 100;
    this.plSection.children05[0].value1 =
      Math.round((level1sum01 + level1sum02 - level1sum04) * 100) / 100;
    this.plSection.children07[0].value1 =
      Math.round(
        (level1sum01 + level1sum02 - level1sum04 - level1sum06) * 100
      ) / 100;
    this.plSection.children10[0].value1 =
      Math.round(
        (level1sum01 +
          level1sum02 -
          level1sum04 -
          level1sum06 -
          level1sum08 -
          level1sum09) *
          100
      ) / 100;

    for (var i = 0; i < this.plSection.children01.length; i++) {
      this.plSection.children01[i].value2 =
        Math.round(
          this.plSection.children01[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    for (var i = 0; i < this.plSection.children02.length; i++) {
      this.plSection.children02[i].value2 =
        Math.round(
          this.plSection.children02[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    for (var i = 0; i < this.plSection.children04.length; i++) {
      this.plSection.children04[i].value2 =
        Math.round(
          this.plSection.children04[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    for (var i = 0; i < this.plSection.children06.length; i++) {
      this.plSection.children06[i].value2 =
        Math.round(
          this.plSection.children06[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    for (var i = 0; i < this.plSection.children08.length; i++) {
      this.plSection.children08[i].value2 =
        Math.round(
          this.plSection.children08[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    for (var i = 0; i < this.plSection.children09.length; i++) {
      this.plSection.children09[i].value2 =
        Math.round(
          this.plSection.children09[i].value1 *
            Math.pow(
              1 + this.plSection.growth / 100,
              this.plSection.targetYear - this.plSection.currentYear
            ) *
            100
        ) / 100;
    }
    if (
      this.plSection != null &&
      typeof this.plSection != "undefined" &&
      this.plSection.length != 0
    ) {
      if (
        this.plSection.children01 != null &&
        typeof this.plSection.children01 != "undefined" &&
        this.plSection.children01.length != 0
      ) {
        level2sum01 = this.plSection.children01
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children02 != null &&
        typeof this.plSection.children02 != "undefined" &&
        this.plSection.children02.length != 0
      ) {
        level2sum02 = this.plSection.children02
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children04 != null &&
        typeof this.plSection.children04 != "undefined" &&
        this.plSection.children04.length != 0
      ) {
        level2sum04 = this.plSection.children04
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children06 != null &&
        typeof this.plSection.children06 != "undefined" &&
        this.plSection.children06.length != 0
      ) {
        level2sum06 = this.plSection.children06
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children08 != null &&
        typeof this.plSection.children08 != "undefined" &&
        this.plSection.children08.length != 0
      ) {
        level2sum08 = this.plSection.children08
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
      if (
        this.plSection.children09 != null &&
        typeof this.plSection.children09 != "undefined" &&
        this.plSection.children09.length != 0
      ) {
        level2sum09 = this.plSection.children09
          .map((a) => a.value2)
          .reduce(function (a, b) {
            return a + b;
          });
      }
    }
    this.plSection.children03[0].value2 =
      Math.round((level2sum01 + level2sum02) * 100) / 100;
    this.plSection.children05[0].value2 =
      Math.round((level2sum01 + level2sum02 - level2sum04) * 100) / 100;
    this.plSection.children07[0].value2 =
      Math.round(
        (level2sum01 + level2sum02 - level2sum04 - level2sum06) * 100
      ) / 100;
    this.plSection.children10[0].value2 =
      Math.round(
        (level2sum01 +
          level2sum02 -
          level2sum04 -
          level2sum06 -
          level2sum08 -
          level2sum09) *
          100
      ) / 100;
  }

  loadDefineAssumption() {
    if (this.atStage === 4) {
      this.loadPlData();

      this.assumptionSection = [];
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .assumptionStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .assumptionStructure != "undefined"
      ) {
        Object.assign(
          this.assumptionSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .assumptionStructure
          )
        );
      } else {
        this.assumptionSection.push({
          id: 1,
          kpi: "",
          assumption: "",
          input: "",
          value: "",
        });
      }

      this.atStage++;
    }
  }

  loadPlData() {
    this.plDataList = [];
    if (
      this.plSection != null &&
      typeof this.plSection != "undefined" &&
      this.plSection.length != 0
    ) {
      if (
        this.plSection.children01 != null &&
        typeof this.plSection.children01 != "undefined" &&
        this.plSection.children01.length != 0
      ) {
        this.plSection.children01.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children02 != null &&
        typeof this.plSection.children02 != "undefined" &&
        this.plSection.children02.length != 0
      ) {
        this.plSection.children02.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children03 != null &&
        typeof this.plSection.children03 != "undefined" &&
        this.plSection.children03.length != 0
      ) {
        this.plSection.children03.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children04 != null &&
        typeof this.plSection.children04 != "undefined" &&
        this.plSection.children04.length != 0
      ) {
        this.plSection.children04.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children05 != null &&
        typeof this.plSection.children05 != "undefined" &&
        this.plSection.children05.length != 0
      ) {
        this.plSection.children05.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children06 != null &&
        typeof this.plSection.children06 != "undefined" &&
        this.plSection.children06.length != 0
      ) {
        this.plSection.children06.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children07 != null &&
        typeof this.plSection.children07 != "undefined" &&
        this.plSection.children07.length != 0
      ) {
        this.plSection.children07.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children08 != null &&
        typeof this.plSection.children08 != "undefined" &&
        this.plSection.children08.length != 0
      ) {
        this.plSection.children08.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children09 != null &&
        typeof this.plSection.children09 != "undefined" &&
        this.plSection.children09.length != 0
      ) {
        this.plSection.children09.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (
        this.plSection.children10 != null &&
        typeof this.plSection.children10 != "undefined" &&
        this.plSection.children10.length != 0
      ) {
        this.plSection.children10.forEach((f) => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
    }
  }

  loadInputData() {
    this.inputDataList = [];
    if (
      this.plSection != null &&
      typeof this.plSection != "undefined" &&
      this.plSection.length != 0
    ) {
      if (
        this.plSection.children01 != null &&
        typeof this.plSection.children01 != "undefined" &&
        this.plSection.children01.length != 0
      ) {
        this.plSection.children01.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children02 != null &&
        typeof this.plSection.children02 != "undefined" &&
        this.plSection.children02.length != 0
      ) {
        this.plSection.children02.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children03 != null &&
        typeof this.plSection.children03 != "undefined" &&
        this.plSection.children03.length != 0
      ) {
        this.plSection.children03.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children04 != null &&
        typeof this.plSection.children04 != "undefined" &&
        this.plSection.children04.length != 0
      ) {
        this.plSection.children04.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children05 != null &&
        typeof this.plSection.children05 != "undefined" &&
        this.plSection.children05.length != 0
      ) {
        this.plSection.children05.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children06 != null &&
        typeof this.plSection.children06 != "undefined" &&
        this.plSection.children06.length != 0
      ) {
        this.plSection.children06.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children07 != null &&
        typeof this.plSection.children07 != "undefined" &&
        this.plSection.children07.length != 0
      ) {
        this.plSection.children07.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children08 != null &&
        typeof this.plSection.children08 != "undefined" &&
        this.plSection.children08.length != 0
      ) {
        this.plSection.children08.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children09 != null &&
        typeof this.plSection.children09 != "undefined" &&
        this.plSection.children09.length != 0
      ) {
        this.plSection.children09.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }
      if (
        this.plSection.children10 != null &&
        typeof this.plSection.children10 != "undefined" &&
        this.plSection.children10.length != 0
      ) {
        this.plSection.children10.forEach((f) => {
          this.inputDataList.push(f.name);
        });
      }

      if (
        this.assumptionSection != null &&
        typeof this.assumptionSection != "undefined" &&
        this.assumptionSection.length != 0
      ) {
        this.assumptionSection.forEach((f) => {
          this.inputDataList.push(f.kpi);
        });
      }

      this.inputDataList.push("Impact value");
    }
  }

  calculateAssumption() {
    if (
      this.assumptionSection != null &&
      typeof this.assumptionSection != "undefined" &&
      this.assumptionSection.length != 0
    ) {
      this.assumptionSection.forEach((f) => {
        if (isNaN(f.assumption) || f.assumption == "" || f.assumption == null)
          f.assumption = 0;
        if (f.input != "" && f.input != null)
          f.value =
            Math.round(
              ((Enumerable.from(this.plDataList)
                .where((x) => x.name == f.input)
                .select((y) => y.value1)
                .firstOrDefault() *
                f.assumption) /
                100) *
                100
            ) / 100;
        else f.value = 0;
      });
    }
  }

  loadDefineOpportunity() {
    if (this.atStage === 5) {
      this.subdimensionlist = [];
      for (var i = 0; i < this.moduleTree.length; i++) {
        if (this.moduleTree[i].ischecked == true) {
          for (var j = 0; j < this.moduleTree[i].children.length; j++) {
            if (this.moduleTree[i].children[j].ischecked == true) {
              this.subdimensionlist.push({
                dimension: this.moduleTree[i].name,
                subdimension: this.moduleTree[i].children[j].name,
              });
            }
          }
        }
      }

      this.loadInputData();
      this.loadBusinessUnits();
      this.opportunitySection = [];
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .opportunityStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .opportunityStructure != "undefined"
      ) {
        Object.assign(
          this.opportunitySection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .opportunityStructure
          )
        );
        var i = 0;
        this.opportunitySection.forEach((item) => {
          this.validateFormula(i, item.opportunityformula);
          i++;
        });
      } else {
      }

      this.atStage++;
    }
  }

  loadBusinessUnits() {
    this.digitalleversobj.isget = true;
    this.accountService.Set("Digitallevers", this.digitalleversobj).subscribe(
      (results) => {
        if (results) {
          this.industryDataList = [];
          Object.assign(this.digitalleversalldata, results);
          Enumerable.from(this.digitalleversalldata)
            .where((x) => x.isenabled == true)
            .distinct((y) => y.industry)
            .orderBy((z) => z.industry)
            .forEach((f) => {
              this.industryDataList.push(f.industry);
            });
          if (
            localStorage.getItem("tmplSelected") == null ||
            typeof localStorage.getItem("tmplSelected") == "undefined" ||
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .opportunityStructure == null ||
            typeof (
              JSON.parse(localStorage.getItem("tmplSelected")) as Template
            ).opportunityStructure == "undefined"
          ) {
            var i = 0;
            Enumerable.from(this.digitalleversalldata)
              .where((x) => x.isenabled == true)
              .orderBy((z) => z.serialno)
              .forEach((f) => {
                this.opportunitySection.push({
                  id: f.serialno,
                  keytechnologies: f.keytechnologies,
                  challenges: f.challenges,
                  advantages: f.advantages,
                  domain: f.domain,
                  subdomain: f.subdomain,
                  industry: f.industry,
                  industrylist: this.industryDataList,
                  lever: f.lever,
                  leverlist: Enumerable.from(this.digitalleversalldata)
                    .where(
                      (x) => x.isenabled == true && x.industry == f.industry
                    )
                    .orderBy((z) => z.lever)
                    .select((y) => y.lever)
                    .toArray(),
                  opportunityformula: f.opportunityformula,
                  subdimension: "",
                });
                this.validateFormula(
                  i,
                  this.opportunitySection[i].opportunityformula
                );
                i++;
              });
          }
        }
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve portfolio inputs from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  loadLevers(index, val) {
    this.opportunitySection[index].lever = "";
    this.opportunitySection[index].opportunityformula = "";
    this.leverDataList = [];
    if (val != "" && val != null && typeof val != "undefined") {
      Enumerable.from(this.digitalleversalldata)
        .where((x) => x.isenabled == true && x.industry == val)
        .orderBy((z) => z.lever)
        .forEach((f) => {
          this.leverDataList.push(f.lever);
        });
    }
    this.opportunitySection[index].leverlist = this.leverDataList;
    this.validateFormula(
      index,
      this.opportunitySection[index].opportunityformula
    );
  }

  loadFormula(index, val) {
    if (val != "" && val != null && typeof val != "undefined") {
      this.opportunitySection[index].opportunityformula = Enumerable.from(
        this.digitalleversalldata
      )
        .where(
          (x) =>
            x.isenabled == true &&
            x.industry == this.opportunitySection[index].industry &&
            x.lever == val
        )
        .select((y) => {
          this.opportunitySection[index].keytechnologies = y.keytechnologies;
          this.opportunitySection[index].challenges = y.challenges;
          this.opportunitySection[index].advantages = y.advantages;
          this.opportunitySection[index].domain = y.domain;
          this.opportunitySection[index].subdomain = y.subdomain;
          return y.opportunityformula;
        })
        .firstOrDefault();
      this.validateFormula(
        index,
        this.opportunitySection[index].opportunityformula
      );
    } else {
      this.opportunitySection[index].opportunityformula = "";
      this.validateFormula(
        index,
        this.opportunitySection[index].opportunityformula
      );
    }
  }

  validateFormula(index, formula) {
    if (formula) {
      var x = formula.split(/[\()*/+-]+/g);
      var y = formula.match(/[\()*/+-]+/g);
      var z = "";

      for (var i = 0; i < x.length; i++) {
        if (x[i] != "" && x[i] != null && typeof x[i] != "undefined") {
          if (isNaN(x[i])) {
            if (this.inputDataList.includes(x[i]))
              z +=
                '<span class="alert alert-success" style="padding: 0.25rem !important;">' +
                x[i] +
                "</span>";
            else
              z +=
                '<span class="alert alert-danger" style="padding: 0.25rem !important;">' +
                x[i] +
                "</span>";
          } else {
            z +=
              '<span class="alert alert-success" style="padding: 0.25rem !important;">' +
              x[i] +
              "</span>";
          }
        }
        if (y[i] != "" && y[i] != null && typeof y[i] != "undefined") {
          z +=
            '<span class="alert alert-dark" style="padding: 0.25rem !important;">' +
            y[i] +
            "</span>";
        }
      }

      setTimeout(function () {
        $("#divFormula" + index).html(z);
      }, 500);
    } else {
      $("#divFormula" + index).html("");
    }
  }

  save() {
    this.alertService.showDialog(
      "Are you sure you want to proceed?",
      DialogType.confirm,
      () => {
        let msg = "";
        let index = 0;
        if (
          typeof this.opportunitySection != "undefined" &&
          this.opportunitySection != null
        ) {
          this.opportunitySection.forEach((item) => {
            if (
              $("#divFormula" + index).find(".alert-success").length > 0 &&
              $("#divFormula" + index).find(".alert-danger").length == 0 &&
              item.subdimension == ""
            ) {
              if (msg == "") msg = "<small>" + item.lever + "</small>";
              else msg += "<br><small>" + item.lever + "</small>";
            }
            index++;
          });
        }
        if (
          this.templEdit.name == null ||
          typeof this.templEdit.name == "undefined" ||
          this.templEdit.name.length == 0
        ) {
          this.alertService.showDialog(
            "Template name is required.",
            DialogType.alert
          );
          return;
        }
        if (
          this.templEdit.name != "" &&
          this.templEdit.name != null &&
          typeof this.templEdit.name != "undefined"
        ) {
          if (this.templEdit.name.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid template name.",
              DialogType.alert
            );
            return;
          }
        }
        if (
          this.templEdit.description != "" &&
          this.templEdit.description != null &&
          typeof this.templEdit.description != "undefined"
        ) {
          if (this.templEdit.description.trim() == "") {
            this.alertService.showDialog(
              "Please enter a valid description.",
              DialogType.alert
            );
            return;
          }
        }
        if (msg != "") {
          this.alertService.showDialog(
            "Please assign value driver for the following lever(s):<br>" + msg,
            DialogType.alert
          );
          return;
        }
        this.templEdit.createdby = this.accountService.currentUser.id;
        this.templEdit.isdeleted = false;
        this.templEdit.isget = false;
        if (this.atStage == 6 && this.templEdit.isQuantitative) {
          this.templEdit.opportunityStructure = JSON.stringify(
            this.opportunitySection
          );
        } else if (this.atStage == 3 && !this.templEdit.isQuantitative) {
          this.templEdit.improvementAreaStructure = JSON.stringify(
            this.improvementAreaSection
          );
          this.templEdit.observationStructure = JSON.stringify(
            this.observationSection
          );
        }
        this.accountService.Set(this.module, this.templEdit).subscribe(
          () => {
            localStorage.removeItem("tmplSelected");
            localStorage.setItem(
              "tmplSelected",
              JSON.stringify(this.templEdit)
            );
            this.alertService.showDialog(
              "Saved Successfully.",
              DialogType.alert
            );
            this.router.navigate(["template"]);
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }

  cancel() {
    this.templEdit = new Template();

    this.showValidationErrors = false;
    this.resetForm();

    this.alertService.showMessage(
      "Cancelled",
      "Operation cancelled by user",
      MessageSeverity.default
    );
    this.alertService.resetStickyMessage();

    if (this.changesSavedCallback) {
      this.changesSavedCallback();
    }
  }

  resetForm(replace = false) {
    if (!replace) {
      this.form.reset();
    } else {
      this.formResetToggle = false;

      setTimeout(() => {
        this.formResetToggle = true;
      });
    }
  }

  addImprovementArea() {
    if (
      typeof this.improvementAreaSection == "undefined" ||
      this.improvementAreaSection == null
    ) {
      this.improvementAreaSection = [{ title: "", description: "" }];
    } else {
      let obj = { title: "", description: "" };
      this.improvementAreaSection.push(obj);
    }
  }

  removeImprovementArea(index: any) {
    this.improvementAreaSection.splice(index, 1);
  }

  addObservation() {
    if (
      typeof this.observationSection == "undefined" ||
      this.observationSection == null
    ) {
      this.observationSection = [{ title: "", description: "" }];
    } else {
      let obj = { title: "", description: "" };
      this.observationSection.push(obj);
    }
  }

  removeObservation(index: any) {
    this.observationSection.splice(index, 1);
  }

  addRevenue() {
    if (
      typeof this.plSection.children02 == "undefined" ||
      this.plSection.children02 == null
    ) {
      this.plSection.children02 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children02.push(obj);
    }
  }

  removeRevenue(index: any) {
    this.plSection.children02.splice(index, 1);
    this.calculatePL();
  }

  addVariable() {
    if (
      typeof this.plSection.children04 == "undefined" ||
      this.plSection.children04 == null
    ) {
      this.plSection.children04 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children04.push(obj);
    }
  }

  removeVariable(index: any) {
    this.plSection.children04.splice(index, 1);
    this.calculatePL();
  }

  addFixed() {
    if (
      typeof this.plSection.children06 == "undefined" ||
      this.plSection.children06 == null
    ) {
      this.plSection.children06 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children06.push(obj);
    }
  }

  removeFixed(index: any) {
    this.plSection.children06.splice(index, 1);
    this.calculatePL();
  }

  addSga() {
    if (
      typeof this.plSection.children08 == "undefined" ||
      this.plSection.children08 == null
    ) {
      this.plSection.children08 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children08.push(obj);
    }
  }

  removeSga(index: any) {
    this.plSection.children08.splice(index, 1);
    this.calculatePL();
  }

  addOther() {
    if (
      typeof this.plSection.children09 == "undefined" ||
      this.plSection.children09 == null
    ) {
      this.plSection.children09 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children09.push(obj);
    }
  }

  removeOther(index: any) {
    this.plSection.children09.splice(index, 1);
    this.calculatePL();
  }

  addAssumption() {
    if (
      typeof this.assumptionSection == "undefined" ||
      this.assumptionSection == null
    ) {
      this.assumptionSection.push({
        id: 1,
        kpi: "",
        assumption: "",
        input: "",
        value: "",
      });
    } else {
      let obj = {
        id: this.assumptionSection.length + 1,
        kpi: "",
        assumption: "",
        input: "",
        value: "",
      };
      this.assumptionSection.push(obj);
    }
  }

  removeAssumption(index: any) {
    this.assumptionSection.splice(index, 1);
    var i = 1;
    this.assumptionSection.forEach((item) => {
      item.id = i;
      i++;
    });
  }

  addOpportunity() {
    if (
      typeof this.opportunitySection == "undefined" ||
      this.opportunitySection == null
    ) {
      this.opportunitySection = {
        id: 1,
        industry: "",
        keytechnologies: '',
        challenges: '',
        advantages: '',
        domain: '',
        subdomain: '',
        industrylist: this.industryDataList,
        lever: "",
        leverlist: [],
        opportunityformula: "",
        subdimension: "",
      };
    } else {
      let obj = {
        id: this.opportunitySection.length + 1,
        keytechnologies: '',
        challenges: '',
        advantages: '',
        domain: '',
        subdomain: '',
        industry: "",
        industrylist: this.industryDataList,
        lever: "",
        leverlist: [],
        opportunityformula: "",
        subdimension: "",
      };
      this.opportunitySection.push(obj);
    }
  }

  removeOpportunity(index: any) {
    this.opportunitySection.splice(index, 1);
    var i = 1;
    this.opportunitySection.forEach((item) => {
      item.id = i;
      i++;
    });
  }

  addCalculation(index: any) {
    this.opportunityIndex = index;
    this.calculationModal.show();
    $("#txtCalculation").val(
      this.opportunitySection[this.opportunityIndex].opportunityformula
    );
    $("#PLInput").prop("checked", true);
    this.loadInputLabel("P&L Input");
  }

  removeCalculation(index: any) {
    this.opportunitySection[index].opportunityformula = "";
    $("#divFormula" + index).html("");
  }

  addCalcModal() {
    $("#txtCalculation").val(
      $("#txtCalculation").val() +
        "" +
        $("#calclabel").val() +
        "" +
        $("#calcoperator").val()
    );
    $("#calclabel").val("");
    $("#calcoperator").val("");
  }

  saveCalcModal() {
    const txtCalc = $("#txtCalculation").val().toString();
    const calcRegex = /\($|\/$|\*$|\+$|\-$/;

    if (
      (txtCalc.indexOf("(") != -1 ||
        (txtCalc.indexOf(")") != -1 && txtCalc.indexOf("(") != -1) ||
        txtCalc.indexOf("/") != -1 ||
        txtCalc.indexOf("*") != -1 ||
        txtCalc.indexOf("+") != -1 ||
        txtCalc.indexOf("-") != -1) &&
      !calcRegex.test(txtCalc.trim())
    ) {
      this.opportunitySection[this.opportunityIndex].opportunityformula =
        $("#txtCalculation").val();
      $("#txtCalculation").val("");
      this.calculationModal.hide();
      this.validateFormula(
        this.opportunityIndex,
        this.opportunitySection[this.opportunityIndex].opportunityformula
      );
    } else {
      this.alertService.showMessage(
        "Incorrect",
        "There's a problem with this formula.",
        MessageSeverity.default
      );
      this.alertService.resetStickyMessage();
    }
  }

  loadInputLabel(val: any) {
    this.inputLabelList = [];
    if (val == "P&L Input") {
      if (
        this.plSection != null &&
        typeof this.plSection != "undefined" &&
        this.plSection.length != 0
      ) {
        if (
          this.plSection.children01 != null &&
          typeof this.plSection.children01 != "undefined" &&
          this.plSection.children01.length != 0
        ) {
          this.plSection.children01.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children02 != null &&
          typeof this.plSection.children02 != "undefined" &&
          this.plSection.children02.length != 0
        ) {
          this.plSection.children02.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children03 != null &&
          typeof this.plSection.children03 != "undefined" &&
          this.plSection.children03.length != 0
        ) {
          this.plSection.children03.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children04 != null &&
          typeof this.plSection.children04 != "undefined" &&
          this.plSection.children04.length != 0
        ) {
          this.plSection.children04.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children05 != null &&
          typeof this.plSection.children05 != "undefined" &&
          this.plSection.children05.length != 0
        ) {
          this.plSection.children05.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children06 != null &&
          typeof this.plSection.children06 != "undefined" &&
          this.plSection.children06.length != 0
        ) {
          this.plSection.children06.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children07 != null &&
          typeof this.plSection.children07 != "undefined" &&
          this.plSection.children07.length != 0
        ) {
          this.plSection.children07.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children08 != null &&
          typeof this.plSection.children08 != "undefined" &&
          this.plSection.children08.length != 0
        ) {
          this.plSection.children08.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children09 != null &&
          typeof this.plSection.children09 != "undefined" &&
          this.plSection.children09.length != 0
        ) {
          this.plSection.children09.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
        if (
          this.plSection.children10 != null &&
          typeof this.plSection.children10 != "undefined" &&
          this.plSection.children10.length != 0
        ) {
          this.plSection.children10.forEach((f) => {
            this.inputLabelList.push(f.name);
          });
        }
      }
    } else if (val == "Assumption") {
      if (
        this.assumptionSection != null &&
        typeof this.assumptionSection != "undefined" &&
        this.assumptionSection.length != 0
      ) {
        this.assumptionSection.forEach((f) => {
          this.inputLabelList.push(f.kpi);
        });
      }
    } else if (val == "Case Lever") {
      this.inputLabelList.push("Impact value");
    }
  }

  editDigitalLever() {
    this.digitalleverModal.show();
  }

  jumpStage(index: any) {
    this.atStage = index - 1;
    if (index == 1) {
      this.loadModuleTreeByVersion(true, this.templEdit.version.toString());
    } else if (index == 2) {
      this.loadAllQuestions();
    } else if (index == 3) {
      this.loadDefineImprovementArea();
      this.loadDefineObservation();
    } else if (index == 4) {
      this.loadDefinePL();
    } else if (index == 5) {
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != "undefined"
      ) {
        this.plSection = {};
        Object.assign(
          this.plSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .plStructure
          )
        );
      }
      this.loadDefineAssumption();
    } else if (index == 6) {
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .plStructure != "undefined"
      ) {
        this.plSection = {};
        Object.assign(
          this.plSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .plStructure
          )
        );
      }
      if (
        localStorage.getItem("tmplSelected") != null &&
        typeof localStorage.getItem("tmplSelected") != "undefined" &&
        (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .assumptionStructure != null &&
        typeof (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
          .assumptionStructure != "undefined"
      ) {
        this.assumptionSection = [];
        Object.assign(
          this.assumptionSection,
          JSON.parse(
            (JSON.parse(localStorage.getItem("tmplSelected")) as Template)
              .assumptionStructure
          )
        );
      }
      this.loadDefineOpportunity();
    }
  }
}
