import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { AccountService } from '../../services/account.service';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { Client } from '../../models/client.model';
import { ClientEditorComponent } from './client-editor.component';


@Component({
  selector: 'client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.scss']
})

export class ClientManagementComponent implements OnInit, AfterViewInit {
  columns: any[] = [];
  rows: Client[] = [];
  rowsCache: Client[] = [];
  allPermissions: Permission[] = [];
  editedClient: Client;
  sourceClient: Client;
  editingClientName: { name: string };
  loadingIndicator: boolean;
  searchText: string;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('editorModal', { static: true })
  editorModal: ModalDirective;

  @ViewChild('clientEditor', { static: true })
  clientEditor: ClientEditorComponent;

  module: any = 'Client';
  obj: Client = new Client();

  constructor(private alertService: AlertService, private translationService: AppTranslationService, private accountService: AccountService, public appComponent: AppComponent, private router: Router) {
  }

  ngOnInit() {
    if (this.appComponent.isAdmin) {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        { prop: 'index', name: '#', width: 50, cellTemplate: this.indexTemplate, canAutoResize: false },
        { prop: 'name', name: 'Private Equity Company' },
        { prop: 'portfolio1', name: 'Portfolio Company 1' },
        { prop: 'portfolio2', name: 'Portfolio Company 2' },
        { prop: 'portfolio3', name: 'Portfolio Company 3' },
        { prop: 'portfolio4', name: 'Portfolio Company 4' },
        { prop: 'portfolio5', name: 'Portfolio Company 5' },
        { name: '', width: 160, cellTemplate: this.actionsTemplate, resizeable: false, canAutoResize: false, sortable: false, draggable: false }
      ];

      this.loadData();
    }
  }


  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.clientEditor.changesSavedCallback = () => {
        this.addNewClientToList();
        this.editorModal.hide();
      };

      this.clientEditor.changesCancelledCallback = () => {
        this.editedClient = null;
        this.sourceClient = null;
        this.editorModal.hide();
      };
    }
  }

  addNewClientToList() {
    if (this.sourceClient) {
      Object.assign(this.sourceClient, this.editedClient);

      let sourceIndex = this.rowsCache.indexOf(this.sourceClient, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceClient, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedClient = null;
      this.sourceClient = null;
    } else {
      const client = new Client();
      Object.assign(client, this.editedClient);
      this.editedClient = null;

      let maxIndex = 0;
      for (const r of this.rowsCache) {
        if ((r as any).index > maxIndex) {
          maxIndex = (r as any).index;
        }
      }

      (client as any).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, client);
      this.rows.splice(0, 0, client);
      this.rows = [...this.rows];
    }
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.obj.isget = true;
    this.accountService.Set(this.module, this.obj).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const clients = results;

        clients.forEach((client, index, clients) => {
          (client as any).index = index + 1;
        });

        this.rowsCache = [...clients];
        this.rows = clients;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage('Load Error', `Unable to retrieve Clients from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r => Utilities.searchArray(value, false, r.name, r.portfolio1, r.portfolio2, r.portfolio3, r.portfolio4, r.portfolio5));
  }

  onEditorModalHidden() {
    this.editingClientName = null;
    this.clientEditor.resetForm(true);
    this.loadData();
    this.searchText = "";
  }

  newclient() {
    this.editingClientName = null;
    this.sourceClient = null;
    this.editedClient = this.clientEditor.newRole(this.allPermissions);
    this.editorModal.show();
  }

  edit(row: Client) {
    this.editingClientName = { name: row.name };
    this.sourceClient = row;
    this.editedClient = this.clientEditor.editRole(row, this.allPermissions);
    this.editorModal.show();
  }

  delete(obj: Client) {
    this.alertService.showDialog('Are you sure you want to delete \"' + obj.name + '\"?', DialogType.confirm, () => {
      obj.createdby = this.accountService.currentUser.id;
      obj.isdeleted = true;
      obj.isget = false;
      this.accountService.Set(this.module, obj).subscribe(
        () => {
          this.alertService.showDialog(`Deleted Successfully.`, DialogType.alert);

          this.rowsCache = this.rowsCache.filter(item => item !== obj);
          this.rows = this.rows.filter(item => item !== obj);

          this.loadData();

        }, error => { this.appComponent.failedHelper(error); });
    });
  }
}
