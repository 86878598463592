// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Permission } from './permission.model';


export class Role {

  constructor(name?: string, description?: string, permissions?: Permission[], isactive?: boolean) {

    this.name = name;
    this.description = description;
    this.permissions = permissions;
    this.isactive = isactive;
  }

  public id: string;
  public name: string;
  public description: string;
  public usersCount: number;
  public permissions: Permission[];
  public isactive: boolean;
}
