// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { Component, Injectable, ViewChild } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { Client } from '../../models/client.model';
import { AccountService } from '../../services/account.service';
import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { Utilities } from '../../services/utilities';
import { Permission } from '../../models/permission.model';
import * as Enumerable from '../../assets/scripts/Linq/linq';
import { Assessment, AssessmentModules } from '../../models/assessment.model';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { Template } from '../../models/template.model';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateEditorComponent } from '../template/template-editor.component';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'user-template',
  templateUrl: './user-template.component.html',
  styleUrls: ['./user-template.component.scss'],
  animations: [fadeInOut],
  providers: [AppComponent, TemplateEditorComponent]
})
export class UserTemplateComponent {
  public allRoles: Role[] = [];
  public orgList: Client[] = [];
  public clientObj: Client = new Client();
  public roleslist: Role[] = [];
  public templateObj: Template = new Template();
  public templateObjActual: Template = new Template();
  public assessmentObj: Assessment = new Assessment();
  public assessmentModuleObj: AssessmentModules = new AssessmentModules();


  template: any;
  organisation: any;
  isAdmin: boolean;
  improvementAreaSection: any;
  observationSection: any;
  opportunitySection: any;
  businessunitDataList: any[] = [];
  opportunityIndex: number = null;
  @ViewChild('calculationModal', { static: false })
  calculationModal: ModalDirective;
  inputLabelList: any[] = [];
  saveexit: boolean = false;
  subdimensionlist: any;
  public moduleTree: any[] = [];
  inputDataList: any[] = [];

  module = "template";
  @ViewChild('detailsModal', { static: false })
  detailsModal: ModalDirective;
  plSection: any;
  atStage: number = 0;
  assumptionSection: any;
  plDataList: any[] = [];

  constructor(public configurations: ConfigurationService, private accountService: AccountService, public appComponent: AppComponent, private templateEditorComponent: TemplateEditorComponent, private alertService: AlertService, private router: Router, public modalRef?: BsModalRef, public http?: HttpClient) {

    $(document).ready(function () {
      // video tab

    });
  }

  ngOnInit() {
    if (!this.appComponent.isAdmin) {
      this.loadCurrentUserData();

    }
  }

  private loadCurrentUserData() {
    this.templateObj.id = this.accountService.currentUser.templId;
    this.templateObj.isget = true;
    this.accountService.Set('LoginHandler/gettemplates', this.templateObj).subscribe(
      result => {
        if (result) {
          this.templateObj = new Template();
          this.templateObjActual = new Template();
          //Object.assign(this.templateObjActual, result);
          Object.assign(this.templateObj, result);
          localStorage.setItem("tmplSelected", JSON.stringify(result));
          this.jumpStage(4);

        }
      },
      error => {
        this.appComponent.failedHelper(error);
      });
    if (localStorage.getItem('currentUserDetails') != null && typeof localStorage.getItem('currentUserDetails') != 'undefined') {
      this.assessmentObj = JSON.parse(localStorage.getItem('currentUserDetails')) as Assessment;
    }
    else {
      this.organisation = this.accountService.currentUser.jobTitle;
      this.assessmentObj.userId = this.accountService.currentUser.id;
      this.assessmentObj.isget = true;
      this.accountService.Set('assessment', this.assessmentObj).subscribe(
        result => {
          if (result) {
            this.assessmentObj = new Assessment();
            this.assessmentModuleObj = new AssessmentModules();
            Object.assign(this.assessmentObj, result);
            if (this.assessmentObj.id == 0) {
              this.assessmentObj.sector = "";
              this.assessmentObj.subSector = "";
              this.assessmentObj.role = "";
              this.assessmentObj.assessmentType = "";
            }
            this.assessmentObj.orgId = this.accountService.currentUser.orgId;
            this.assessmentObj.templId = this.accountService.currentUser.templId;

            localStorage.setItem("currentUserDetails", JSON.stringify(result));
          }
        },
        error => {
          this.appComponent.failedHelper(error);
        });
    }
  }

  save() {
    if (this.assessmentObj) {
      if (this.assessmentObj.sector == null || typeof (this.assessmentObj.sector) == 'undefined' || this.assessmentObj.sector.length == 0) {
        this.alertService.showDialog('Industry sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.subSector == null || typeof (this.assessmentObj.subSector) == 'undefined' || this.assessmentObj.subSector.length == 0) {
        this.alertService.showDialog('Sub-sector is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.role == null || typeof (this.assessmentObj.role) == 'undefined' || this.assessmentObj.role.length == 0) {
        this.alertService.showDialog('Role is mandatory.', DialogType.alert);
        return;
      }
      if (this.assessmentObj.assessmentType == null || typeof (this.assessmentObj.assessmentType) == 'undefined' || this.assessmentObj.assessmentType.length == 0) {
        this.alertService.showDialog('Assessment type is mandatory.', DialogType.alert);
        return;
      }

      this.assessmentObj.isget = false;
      this.assessmentObj.userId = this.accountService.currentUser.id;
      this.assessmentObj.createdby = this.accountService.currentUser.id;

      this.accountService.Set("assessment", this.assessmentObj).subscribe(
        results => {
          localStorage.setItem("currentUserDetails", JSON.stringify(this.assessmentObj));
          localStorage.setItem("userModules", JSON.stringify(this.assessmentModuleObj));

          if (!this.assessmentObj.isSubmitted) {
            if (!this.assessmentObj.isAcceptedPrivacy) {
              this.router.navigate(['privacy']);
            }
            else {
              this.router.navigate(['questionnaires']);
            }
          }
          else {
            this.router.navigate(['reportqualitativecontent']);
          }
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
            MessageSeverity.error, error);
        });
    }
  }

  completed() {
    this.router.navigate(['completed']);
  }

  showDetailsModal() {
    this.detailsModal.show();
  }

  jumpStage(index: any) {
    this.atStage = index - 1;

    if (index == 4) {
      this.loadDefinePL();
    }
    else if (index == 5) {
      this.loadDefineAssumption();
    }

  }

  loadDefinePL() {
    if (this.atStage === 3) {

      if (this.assessmentObj.plStructure != null) {
        this.plSection = {};
        Object.assign(this.plSection, JSON.parse(this.assessmentObj.plStructure));

      }
      else if (localStorage.getItem('tmplSelected') != null && typeof localStorage.getItem('tmplSelected') != 'undefined'
        && (JSON.parse(localStorage.getItem('tmplSelected')) as Template).plStructure != null && typeof (JSON.parse(localStorage.getItem('tmplSelected')) as Template).plStructure != 'undefined') {
        this.plSection = {};
        Object.assign(this.plSection, JSON.parse((JSON.parse(localStorage.getItem('tmplSelected')) as Template).plStructure));
      }
      else {
        this.plSection = { currentYear: new Date().getFullYear(), targetYear: new Date().getFullYear() + 4, growth: 15, children01: [], children02: [], children03: [], children04: [], children05: [], children06: [], children07: [], children08: [], children09: [], children10: [] };
        this.plSection.children01.push({ name: 'Revenues from Non Digital products', description: '', value1: 0, value2: 0 });
        this.plSection.children01.push({ name: 'Revenues from Digital Products', description: '', value1: 0, value2: 0 });
        this.plSection.children02.push({ name: '', description: '', value1: 0, value2: 0 });
        this.plSection.children03.push({ name: 'Total Revenues', description: '', value1: 0, value2: 0 });
        this.plSection.children04.push({ name: '', description: '', value1: 0, value2: 0 });
        this.plSection.children05.push({ name: 'Contribution Margin', description: '', value1: 0, value2: 0 });
        this.plSection.children06.push({ name: '', description: '', value1: 0, value2: 0 });
        this.plSection.children07.push({ name: 'Gross Margin', description: '', value1: 0, value2: 0 });
        this.plSection.children08.push({ name: '', description: '', value1: 0, value2: 0 });
        this.plSection.children09.push({ name: '', description: '', value1: 0, value2: 0 });
        this.plSection.children10.push({ name: 'EBITDA', description: '', value1: 0, value2: 0 });
      }

      this.atStage++;
    }
  }

  loadDefineAssumption() {
    if (this.atStage === 4) {
      this.loadPlData();

      this.assumptionSection = [];
      if (this.assessmentObj.assumptionStructure != null) {

        Object.assign(this.assumptionSection, JSON.parse(this.assessmentObj.assumptionStructure));

      }
      else if (localStorage.getItem('tmplSelected') != null && typeof localStorage.getItem('tmplSelected') != 'undefined'
        && (JSON.parse(localStorage.getItem('tmplSelected')) as Template).assumptionStructure != null && typeof (JSON.parse(localStorage.getItem('tmplSelected')) as Template).assumptionStructure != 'undefined') {
        Object.assign(this.assumptionSection, JSON.parse((JSON.parse(localStorage.getItem('tmplSelected')) as Template).assumptionStructure));
      }
      else {
        this.assumptionSection.push({ id: 1, kpi: '', assumption: '', input: '', value: '' });
      }

      this.atStage++;
    }
  }

  loadPlData() {
    this.plDataList = [];
    if (this.plSection != null && typeof this.plSection != "undefined" && this.plSection.length != 0) {
      if (this.plSection.children01 != null && typeof this.plSection.children01 != "undefined" && this.plSection.children01.length != 0) {
        this.plSection.children01.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children02 != null && typeof this.plSection.children02 != "undefined" && this.plSection.children02.length != 0) {
        this.plSection.children02.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children03 != null && typeof this.plSection.children03 != "undefined" && this.plSection.children03.length != 0) {
        this.plSection.children03.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children04 != null && typeof this.plSection.children04 != "undefined" && this.plSection.children04.length != 0) {
        this.plSection.children04.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children05 != null && typeof this.plSection.children05 != "undefined" && this.plSection.children05.length != 0) {
        this.plSection.children05.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children06 != null && typeof this.plSection.children06 != "undefined" && this.plSection.children06.length != 0) {
        this.plSection.children06.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children07 != null && typeof this.plSection.children07 != "undefined" && this.plSection.children07.length != 0) {
        this.plSection.children07.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children08 != null && typeof this.plSection.children08 != "undefined" && this.plSection.children08.length != 0) {
        this.plSection.children08.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children09 != null && typeof this.plSection.children09 != "undefined" && this.plSection.children09.length != 0) {
        this.plSection.children09.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
      if (this.plSection.children10 != null && typeof this.plSection.children10 != "undefined" && this.plSection.children10.length != 0) {
        this.plSection.children10.forEach(f => {
          this.plDataList.push({ name: f.name, value1: f.value1 });
        });
      }
    }
  }

  addRevenue() {
    if (
      typeof this.plSection.children02 == "undefined" ||
      this.plSection.children02 == null
    ) {
      this.plSection.children02 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children02.push(obj);
    }
  }

  removeRevenue(index: any) {
    this.plSection.children02.splice(index, 1);
    this.calculatePL();
  }

  addVariable() {
    if (
      typeof this.plSection.children04 == "undefined" ||
      this.plSection.children04 == null
    ) {
      this.plSection.children04 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children04.push(obj);
    }
  }

  removeVariable(index: any) {
    this.plSection.children04.splice(index, 1);
    this.calculatePL();
  }

  addFixed() {
    if (
      typeof this.plSection.children06 == "undefined" ||
      this.plSection.children06 == null
    ) {
      this.plSection.children06 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children06.push(obj);
    }
  }

  removeFixed(index: any) {
    this.plSection.children06.splice(index, 1);
    this.calculatePL();
  }

  addSga() {
    if (
      typeof this.plSection.children08 == "undefined" ||
      this.plSection.children08 == null
    ) {
      this.plSection.children08 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children08.push(obj);
    }
  }

  removeSga(index: any) {
    this.plSection.children08.splice(index, 1);
    this.calculatePL();
  }

  addOther() {
    if (
      typeof this.plSection.children09 == "undefined" ||
      this.plSection.children09 == null
    ) {
      this.plSection.children09 = [
        { name: "", description: "", value1: 0, value2: 0 },
      ];
    } else {
      let obj = { name: "", description: "", value1: 0, value2: 0 };
      this.plSection.children09.push(obj);
    }
  }

  removeOther(index: any) {
    this.plSection.children09.splice(index, 1);
    this.calculatePL();
  }

  calculatePL() {
    if (this.plSection != null && typeof this.plSection != "undefined" && this.plSection.length != 0) {
      if (this.plSection.children01 != null && typeof this.plSection.children01 != "undefined" && this.plSection.children01.length != 0) {
        this.plSection.children01.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children02 != null && typeof this.plSection.children02 != "undefined" && this.plSection.children02.length != 0) {
        this.plSection.children02.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children03 != null && typeof this.plSection.children03 != "undefined" && this.plSection.children03.length != 0) {
        this.plSection.children03.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children04 != null && typeof this.plSection.children04 != "undefined" && this.plSection.children04.length != 0) {
        this.plSection.children04.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children05 != null && typeof this.plSection.children05 != "undefined" && this.plSection.children05.length != 0) {
        this.plSection.children05.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children06 != null && typeof this.plSection.children06 != "undefined" && this.plSection.children06.length != 0) {
        this.plSection.children06.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children07 != null && typeof this.plSection.children07 != "undefined" && this.plSection.children07.length != 0) {
        this.plSection.children07.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children08 != null && typeof this.plSection.children08 != "undefined" && this.plSection.children08.length != 0) {
        this.plSection.children08.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children09 != null && typeof this.plSection.children09 != "undefined" && this.plSection.children09.length != 0) {
        this.plSection.children09.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
      if (this.plSection.children10 != null && typeof this.plSection.children10 != "undefined" && this.plSection.children10.length != 0) {
        this.plSection.children10.forEach(f => {
          if (isNaN(f.value1) || f.value1 == "" || f.value1 == null)
            f.value1 = 0;
        });
      }
    }

    let level1sum01: number = 0;
    let level1sum02: number = 0;
    let level1sum04: number = 0;
    let level1sum06: number = 0;
    let level1sum08: number = 0;
    let level1sum09: number = 0;
    let level2sum01: number = 0;
    let level2sum02: number = 0;
    let level2sum04: number = 0;
    let level2sum06: number = 0;
    let level2sum08: number = 0;
    let level2sum09: number = 0;
    if (this.plSection != null && typeof this.plSection != "undefined" && this.plSection.length != 0) {
      if (this.plSection.children01 != null && typeof this.plSection.children01 != "undefined" && this.plSection.children01.length != 0) {
        level1sum01 = this.plSection.children01.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children02 != null && typeof this.plSection.children02 != "undefined" && this.plSection.children02.length != 0) {
        level1sum02 = this.plSection.children02.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children04 != null && typeof this.plSection.children04 != "undefined" && this.plSection.children04.length != 0) {
        level1sum04 = this.plSection.children04.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children06 != null && typeof this.plSection.children06 != "undefined" && this.plSection.children06.length != 0) {
        level1sum06 = this.plSection.children06.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children08 != null && typeof this.plSection.children08 != "undefined" && this.plSection.children08.length != 0) {
        level1sum08 = this.plSection.children08.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children09 != null && typeof this.plSection.children09 != "undefined" && this.plSection.children09.length != 0) {
        level1sum09 = this.plSection.children09.map(a => a.value1).reduce(function (a, b) {
          return a + b;
        });
      }
    }
    this.plSection.children03[0].value1 = Math.round((level1sum01 + level1sum02) * 100) / 100;
    this.plSection.children05[0].value1 = Math.round((level1sum01 + level1sum02 - level1sum04) * 100) / 100;
    this.plSection.children07[0].value1 = Math.round((level1sum01 + level1sum02 - level1sum04 - level1sum06) * 100) / 100;
    this.plSection.children10[0].value1 = Math.round((level1sum01 + level1sum02 - level1sum04 - level1sum06 - level1sum08 - level1sum09) * 100) / 100;

    for (var i = 0; i < this.plSection.children01.length; i++) {
      this.plSection.children01[i].value2 = Math.round((this.plSection.children01[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    for (var i = 0; i < this.plSection.children02.length; i++) {
      this.plSection.children02[i].value2 = Math.round((this.plSection.children02[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    for (var i = 0; i < this.plSection.children04.length; i++) {
      this.plSection.children04[i].value2 = Math.round((this.plSection.children04[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    for (var i = 0; i < this.plSection.children06.length; i++) {
      this.plSection.children06[i].value2 = Math.round((this.plSection.children06[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    for (var i = 0; i < this.plSection.children08.length; i++) {
      this.plSection.children08[i].value2 = Math.round((this.plSection.children08[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    for (var i = 0; i < this.plSection.children09.length; i++) {
      this.plSection.children09[i].value2 = Math.round((this.plSection.children09[i].value1 * Math.pow(1 + (this.plSection.growth / 100), (this.plSection.targetYear - this.plSection.currentYear))) * 100) / 100;
    }
    if (this.plSection != null && typeof this.plSection != "undefined" && this.plSection.length != 0) {
      if (this.plSection.children01 != null && typeof this.plSection.children01 != "undefined" && this.plSection.children01.length != 0) {
        level2sum01 = this.plSection.children01.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children02 != null && typeof this.plSection.children02 != "undefined" && this.plSection.children02.length != 0) {
        level2sum02 = this.plSection.children02.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children04 != null && typeof this.plSection.children04 != "undefined" && this.plSection.children04.length != 0) {
        level2sum04 = this.plSection.children04.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children06 != null && typeof this.plSection.children06 != "undefined" && this.plSection.children06.length != 0) {
        level2sum06 = this.plSection.children06.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children08 != null && typeof this.plSection.children08 != "undefined" && this.plSection.children08.length != 0) {
        level2sum08 = this.plSection.children08.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
      if (this.plSection.children09 != null && typeof this.plSection.children09 != "undefined" && this.plSection.children09.length != 0) {
        level2sum09 = this.plSection.children09.map(a => a.value2).reduce(function (a, b) {
          return a + b;
        });
      }
    }
    this.plSection.children03[0].value2 = Math.round((level2sum01 + level2sum02) * 100) / 100;
    this.plSection.children05[0].value2 = Math.round((level2sum01 + level2sum02 - level2sum04) * 100) / 100;
    this.plSection.children07[0].value2 = Math.round((level2sum01 + level2sum02 - level2sum04 - level2sum06) * 100) / 100;
    this.plSection.children10[0].value2 = Math.round((level2sum01 + level2sum02 - level2sum04 - level2sum06 - level2sum08 - level2sum09) * 100) / 100;
  }

  loadNext() {


    if (this.atStage == 4) {
      this.saveStage4();
    }

    if (this.atStage == 5) {
      this.saveStage5();
    }
  }

  loadNextExit() {
    this.saveexit = true;

    if (this.atStage == 4) {
      this.saveStage4();
    }

    if (this.atStage == 5) {
      this.saveStage5();
    }

  }

  saveStage4() {
    this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {
      if (this.assessmentObj) {
        if (this.assessmentObj.sector == null || typeof (this.assessmentObj.sector) == 'undefined' || this.assessmentObj.sector.length == 0) {
          this.alertService.showDialog('Industry sector is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.subSector == null || typeof (this.assessmentObj.subSector) == 'undefined' || this.assessmentObj.subSector.length == 0) {
          this.alertService.showDialog('Sub-sector is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.role == null || typeof (this.assessmentObj.role) == 'undefined' || this.assessmentObj.role.length == 0) {
          this.alertService.showDialog('Role is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.assessmentType == null || typeof (this.assessmentObj.assessmentType) == 'undefined' || this.assessmentObj.assessmentType.length == 0) {
          this.alertService.showDialog('Assessment type is mandatory.', DialogType.alert);
          return;
        }

        this.assessmentObj.isget = false;
        this.assessmentObj.userId = this.accountService.currentUser.id;
        this.assessmentObj.updatedby = this.accountService.currentUser.id;
        this.assessmentObj.plStructure = JSON.stringify(this.plSection);

        this.accountService.Set("assessment", this.assessmentObj).subscribe(
          results => {
            localStorage.setItem("currentUserDetails", JSON.stringify(this.assessmentObj));
            localStorage.setItem("userModules", JSON.stringify(this.assessmentModuleObj));


            if (!this.assessmentObj.isSubmitted) {
              if (this.saveexit == true) {
                this.modalRef.hide();
                this.router.navigate(['questionnaires']);
              }
              else {
                this.loadDefineOpportunity();
                this.jumpStage(5);
              }
            }
            else {
              this.router.navigate(['template']);
            }
          },
          error => {
            this.alertService.stopLoadingMessage();
            this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
              MessageSeverity.error, error);
          });
      }

    });

  }

  saveStage5() {
    this.alertService.showDialog('Are you sure you want to proceed?', DialogType.confirm, () => {

      if (this.assessmentObj) {
        if (this.assessmentObj.sector == null || typeof (this.assessmentObj.sector) == 'undefined' || this.assessmentObj.sector.length == 0) {
          this.alertService.showDialog('Industry sector is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.subSector == null || typeof (this.assessmentObj.subSector) == 'undefined' || this.assessmentObj.subSector.length == 0) {
          this.alertService.showDialog('Sub-sector is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.role == null || typeof (this.assessmentObj.role) == 'undefined' || this.assessmentObj.role.length == 0) {
          this.alertService.showDialog('Role is mandatory.', DialogType.alert);
          return;
        }
        if (this.assessmentObj.assessmentType == null || typeof (this.assessmentObj.assessmentType) == 'undefined' || this.assessmentObj.assessmentType.length == 0) {
          this.alertService.showDialog('Assessment type is mandatory.', DialogType.alert);
          return;
        }

        this.assessmentObj.isget = false;
        this.assessmentObj.userId = this.accountService.currentUser.id;
        this.assessmentObj.updatedby = this.accountService.currentUser.id;
        this.assessmentObj.assumptionStructure = JSON.stringify(this.assumptionSection);

        this.accountService.Set("assessment", this.assessmentObj).subscribe(
          results => {
            localStorage.setItem("currentUserDetails", JSON.stringify(this.assessmentObj));
            localStorage.setItem("userModules", JSON.stringify(this.assessmentModuleObj));

            if (!this.assessmentObj.isSubmitted) {
              this.modalRef.hide();
              this.router.navigate(['questionnaires']);
            }
          },
          error => {
            this.alertService.stopLoadingMessage();
            this.alertService.showStickyMessage('Load Error', `Unable to save responses to the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
              MessageSeverity.error, error);
          });
      }

    });
  }

  loadDefineOpportunity() {
    if (this.atStage === 5) {
      this.subdimensionlist = [];
      for (var i = 0; i < this.moduleTree.length; i++) {
        if (this.moduleTree[i].ischecked == true) {
          for (var j = 0; j < this.moduleTree[i].children.length; j++) {
            if (this.moduleTree[i].children[j].ischecked == true) {
              this.subdimensionlist.push({ dimension: this.moduleTree[i].name, subdimension: this.moduleTree[i].children[j].name });
            }
          }
        }
      }

      this.loadInputData();
      //this.loadBusinessUnits();

      this.opportunitySection = [];
      if (localStorage.getItem('tmplSelected') != null && typeof localStorage.getItem('tmplSelected') != 'undefined'
        && (JSON.parse(localStorage.getItem('tmplSelected')) as Template).opportunityStructure != null && typeof (JSON.parse(localStorage.getItem('tmplSelected')) as Template).opportunityStructure != 'undefined') {
        Object.assign(this.opportunitySection, JSON.parse((JSON.parse(localStorage.getItem('tmplSelected')) as Template).opportunityStructure));
        var i = 0;
        this.opportunitySection.forEach(item => {
          this.validateFormula(i, item.opportunityformula);
          i++;
        });
      }
      else {

      }

      this.atStage++;
    }
  }

  validateFormula(index, formula) {
    if (formula != '') {
      var x = formula.split(/[\()*/+-]+/g);
      var y = formula.match(/[\()*/+-]+/g);
      var z = '';

      for (var i = 0; i < x.length; i++) {
        if (x[i] != '' && x[i] != null && typeof x[i] != 'undefined') {
          if (isNaN(x[i])) {
            if (this.inputDataList.includes(x[i]))
              z += '<span class="alert alert-success" style="padding: 0.25rem !important;">' + x[i] + '</span>';
            else
              z += '<span class="alert alert-danger" style="padding: 0.25rem !important;">' + x[i] + '</span>';
          }
          else {
            z += '<span class="alert alert-success" style="padding: 0.25rem !important;">' + x[i] + '</span>';
          }
        }
        if (y[i] != '' && y[i] != null && typeof y[i] != 'undefined') {
          z += '<span class="alert alert-dark" style="padding: 0.25rem !important;">' + y[i] + '</span>';
        }
      }

      setTimeout(function () { $("#divFormula" + index).html(z); }, 500);
    }
    else {
      $("#divFormula" + index).html('');
    }
  }


  loadInputData() {
    this.inputDataList = [];
    if (this.plSection != null && typeof this.plSection != "undefined" && this.plSection.length != 0) {
      if (this.plSection.children01 != null && typeof this.plSection.children01 != "undefined" && this.plSection.children01.length != 0) {
        this.plSection.children01.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children02 != null && typeof this.plSection.children02 != "undefined" && this.plSection.children02.length != 0) {
        this.plSection.children02.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children03 != null && typeof this.plSection.children03 != "undefined" && this.plSection.children03.length != 0) {
        this.plSection.children03.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children04 != null && typeof this.plSection.children04 != "undefined" && this.plSection.children04.length != 0) {
        this.plSection.children04.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children05 != null && typeof this.plSection.children05 != "undefined" && this.plSection.children05.length != 0) {
        this.plSection.children05.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children06 != null && typeof this.plSection.children06 != "undefined" && this.plSection.children06.length != 0) {
        this.plSection.children06.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children07 != null && typeof this.plSection.children07 != "undefined" && this.plSection.children07.length != 0) {
        this.plSection.children07.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children08 != null && typeof this.plSection.children08 != "undefined" && this.plSection.children08.length != 0) {
        this.plSection.children08.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children09 != null && typeof this.plSection.children09 != "undefined" && this.plSection.children09.length != 0) {
        this.plSection.children09.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }
      if (this.plSection.children10 != null && typeof this.plSection.children10 != "undefined" && this.plSection.children10.length != 0) {
        this.plSection.children10.forEach(f => {
          this.inputDataList.push(f.name);
        });
      }

      if (this.assumptionSection != null && typeof this.assumptionSection != "undefined" && this.assumptionSection.length != 0) {
        this.assumptionSection.forEach(f => {
          this.inputDataList.push(f.kpi);
        });
      }

      this.inputDataList.push("Impact value");
    }
  }

  addAssumption() {
    if (typeof this.assumptionSection == 'undefined' || this.assumptionSection == null) {
      this.assumptionSection.push({ id: 1, kpi: '', assumption: '', input: '', value: '' });
    }
    else {
      let obj = { id: this.assumptionSection.length + 1, kpi: '', assumption: '', input: '', value: '' };
      this.assumptionSection.push(obj);
    }
  }

  removeAssumption(index: any) {
    this.assumptionSection.splice(index, 1);
    var i = 1;
    if (this.assumptionSection != null && typeof this.assumptionSection != "undefined" && this.assumptionSection.length != 0) {
      this.assumptionSection.forEach(item => {
        item.id = i;
        i++
      });
    }
  }
  calculateAssumption() {
    if (this.assumptionSection != null && typeof this.assumptionSection != "undefined" && this.assumptionSection.length != 0) {
      this.assumptionSection.forEach(f => {
        if (isNaN(f.assumption) || f.assumption == "" || f.assumption == null)
          f.assumption = 0;
        if (f.input != "" && f.input != null)
          f.value = Math.round((Enumerable.from(this.plDataList).where(x => x.name == f.input).select(y => y.value1).firstOrDefault() * f.assumption / 100) * 100) / 100;
        else
          f.value = 0;
      });
    }
  }

  loadBack() {
    if (this.atStage > 4) {
      this.atStage--;
    }
    else {
      this.modalRef.hide();
      $('.open-assessment-modal').click();
    }
  }


}
