import { Auditentity } from "./auditentity.model";

export class DigitalLevers extends Auditentity {
  constructor(
    id?: number,
    serialno?: number,
    timestamp?: any,
    lever?: string,
    description?: string,
    kpiname?: string,
    impact?: number,
    minimumcasecaselever?: number,
    minimumcase?: number,
    realisticcasecaselever?: number,
    realisticcase?: number,
    bestcasecaselever?: number,
    bestcase?: number,
    businessunit?: string,
    source?: string,
    impactexplanation?: string,
    keytechnologies?: string,
    opportunityformula?: string,
    paybackperiodonetime?: number,
    paybackperiodtotal?: number,
    risk?: string,
    risklevel?: string,
    strategicrelevance?: string,
    implementationcomplexity?: string,
    implementationtime?: number,
    implementationrisk?: number,
    likelihood?: number,
    comments?: string,
    isenabled?: boolean,
    challenges?: string,
    advantages?: string,
    industry?: string,
    domain?: string,
    subdomain?: string
  ) {
    super();
    this.id = id;
    this.serialno = serialno;
    this.timestamp = timestamp;
    this.lever = lever;
    this.description = description;
    this.kpiname = kpiname;
    this.impact = impact;
    this.minimumcasecaselever = minimumcasecaselever;
    this.minimumcase = minimumcase;
    this.realisticcasecaselever = realisticcasecaselever;
    this.realisticcase = realisticcase;
    this.bestcasecaselever = bestcasecaselever;
    this.bestcase = bestcase;
    this.businessunit = businessunit;
    this.source = source;
    this.impactexplanation = impactexplanation;
    this.keytechnologies = keytechnologies;
    this.opportunityformula = opportunityformula;
    this.paybackperiodonetime = paybackperiodonetime;
    this.paybackperiodtotal = paybackperiodtotal;
    this.risk = risk;
    this.risklevel = risklevel;
    this.strategicrelevance = strategicrelevance;
    this.implementationcomplexity = implementationcomplexity;
    this.implementationtime = implementationtime;
    this.implementationrisk = implementationrisk;
    this.likelihood = likelihood;
    this.comments = comments;
    this.isenabled = isenabled;
    this.challenges = challenges;
    this.advantages = advantages;
    this.industry = industry;
    this.domain = domain;
    this.subdomain = subdomain;
  }
  public id: number;
  public serialno: number;
  public timestamp: any;
  public lever: string;
  public description: string;
  public kpiname: string;
  public impact: number;
  public minimumcasecaselever: number;
  public minimumcase: number;
  public realisticcasecaselever: number;
  public realisticcase: number;
  public bestcasecaselever: number;
  public bestcase: number;
  public businessunit: string;
  public source: string;
  public impactexplanation: string;
  public keytechnologies: string;
  public opportunityformula: string;
  public paybackperiodonetime: number;
  public paybackperiodtotal: number;
  public risk: string;
  public risklevel: string;
  public strategicrelevance: string;
  public implementationcomplexity: string;
  public implementationtime: number;
  public implementationrisk: number;
  public likelihood: number;
  public comments: string;
  public isenabled: boolean;
  public challenges: string;
  public advantages: string;
  public industry: string;
  public domain: string;
  public subdomain: string;
}
