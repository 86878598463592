import { Auditentity } from './auditentity.model';

export class Report extends Auditentity {

  constructor(id?: number
    , userid?: string
    , chartdata?: string
    , oppurtunitydata?: string) {
    super();
    this.id = id;
    this.userid = userid;
    this.chartdata = chartdata;
    this.oppurtunitydata = oppurtunitydata;
  }

  public id: number;
  public userid: string;
  public chartdata: string;
  public oppurtunitydata: string;
}
