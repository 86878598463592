// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string, roles?: string[], orgId?: number, templId?: number, isAccept?: boolean, clientOrganisation?: string, portfolioCompany?: string, industrySector?: string) {

    this.id = id;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.orgId = orgId;
    this.templId = templId;
    this.isAccept = isAccept;
    this.clientOrganisation = clientOrganisation;
    this.portfolioCompany = portfolioCompany;
    this.industrySector = industrySector;
  }


  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + ' ' + name;
    }

    return name;
  }


  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public isAssessmentCompleted: boolean;
  public roles: string[];
  public orgId: number;
  public templId: number;
  public isAccept: boolean;
  public clientOrganisation: string;
  public portfolioCompany: string;
  public industrySector: string;

}
