// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //local / dev
  //idam: {
  //  orgId: "d8602888-f5c6-4ae2-94b6-f1a7f54c343f",
  //  authorization_endpoint: "https://signin.test.access.pwc.com/openam/oauth2/realms/venturessso/authorize?",
  //  token_endpoint: "https://signin.test.access.pwc.com/openam/oauth2/realms/root/realms/venturessso/access_token",
  //  user_profile_url: "",
  //  app_url: "https://localhost:44350/",
  //  logout_url: "https://signin.test.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout/&goto=https://portal-dmt-dev-r2.np.dealstechmanagedservices.pwc.de",
  //  client_app_id: "deals_technology_platform_dev",
  //  client_secret: "Ufym8WulsBBrF85eGtHw",
  //  client_host: "https://crs.test.access.pwc.com",
  //  piam_auth_host:"signin.test.access.pwc.com"
  //},
  // baseUrl: 'https://portal-dmt-dev-r2.np.dealstechmanagedservices.pwc.de',
  idam: {
    //devr2
    orgId: "bfffab83-7695-448e-a33c-9ab705e0339a",
    authorization_endpoint:
      "https://signin.access.pwc.com/openam/oauth2/realms/venturessso/authorize?",
    token_endpoint:
      "https://signin.access.pwc.com/openam/oauth2/realms/root/realms/venturessso/access_token",
    user_profile_url: "",
    app_url: "https://portal-dmt.dealstechmanagedservices.pwc.de/",
    logout_url:
      "https://signin.access.pwc.com/openam/XUI/?realm=%2Fventuressso#logout/&goto=https://portal-dmt.dealstechmanagedservices.pwc.de/",
    client_app_id: "deals_technology_platform",
    client_secret: "rgrrF7VbeXpw6IFJt5lX",
    client_host: "https://crs.access.pwc.com",
    piam_auth_host: "signin.access.pwc.com",
  },
  baseUrl: null, // Change this to the address of your backend API if different from frontend address
  loginUrl: "/login",
  IdleSessionTimeout: "900",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
