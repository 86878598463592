// =============================
// Email: info@pwc.com
// www.pwc.com/templates
// =============================

import { User } from './user.model';


export class UserEdit extends User {
  constructor(
    currentPassword?: string,
    newPassword?: string,
    confirmPassword?: string,
    firstName?: string,
    lastName?: string
  ) {
    super();

    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.confirmPassword = confirmPassword;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  public currentPassword: string;
  public newPassword: string;
  public confirmPassword: string;
  public firstName: string;
  public lastName: string;
}
