import {
  Component,
  OnInit,
  AfterViewInit,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import {
  AlertService,
  DialogType,
  MessageSeverity,
} from "../../services/alert.service";
import { AppTranslationService } from "../../services/app-translation.service";
import { AccountService } from "../../services/account.service";
import { Utilities } from "../../services/utilities";
import { Permission } from "../../models/permission.model";
import { AppComponent } from "../app.component";
import { Router } from "@angular/router";
import { DigitalLevers } from "../../models/digitallevers.model";
import { DigitalleversNewEditorComponent } from "./digitallevers-editor.component";
import { DatePipe } from "@angular/common";
import { DigitalleversUploadComponent } from "./digitallevers-upload.component";

@Component({
  selector: "digitallevers-management",
  templateUrl: "./digitallevers-management.component.html",
  styleUrls: ["./digitallevers-management.component.scss"],
  providers: [DatePipe],
})
export class DigitalleversNewManagementComponent
  implements OnInit, AfterViewInit
{
  columns: any[] = [];
  rows: DigitalLevers[] = [];
  rowsCache: DigitalLevers[] = [];
  editedDigitallevers: DigitalLevers;
  sourceDigitallevers: DigitalLevers;
  editingDigitallevers: { lever: string };
  loadingIndicator: boolean;
  searchText: string;

  @ViewChild("indexTemplate", { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild("actionsTemplate", { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild("editorModal", { static: true })
  editorModal: ModalDirective;

  @ViewChild("leversUploadModal", { static: true })
  leversUploadModal: ModalDirective;

  module: any = "DigitalLevers";
  obj: DigitalLevers = new DigitalLevers();
  @ViewChild("digitalleversEditor", { static: true })
  digitalleversEditor: DigitalleversNewEditorComponent;
  @ViewChild("digitalleversUpload", { static: true })
  digitalleversupload: DigitalleversUploadComponent;
  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    public appComponent: AppComponent,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    if (this.appComponent.isAdmin) {
      const gT = (key: string) => this.translationService.getTranslation(key);

      this.columns = [
        {
          prop: "serialno",
          name: "#",
          width: 50,
          cellTemplate: this.indexTemplate,
          canAutoResize: false,
        },
        { prop: "lever", name: "Lever" },
        { prop: "kpiname", name: "KPI (Name)" },
        { prop: "impact", name: "Impact of the Lever" },
        { prop: "businessunit", name: "Business Unit" },
        { prop: "timestamp", name: "Date Last Modified" },
        {
          name: "",
          width: 160,
          cellTemplate: this.actionsTemplate,
          resizeable: false,
          canAutoResize: false,
          sortable: false,
          draggable: false,
        },
      ];

      this.loadData();
    }
  }

  ngAfterViewInit() {
    if (this.appComponent.isAdmin) {
      this.digitalleversEditor.changesSavedCallback = () => {
        this.addNewDigitalleversToList();
        this.editorModal.hide();
        this.leversUploadModal.hide();
      };

      this.digitalleversupload.changesSavedCallback = () => {
        this.addNewDigitalleversToList();
        this.leversUploadModal.hide();
      };

      this.digitalleversEditor.changesCancelledCallback = () => {
        this.editedDigitallevers = null;
        this.sourceDigitallevers = null;
        this.editorModal.hide();
        this.leversUploadModal.hide();
      };
    }
  }

  addNewDigitalleversToList() {
    if (this.sourceDigitallevers) {
      Object.assign(this.sourceDigitallevers, this.editedDigitallevers);

      let sourceIndex = this.rowsCache.indexOf(this.sourceDigitallevers, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rowsCache, sourceIndex, 0);
      }

      sourceIndex = this.rows.indexOf(this.sourceDigitallevers, 0);
      if (sourceIndex > -1) {
        Utilities.moveArrayItem(this.rows, sourceIndex, 0);
      }

      this.editedDigitallevers = null;
      this.sourceDigitallevers = null;
    } else {
      const digitallevers = new DigitalLevers();
      Object.assign(digitallevers, this.editedDigitallevers);
      this.editedDigitallevers = null;

      let maxIndex = 0;
      for (const r of this.rowsCache) {
        if ((r as any).index > maxIndex) {
          maxIndex = (r as any).index;
        }
      }

      (digitallevers as any).index = maxIndex + 1;

      this.rowsCache.splice(0, 0, digitallevers);
      this.rows.splice(0, 0, digitallevers);
      this.rows = [...this.rows];
    }
  }

  inputFormData: string = "";

  closeUploadModal() {
    this.inputFormData = "close";
    this.leversUploadModal.hide();
  }

  uploadDigitallevers() {
    this.leversUploadModal.show();
    this.inputFormData = "";
  }

  downloadtemplate() {
    window.location.href = "../assets/DigitalleversTemplate.xlsx";
  }

  onleversUploadModalHidden() {
    this.leversUploadModal.hide();
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.obj.isget = true;
    this.accountService.Set(this.module, this.obj).subscribe(
      (results) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        const Digitallevers = results;

        Digitallevers.forEach((item) => {
          if (item.timestamp == "0001-01-01") item.timestamp = null;
          item.timestamp = this.datePipe.transform(
            item.timestamp,
            "MM-dd-yyyy hh:mm:ss a"
          );
        });

        this.rowsCache = [...Digitallevers];
        this.rows = Digitallevers;
      },
      (error) => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          "Load Error",
          `Unable to retrieve Digitallevers from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        r.lever,
        r.kpiname,
        r.impact,
        r.businessunit
      )
    );
  }

  onEditorModalHidden() {
    this.editingDigitallevers = null;
    this.digitalleversEditor.resetForm(true);
    this.loadData();
    this.searchText = "";
  }

  new() {
    this.editingDigitallevers = null;
    this.sourceDigitallevers = null;
    this.editedDigitallevers = this.digitalleversEditor.new();
    this.editorModal.show();
  }

  edit(row: DigitalLevers) {
    this.editingDigitallevers = { lever: row.lever };
    this.sourceDigitallevers = row;
    this.editedDigitallevers = this.digitalleversEditor.edit(row);
    this.editorModal.show();
  }

  delete(obj: DigitalLevers) {
    this.alertService.showDialog(
      'Are you sure you want to delete "' + obj.lever + '"?',
      DialogType.confirm,
      () => {
        obj.createdby = this.accountService.currentUser.id;
        obj.isdeleted = true;
        obj.isget = false;
        this.accountService.Set(this.module, obj).subscribe(
          () => {
            this.alertService.showDialog(
              `Deleted Successfully.`,
              DialogType.alert
            );

            this.rowsCache = this.rowsCache.filter((item) => item !== obj);
            this.rows = this.rows.filter((item) => item !== obj);

            this.loadData();
          },
          (error) => {
            this.appComponent.failedHelper(error);
          }
        );
      }
    );
  }
}
